import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Alert,
  Modal,
  Skeleton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OpacityIcon from "@mui/icons-material/Opacity";
import AirIcon from "@mui/icons-material/Air";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CompressIcon from "@mui/icons-material/Compress";
import NavigationIcon from "@mui/icons-material/Navigation";
import WarningIcon from "@mui/icons-material/Warning";
import StationMap from "../StationMap/StationMap";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import {
  getCardinalDirection,
  convertMSToKMH,
  getQCFlag,
  getStationMetadata,
} from "../../utils/rawFunction";
import WeatherInfoItem from "./WeatherInfoItem";
import { stationMetaData } from "../../utils/rawData";
import WarningBanner from "./WarningBanner";
import { SENSOR_LABELS } from "./SENSOR_LABELS";
import { calculateWindChill } from "./calculateWindChill";
import { DateTime } from "luxon";
import { useTranslation } from "../../context/TranslationContext";
import { WindCompass } from "./WindCompass";
import { colorRanges } from "./colorRanges";

function WeatherDashboard({ data, stationId }) {
  const [openMap, setOpenMap] = useState(false);
  const { currentLanguage, setCurrentLanguage, translate } = useTranslation();

  // const [displayedValue, setDisplayedValue] = useState({
  //   value: 0,
  //   label: "Temperature",
  //   unit: "°C",
  // });
  // console.log(data);
  // useEffect(() => {
  //   if (data && data.elements) {
  //     const { temperature_ext } = data.elements;
  //     setDisplayedValue({
  //       value: temperature_ext.value,
  //       label: "Temperature",
  //       unit: "°C",
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (data && data.elements) {
  //     const interval = setInterval(() => {
  //       setDisplayedValue((prevValue) => {
  //         const weatherVariables = [
  //           {
  //             value: data.elements.temperature_ext.value,
  //             label: "Temperature",
  //             unit: "°C",
  //           },
  //           // {
  //           // 	value: data.elements.humidity_ext.value,
  //           // 	label: "Humidity",
  //           // 	unit: "%",
  //           // },
  //           // {
  //           // 	value: data.elements.pressure_int.value,
  //           // 	label: "Pressure",
  //           // 	unit: "hPa",
  //           // },
  //           {
  //             value: data.elements.wind_speed.value,
  //             label: "Wind Speed",
  //             unit: "km/h",
  //           },
  //         ];
  //         const nextIndex =
  //           (weatherVariables.findIndex((v) => v.label === prevValue.label) +
  //             1) %
  //           weatherVariables.length;
  //         return weatherVariables[nextIndex];
  //       });
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   }
  // }, [data]);

  const handleMapOpen = () => {
    setOpenMap(true);
  };

  const handleMapClose = () => {
    setOpenMap(false);
  };

  if (!data || !data.elements) return null;

  const { timestamp, elements } = data;
  const dateTime = DateTime.fromISO(timestamp);
  const metadata = getStationMetadata(stationId);
  const {
    temperature_int,
    humidity_int,
    pressure_int,
    temperature_ext,
    humidity_ext,
    pitch,
    roll,
    wind_speed,
    wind_direction,
    wind_gust_speed,
    wind_gust_direction,
    iridium_latitude,
    iridium_longitude,
    iridium_cep,
    voltage,
    solar,
    snow_depth,
  } = elements;

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      {/* <Grid container spacing={2}> */}
      <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on mobile
                justifyContent: "space-between",
                gap: 2,
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={handleMapOpen}
                >
                  <LocationOnIcon />
                </IconButton>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "1.5rem", sm: "1.8rem" },
                  }}
                >
                  {" "}
                  {translate(metadata.name)}
                  {/* {metadata.name} ( {stationId} ) */}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle2">
                  {translate("Last updated")}{" "}
                  {(() => {
                    // Get the relative time string from moment.js (e.g., "an hour ago", "2 days ago")
                    const timeString = moment(timestamp).local().fromNow();

                    // Check if the time string contains special multi-word phrases that need special handling
                    if (
                      timeString.includes("an hour") ||
                      timeString.includes("a day")
                    ) {
                      // For special cases like "an hour" or "a day":
                      // 1. First replace the special phrases with their translations
                      // 2. Then split the remaining string and translate word by word
                      // 3. Finally join all parts back together
                      return timeString
                        .replace("an hour", translate("an hour")) // Translate "an hour" as one unit
                        .replace("a day", translate("a day")) // Translate "a day" as one unit
                        .split(" ") // Split remaining text into words
                        .map((part) => translate(part)) // Translate each remaining word
                        .join(" "); // Rejoin all parts with spaces
                    }

                    // For regular cases (e.g., "2 days ago", "5 minutes ago"):
                    // Simply split the string and translate each word individually
                    return timeString
                      .split(" ")
                      .map((part) => translate(part))
                      .join(" ");
                  })()}
                </Typography>
                <Tooltip
                  //had to use this new package because moment is not having a built-in method for converting to local time zone names
                  title={dateTime.toLocaleString(
                    DateTime.DATETIME_FULL_WITH_SECONDS
                  )}
                >
                  <InfoIcon sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            </Box>
            <WarningBanner elements={data.elements} />

            {/* <Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 2,
							}}
						>
							<Box>
								<Typography
									variant="h1"
									sx={{ fontSize: "6rem", fontWeight: "bold" }}
								>
									{displayedValue.value.toFixed(1)}
									<span style={{ fontSize: "1rem", verticalAlign: "super" }}>
										{displayedValue.unit}
									</span>
								</Typography>
								<Typography variant="h4">{displayedValue.label}</Typography>
							</Box>
						</Box> */}
            {/* 
						<Typography variant="h6" sx={{ mb: 2 }}>
							Weather Info
						</Typography> */}

            <Grid container spacing={2}>
              {SENSOR_LABELS.temperature_int.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.temperature_int.label}
                    value={temperature_int.value}
                    unit="°"
                    icon={ThermostatIcon}
                    element={temperature_int}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.temperature_ext.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.temperature_ext.label}
                    value={temperature_ext.value}
                    unit="°C"
                    icon={ThermostatIcon}
                    element={temperature_ext}
                  />
                </Grid>
              )}
              {/* have calculated ,but not sure */}
              {SENSOR_LABELS.wind_chill.visible &&
                calculateWindChill(temperature_ext, wind_speed) !== null && (
                  <Grid item xs={6} sm={4} md={3}>
                    <WeatherInfoItem
                      label={SENSOR_LABELS.wind_chill.label}
                      value={calculateWindChill(temperature_ext, wind_speed)}
                      unit=""
                      icon={AirIcon}
                      element={{
                        value: calculateWindChill(temperature_ext, wind_speed),
                        qc_value: undefined,
                      }}
                    />
                  </Grid>
                )}
              {SENSOR_LABELS.humidity_int.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.humidity_int.label}
                    value={humidity_int.value}
                    unit="%"
                    icon={OpacityIcon}
                    element={humidity_int}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.humidity_ext.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.humidity_ext.label}
                    value={humidity_ext.value}
                    unit="%"
                    icon={OpacityIcon}
                    element={humidity_ext}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.pressure_int.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.pressure_int.label}
                    value={pressure_int.value}
                    unit=" hPa"
                    icon={CompressIcon}
                    element={pressure_int}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.wind_speed.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.wind_speed.label}
                    value={convertMSToKMH(wind_speed.value)}
                    unit=" km/h"
                    icon={AirIcon}
                    element={wind_speed}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.wind_direction.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.wind_direction.label}
                    value={wind_direction.value}
                    unit="°"
                    icon={NavigationIcon}
                    element={wind_direction}
                    rotateIcon={true}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.wind_gust_speed.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.wind_gust_speed.label}
                    value={convertMSToKMH(wind_gust_speed.value)}
                    unit=" km/h"
                    icon={AirIcon}
                    element={wind_gust_speed}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.wind_gust_direction.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.wind_gust_direction.label}
                    value={wind_gust_direction.value}
                    unit="°"
                    icon={NavigationIcon}
                    element={wind_gust_direction}
                    rotateIcon={true}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.pitch.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.pitch.label}
                    value={pitch.value}
                    unit="°"
                    icon={NavigationIcon}
                    element={pitch}
                    rotateIcon={true}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.roll.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.roll.label}
                    value={roll.value}
                    unit="°"
                    icon={NavigationIcon}
                    element={roll}
                    rotateIcon={true}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.voltage.visible && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.voltage.label}
                    value={voltage.value}
                    unit=" V"
                    icon={BatteryChargingFullIcon}
                    element={voltage}
                  />
                </Grid>
              )}
              {SENSOR_LABELS.solar.visible && solar?.value !== undefined && (
                <Grid item xs={6} sm={4} md={3}>
                  <WeatherInfoItem
                    label={SENSOR_LABELS.solar.label}
                    value={solar.value}
                    unit=" W/m²"
                    icon={SolarPowerIcon}
                    element={solar}
                  />
                </Grid>
              )}

              {SENSOR_LABELS.snow_depth.visible &&
                snow_depth?.value !== undefined && (
                  <Grid item xs={6} sm={4} md={3}>
                    <WeatherInfoItem
                      label={SENSOR_LABELS.snow_depth.label}
                      value={snow_depth.value}
                      unit=" mm"
                      icon={AcUnitIcon}
                      element={snow_depth}
                    />
                  </Grid>
                )}
            </Grid>
            {/* Separate row for WindCompass */}
            {SENSOR_LABELS.wind_compass.visible && (
              <Grid container sx={{ mt: 1 }} spacing={1}>
                <Grid item xs={12} md={6}>
                  <WindCompass
                    title={SENSOR_LABELS.wind_compass.label}
                    windSpeed={convertMSToKMH(wind_speed.value)}
                    windDirectionTo={(wind_direction.value + 180) % 360}
                    windDirectionFrom={wind_direction.value}
                    windGustSpeed={convertMSToKMH(wind_gust_speed.value)}
                    // windGustDirection={(wind_gust_direction.value + 180) % 360}
                    cardinalDirectionFrom={`${getCardinalDirection(
                      wind_direction.value
                    )} `}
                    // gustCardinalDirection={`${(
                    //   (wind_gust_direction.value + 180) % 360
                    // ).toFixed(0)}°`}
                    // gustCardinalDirection={`${getCardinalDirection(
                    //   (wind_gust_direction.value + 180) % 360
                    // )}${
                    //   (wind_gust_direction.value + 180) % 360
                    // }°`}
                    // Add QC values
                    windSpeedQC={wind_speed.qc_value}
                    windDirectionQC={wind_direction.qc_value}
                    windGustSpeedQC={wind_gust_speed.qc_value}
                    windGustDirectionQC={wind_gust_direction.qc_value}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={openMap}
        onClose={handleMapClose}
        aria-labelledby="map-modal-title"
        aria-describedby="map-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <StationMap
            stationId={stationId}
            latitude={iridium_latitude.value}
            longitude={iridium_longitude.value}
            cep={iridium_cep.value}
            metadata={metadata}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default WeatherDashboard;
