import React from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";

const FormField = ({ type, name, label, options, isLoading, ...props }) => {
	switch (type) {
		case "select":
			return (
				<TextField
					select
					fullWidth
					margin="normal"
					name={name}
					label={label}
					{...props}
				>
					{isLoading ? (
						<MenuItem disabled>
							<CircularProgress size={20} /> Loading...
						</MenuItem>
					) : (
						options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label} 
							</MenuItem>
						))
					)}
				</TextField>
			);
		case "datetime-local":
			return (
				<TextField
					fullWidth
					margin="normal"
					type="datetime-local"
					name={name}
					label={`${label} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
					InputLabelProps={{
						shrink: true,
					}}
					{...props}
				/>
			);
		case "number":
			return (
                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
					name={name}
                    label={label}	
				
                    {...props}
                />
            );
		default:
			return (
				<TextField
					fullWidth
					margin="normal"
					type={type}
					name={name}
					label={label}
					{...props}
				/>
			);
	}
};

export default FormField;
