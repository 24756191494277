import { useInfiniteQuery, useQueryClient } from "react-query";
import { getFormData } from "./api";
import moment from "moment";

const formatFlag = (flag) => ({
  id: flag.id,
  variable: flag.variable,
  from_date: flag.from_date
    ? moment(flag.from_date).format("MM/DD/YYYY, h:mm:ss a")
    : null,
  to_date: flag.to_date
    ? moment(flag.to_date).format("MM/DD/YYYY, h:mm:ss a")
    : null,
  QC_value: flag.QC_value,
  comment: flag.comment,
  aws_mapping_id: flag.aws_mapping_id,
  uid: flag.uid,
});

const fetchFlags = async ({ pageParam = 0 }) => {
  const response = await getFormData("flags", { page: pageParam, limit: 5 });
  return {
    flags: response.flags.map(formatFlag),
    nextPage: response.has_more ? pageParam + 1 : undefined,
    totalCount: response.total_count,
  };
};

export const useFlags = () => {
  const queryClient = useQueryClient();

  const query = useInfiniteQuery("flags", fetchFlags, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const refetchRows = async () => {
    await queryClient.resetQueries("flags");
    return query.refetch();
  };

  return { ...query, refetchRows };
};

