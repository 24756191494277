import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grid,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Autocomplete,
	Chip,
	Divider,
	Paper,
} from "@mui/material";
import { postFormData } from "../../../services/api";
import AddPersonDialog from "./AddPersonDialog"; // Add this import
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Add these configurations inside your component
const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		["bold", "italic", "underline", "strike"],
		[{ list: "ordered" }, { list: "bullet" }],
		["link"],
		[{ color: [] }, { background: [] }],
		["clean"],
	],
};

const formats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"list",
	"bullet",
	"link",
	"color",
	"background",
];
const AddVisitInfoDialog = ({
	open, onClose, onSuccess, stationId, onPersonAdded,personsData, sitePersonsData, initialData
}) => {
	const [visitInfo, setVisitInfo] = useState({
		reason_for_visit: "",
		onsite_start: null,
		onsite_end: null,
		outage_start: null,
		outage_end: null,
		visit_issues: "",
		visit_notes: "",
		visit_date: null,
	});
	const [showAddPerson, setShowAddPerson] = useState(false);
	// State for managing multiple person assignments
	const [assignedPersons, setAssignedPersons] = useState([]);
	const [currentPerson, setCurrentPerson] = useState({
		person: null,
		role: "",
	});
	const [errors, setErrors] = useState({});

	const handleChange = (field, value) => {
		setVisitInfo((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const handleAddPerson = () => {
		if (currentPerson.person && currentPerson.role) {
			setAssignedPersons((prev) => [...prev, { ...currentPerson }]);
			setCurrentPerson({ person: null, role: "" });
		}
	};

	const handleRemovePerson = (index) => {
		setAssignedPersons((prev) => prev.filter((_, i) => i !== index));
	};
	// Helper function to format date for datetime-local input
	const formatDateForInput = (dateString) => {
		if (!dateString) return "";
		const date = new Date(dateString);
		return date.toISOString().slice(0, 16); // "yyyy-MM-ddTHH:mm"
	};

  useEffect(() => {
    if (initialData && sitePersonsData) {
        // Prefill visit info
        setVisitInfo({
            reason_for_visit: initialData.reason_for_visit || "",
            onsite_start: formatDateForInput(initialData.onsite_start),
            onsite_end: formatDateForInput(initialData.onsite_end),
            outage_start: formatDateForInput(initialData.outage_start),
            outage_end: formatDateForInput(initialData.outage_end),
            visit_issues: initialData.visit_issues || "",
            visit_notes: initialData.visit_notes || "",
            visit_date: initialData.visit_date || ""
        });

        // Filter and format personnel data for the current visit
        const visitPersonnel = sitePersonsData
            .filter(sp => sp.visit_info_id === initialData.site_visit_id)
            .map(sp => ({
                person: {
                    id: sp.person.id,
                    firstname: sp.person.firstname,
                    lastname: sp.person.lastname,
                    organization: sp.person.organization
                },
                role: sp.role[0] // Assuming role is an array and we want the first role
            }));

        // Set the filtered personnel data to assigned persons
        setAssignedPersons(visitPersonnel);
    }
}, [initialData, sitePersonsData]); // Add sitePersonsData to dependencies

const validateForm = () => {
		const newErrors = {};
		if (!visitInfo.reason_for_visit)
			newErrors.reason_for_visit = "Reason is required";
		// if (!visitInfo.onsite_start) newErrors.onsite_start = "Start time is required";
		// if (!visitInfo.onsite_end) newErrors.onsite_end = "End time is required";

		// Validate date order
		if (visitInfo.onsite_start && visitInfo.onsite_end) {
			if (new Date(visitInfo.onsite_start) > new Date(visitInfo.onsite_end)) {
				newErrors.onsite_end = "End time must be after start time";
			}
		}

		if (visitInfo.outage_start && visitInfo.outage_end) {
			if (new Date(visitInfo.outage_start) > new Date(visitInfo.outage_end)) {
				newErrors.outage_end = "Outage end must be after outage start";
			}
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async () => {
		if (!validateForm()) return;

		try {
			if (initialData) {
				// Call API to update existing visit info
				await postFormData(
					`metadata/visit-info/${stationId}/${initialData.id}`,
					visitInfo
				);
			} else {
				// Create visit info
				const visitResponse = await postFormData(
					`metadata/visit-info/${stationId}`,
					visitInfo
				);

				// Create person assignments
				if (assignedPersons.length > 0) {
					await Promise.all(
						assignedPersons.map((assignment) =>
							postFormData(`metadata/metadata-si te-person/${stationId}`, {
								visit_info_id: visitResponse.id,
								person_id: assignment.person.id,
								role: [assignment.role],
							})
						)
					);
				}
			}
			onSuccess();
		} catch (error) {
			console.error("Error adding visit:", error);
			setErrors({ submit: "Failed to add visit. Please try again." });
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>{initialData ? "Edit Visit" : "Add New Visit"}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					{/* Visit Date */}
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							type="date"
							label="Visit Date"
							value={visitInfo.visit_date}
							onChange={(e) => handleChange("visit_date", e.target.value)}
							InputLabelProps={{ shrink: true }}
							required
							error={!!errors.visit_date}
							helperText={errors.visit_date}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Reason for Visit"
							value={visitInfo.reason_for_visit}
							onChange={(e) => handleChange("reason_for_visit", e.target.value)}
							error={!!errors.reason_for_visit}
							helperText={errors.reason_for_visit}
							required
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							type="datetime-local"
							label="Onsite Start"
							value={visitInfo.onsite_start}
							onChange={(e) => handleChange("onsite_start", e.target.value)}
							error={!!errors.onsite_start}
							helperText={errors.onsite_start}
							InputLabelProps={{ shrink: true }}
							//   required
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							type="datetime-local"
							label="Onsite End"
							value={visitInfo.onsite_end}
							onChange={(e) => handleChange("onsite_end", e.target.value)}
							error={!!errors.onsite_end}
							helperText={errors.onsite_end}
							InputLabelProps={{ shrink: true }}
							//   required
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							type="datetime-local"
							label="Outage Start"
							value={visitInfo.outage_start}
							onChange={(e) => handleChange("outage_start", e.target.value)}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							type="datetime-local"
							label="Outage End"
							value={visitInfo.outage_end}
							onChange={(e) => handleChange("outage_end", e.target.value)}
							error={!!errors.outage_end}
							helperText={errors.outage_end}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="subtitle1" gutterBottom>
							Visit Issues
						</Typography>
						<Paper
							elevation={0}
							sx={{
								border: "1px solid black",
								borderRadius: "8px",
								".ql-toolbar": {
									border: "none",
									borderBottom: "1px solid black",
									borderRadius: "8px 8px 0 0",
								},
								".ql-container": {
									border: "none",
									borderRadius: "0 0 8px 8px",
									minHeight: "150px",
									fontSize: "16px",
								},
								"& .ql-editor": {
									minHeight: "150px",
									fontSize: "16px",
								},
							}}
						>
							<ReactQuill
								value={visitInfo.visit_issues}
								onChange={(value) => handleChange("visit_issues", value)}
								modules={modules}
								formats={formats}
								placeholder="Enter visit issues..."
							/>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="subtitle1" gutterBottom>
							Visit Notes
						</Typography>
						<Paper
							elevation={0}
							sx={{
								border: "1px solid black",
								borderRadius: "8px",
								".ql-toolbar": {
									border: "none",
									borderBottom: "1px solid black",
									borderRadius: "8px 8px 0 0",
								},
								".ql-container": {
									border: "none",
									borderRadius: "0 0 8px 8px",
									minHeight: "150px",
									fontSize: "16px",
								},
								"& .ql-editor": {
									minHeight: "150px",
									fontSize: "16px",
								},
							}}
						>
							<ReactQuill
								value={visitInfo.visit_notes}
								onChange={(value) => handleChange("visit_notes", value)}
								modules={modules}
								formats={formats}
								placeholder="Enter visit notes..."
							/>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="subtitle1">Assign Personnel</Typography>
							<Button
								variant="outlined"
								size="small"
								startIcon={<PersonAddIcon />}
								onClick={() => setShowAddPerson(true)}
								sx={{
									borderColor: "black",
									color: "black",
								}}
							>
								Add New Person To System
							</Button>
						</Box>
					</Grid>

					{/* Add Person Dialog */}
					{showAddPerson && (
						<AddPersonDialog
							open={showAddPerson}
							onClose={() => setShowAddPerson(false)}
							onSuccess={(newPerson) => {
								// Refresh persons data
								onPersonAdded && onPersonAdded();
								setShowAddPerson(false);
							}}
						/>
					)}

					{/* Display assigned persons */}
					{assignedPersons.length > 0 && (
						<Grid item xs={12}>
							<Box sx={{ mb: 2 }}>
								{assignedPersons.map((assignment, index) => (
									<Chip
										key={index}
										label={`${assignment.person.firstname} ${assignment.person.lastname} (${assignment.role})`}
										onDelete={() => handleRemovePerson(index)}
										sx={{ m: 0.5 }}
									/>
								))}
							</Box>
						</Grid>
					)}

					{/* Person assignment form */}
					<Grid item xs={12} md={5}>
						<Autocomplete
							options={personsData || []}
							getOptionLabel={(option) =>
								`${option.firstname} ${option.lastname} - ${option.organization}`
							}
							value={currentPerson.person}
							onChange={(event, newValue) =>
								setCurrentPerson((prev) => ({ ...prev, person: newValue }))
							}
							renderInput={(params) => (
								<TextField {...params} label="Select Person" fullWidth />
							)}
						/>
					</Grid>

					<Grid item xs={12} md={5}>
						<FormControl fullWidth>
							<InputLabel>Role</InputLabel>
							<Select
								value={currentPerson.role}
								onChange={(e) =>
									setCurrentPerson((prev) => ({
										...prev,
										role: e.target.value,
									}))
								}
								label="Role"
							>
								<MenuItem value="Engineer">Engineer</MenuItem>
								<MenuItem value="Technician">Technician</MenuItem>
								<MenuItem value="Researcher">Researcher</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={2}>
						<Button
							variant="outlined"
							onClick={handleAddPerson}
							fullWidth
							sx={{ height: "100%" }}
							disabled={!currentPerson.person || !currentPerson.role}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSubmit}>
					{initialData ? "Update" : "Add Visit"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddVisitInfoDialog;
