import React, { useState } from "react";
import { 
  Typography, 
  Box, 
  Container, 
  Dialog, 
  DialogTitle, 
  DialogContent,
  IconButton,
  Tooltip 
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import FormField from "./FormField";
import FormSubmitButton from "./FormSubmitButton";
import MessageDisplay from "./MessageDisplay";
const isValidExpression = (expression) => {
	if (!expression) return false;
	
	// PostgreSQL validation rules
	return expression.includes('x') && // Must contain 'x'
	  /^[x0-9\s+\-*/().,ABS|ROUND|FLOOR|CEILING|MOD|POWER|LEAST|GREATEST]+$/i.test(expression) && // Valid characters
	  expression.split('(').length === expression.split(')').length && // Balanced parentheses
	  !expression.includes('//') && // No division by zero
	  !expression.match(/\(\s*\)/) && // No empty parentheses
	  expression.length > 0; // Not empty
  };
  
  
const ExpressionGuide = ({ open, onClose }) => (
	<Dialog open={open} onClose={onClose} maxWidth="md">
	  <DialogTitle>
		<Typography variant="h6"> Custom Expression Guide</Typography>
	  </DialogTitle>
	  <DialogContent>
		<Box sx={{ p: 2 }}>
		  <Typography variant="h6" gutterBottom>Available Operations</Typography>
		  <Box component="ul" sx={{ mb: 2 }}>
			<li>Arithmetic: +, -, *, /, % (modulo)</li>
			<li>Parentheses: ( )</li>
			<li>Mathematical Functions:</li>
			<ul>
			  <li>ABS(x) - Absolute value</li>
			  <li>ROUND(x) - Round to nearest integer</li>
			  <li>FLOOR(x) - Round down</li>
			  <li>CEILING(x) - Round up</li>
			  <li>MOD(x, y) - Modulo operation</li>
			  <li>POWER(x, y) - x raised to power y</li>
			</ul>
		  </Box>
  
		  <Typography variant="h6" gutterBottom>Expression Examples</Typography>
		  <Box component="ul" sx={{ mb: 2 }}>
			<li>(x + 10) * 2 → Basic arithmetic with parentheses</li>
			<li>ROUND(x / 3.5) → Round division result</li>
			<li>ABS(x - 100) → Distance from 100</li>
			<li>POWER(x, 2) → Square the value</li>
			<li>MOD(x, 24) → Get remainder when divided by 24 (useful for hours)</li>
			<li>CEILING(x / 10) * 10 → Round up to nearest 10</li>
			<li>Value capping Like LEAST(x, 100) or GREATEST(x, 0)</li>

		  </Box>
  
		  <Typography variant="h6" gutterBottom>Weather-Specific Examples</Typography>
		  <Box component="ul" sx={{ mb: 2 }}>
			<li>(x * 9/5) + 32 → Celsius to Fahrenheit</li>
			<li>(x - 32) * 5/9 → Fahrenheit to Celsius</li>
			<li>x * 3.6 → Convert m/s to km/h (wind speed)</li>
			<li>ROUND(x * 100) / 100 → Limit to 2 decimal places</li>
		  </Box>
  
		  <Typography variant="h6" gutterBottom>Important Rules</Typography>
		  <Box component="ul">
			<li>Use 'x' as placeholder for the field value</li>
			<li>Expressions must be mathematically valid</li>
			<li>Avoid division by zero</li>
			<li>Type casting is handled automatically</li>
			<li>Results are converted to double precision</li>
			<li>Nested functions are supported</li>
		  </Box>
		</Box>
	  </DialogContent>
	</Dialog>
  );

const FormBuilder = ({
  title,
  fields,
  onSubmit,
  formState,
  handleChange,
  errors,
  message,
  handleCloseMessage,
  resetForm,
  disabledFields = {},
  isSubmitting = false, 
}) => {
  const [showGuide, setShowGuide] = useState(false);

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            mb: 3,
            fontWeight: "bold",
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {title}
        </Typography>
        <Box
          component="form"
          onSubmit={onSubmit}
          noValidate
          sx={{
            "& .MuiTextField-root": { mb: 2.5 },
          }}
        >
          {fields.map((field) => (
            <FormField    
              key={field.name}
              {...field}
              value={formState[field.name] === null ? '' : formState[field.name]}
              onChange={handleChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
			  disabled={isSubmitting || disabledFields[field.name] || false}

            />
          ))}

{formState.operator === "custom" && (
  <Box sx={{ position: 'relative' }}>
    <FormField
      name="custom_expression"
	  disabled={isSubmitting}

      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <span>Custom Expression </span>
          {formState.custom_expression && (
            <span style={{ 
              fontSize: '0.75rem',
              color: isValidExpression(formState.custom_expression) ? '#2e7d32' : '#d32f2f'
            }}>
              {isValidExpression(formState.custom_expression) ? '(Valid)' : '(Invalid)'}
            </span>
          )}
        </Box>
      }
      type="text"
      
      value={formState.custom_expression || ""}
      onChange={handleChange}
      error={formState.custom_expression && !isValidExpression(formState.custom_expression)}
      helperText={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InfoIcon 
            fontSize="small" 
            color="primary" 
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowGuide(true)}
          />
          <span style={{ cursor: 'pointer' }} onClick={() => setShowGuide(true)}>
            Click here to learn how to write custom expressions
          </span>
        </Box>
      }
    />
  </Box>
)}

<Box sx={{ mt: -1 }}>
  <FormSubmitButton 
    disabled={isSubmitting}
    loading={isSubmitting}
  />
</Box>


        </Box>
      </Box>
      <MessageDisplay
        message={message.text}
        severity={message.severity}
        open={message.open}
        onClose={handleCloseMessage}
      />
      <ExpressionGuide 
        open={showGuide} 
        onClose={() => setShowGuide(false)} 
      />
    </Container>
  );
};

export default FormBuilder;