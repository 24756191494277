import React from "react";
import {
	Typography,
	Grid,
	Box,
	Paper,
	Divider,
	Chip,
	IconButton,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import theme from "../../../utils/theme";

const LoggerInfoDisplay = ({ loggerData }) => {
	const handleOpenLink = (url) => {
		if (url) {
			window.open(url, "_blank", "noopener,noreferrer");
		}
	};
    const formatInterval = (interval) => {
        return interval ? `${interval} seconds` : "N/A";
      };
    
      const formatDate = (date) => {
        return date ? new Date(date).toLocaleString() : "N/A";
      };
    
    return (
        <Box>
          {loggerData.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ padding: "20px" , height: "100%"}}>
                  <Divider textAlign="left"
                  sx={{
                    "&::before": { width: 12 },
                    "&::after": { width: "100%" },
                }}>
                    <Chip
                      label="Basic Information"
                      sx={{
                        backgroundColor: theme.palette.lightBlackBg.main,
                        color: theme.palette.secondary.main,
                      }}
                    />
                  </Divider>
                  <Box mt={2} ml={3}>
                    <InfoRow 
                      label="Logger Make" 
                      value={loggerData[0].logger_make || "N/A"} 
                    />
                    
                   
                    <InfoRow 
                      label="Serial Number" 
                      value={loggerData[0].logger_serial || "N/A"} 
                    />
                    <InfoRow
                      label="Status"
                      value={loggerData[0].is_active ? "Active" : "Inactive"}
                    />
                    <InfoRow
                      label="Begin Date"
                      value={formatDate(loggerData[0].date_begin)}
                    />
                    <InfoRow
                      label="End Date"
                      value={formatDate(loggerData[0].date_end)}
                    />
                     <InfoRow 
                      label="Description" 
                      value={loggerData[0].description || "N/A"} 
                    />
                  </Box>
                </Paper>
              </Grid>
    
              <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ padding: "20px", height: "100%" }}>
                  <Divider textAlign="left"
                  sx={{
										"&::before": { width: 12 },
										"&::after": { width: "100%" },
									}}>
                    <Chip
                      label="Version Information"
                      sx={{
                        backgroundColor: theme.palette.lightBlackBg.main,
                        color: theme.palette.secondary.main,
                      }}
                    />
                  </Divider>
                  <Box mt={2} ml={3}>
                    <InfoRow
                      label="Hardware Version"
                      value={loggerData[0].logger_version_hardware || "N/A"}
                    />
                    <InfoRow
                      label="Software Version"
                      value={loggerData[0].logger_version_software || "N/A"}
                    />
                    <InfoRow
                      label="Github Code"
                      value={loggerData[0].logger_github_software_code || "N/A"}
                      isLink
                      onLinkClick={() =>
                        handleOpenLink(loggerData[0].logger_github_software_code)
                      }
                    />
                  </Box>
                </Paper>
              </Grid>
    
              <Grid item xs={12}>
                <Paper elevation={0} sx={{ padding: "20px" , height: "100%" }}>
                  <Divider textAlign="left"
                  sx={{
                    "&::before": { width: 12 },
                    "&::after": { width: "100%" },
                }}>
                    <Chip
                      label="Intervals"
                      sx={{
                        backgroundColor: theme.palette.lightBlackBg.main,
                        color: theme.palette.secondary.main,
                      }}
                    />
                  </Divider>
                  <Box mt={2} ml={3}>
                    <InfoRow
                      label="Sampling Interval"
                      value={formatInterval(loggerData[0].logger_interval_sampling)}
                    />
                    <InfoRow
                      label="Logging Interval"
                      value={formatInterval(loggerData[0].logger_interval_logging)}
                    />
                    <InfoRow
                      label="Transmit Interval"
                      value={formatInterval(loggerData[0].logger_interval_transmit)}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="subtitle1">No logger information available.</Typography>
          )}
        </Box>
      );
    };
    
    const InfoRow = ({ label, value, isLink, onLinkClick }) => (
      <Box display="flex" alignItems="center" mb={2}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ fontWeight: "bold", marginRight: "10px" }}
        >
          {label}:
        </Typography>
        <Typography variant="body1">
          {value}
          {isLink && value && value !== "N/A" && (
            <IconButton size="small" onClick={onLinkClick} sx={{ ml: 1 }}>
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
    );
    
    export default LoggerInfoDisplay;
    
    