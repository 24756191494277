export const colorRanges = [
  [0, [98, 113, 183]],
  [4, [57, 97, 159]],
  [11, [74, 148, 169]],
  [18, [77, 141, 123]],
  [25, [83, 165, 83]],
  [32, [53, 159, 53]],
  [40, [167, 157, 81]],
  [47, [159, 127, 58]],
  [54, [161, 108, 92]],
  [61, [129, 58, 78]],
  [68, [175, 80, 136]],
  [76, [117, 74, 147]],
  [86, [109, 97, 163]],
  [97, [68, 105, 141]],
  [104, [92, 144, 152]],
  [130, [125, 68, 165]],
  [166, [231, 215, 215]],
  [184, [219, 212, 135]],
  [277, [205, 202, 112]],
  [374, [128, 128, 128]]
];
