// EditModeActions.js
import React from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const EditModeActions = ({ editMode, handleEditMode }) => (
  <Button
    onClick={handleEditMode}
    startIcon={<EditIcon />}
    variant={editMode ? "contained" : "outlined"}
    size="small"
    sx={{ mr: 1 }}
  >
    Edit
  </Button>
);

export default EditModeActions;