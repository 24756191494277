import React, { useEffect, useState } from "react";
import { Route, Routes, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Toolbar,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ComputerIcon from "@mui/icons-material/Computer";
import BuildIcon from "@mui/icons-material/Build";
import EventIcon from "@mui/icons-material/Event";
import StationInfo from "./StationInfo/StationInfo";
import LoggerInfo from "./LoggerInfo/LoggerInfo";
import EquipmentInfo from "./EquipmentInfo/EquipmentInfo";
import VisitInfo from "./VisitInfo/VisitInfo";

const drawerWidth = 240;

const StationMetadataDashboard = () => {
  const { uid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Initialize selectedOption based on the current path
  const getInitialSelectedOption = () => {
    const path = location.pathname;
    const matches = path.match(/\/metadata\/(\w+)/);
    return matches ? matches[1] : "station";
  };

  const [selectedOption, setSelectedOption] = useState(getInitialSelectedOption);

  // Update selectedOption when the path changes
  useEffect(() => {
    const currentOption = getInitialSelectedOption();
    setSelectedOption(currentOption);
  }, [location.pathname]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    navigate(`/${uid}/metadata/${option}`);
  };

  const menuItems = [
    { icon: <InfoIcon />, text: "Station Info" },
    { icon: <ComputerIcon />, text: "Logger Info" },
    { icon: <BuildIcon />, text: "Equipment Info" },
    { icon: <EventIcon />, text: "Visit Info" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1000,
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar /> {/* This pushes the drawer content below the AppBar */}
        <Box sx={{ overflow: "auto" }}>
          <List>
            {menuItems.map((item, index) => (
        <ListItem
  component="div"
  key={index}
  onClick={() => handleOptionClick(item.text.toLowerCase().split(" ")[0])}
  sx={{
    position: 'relative',
    py: 1.5,
    px: 3,
    borderBottom: 'none',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 0,
      transform: 'translateX(-50%)',
      width: '80%',
      borderBottom: '1px solid #e0e0e0',
    },
    backgroundColor:
      selectedOption === item.text.toLowerCase().split(" ")[0]
        ? 'transparent'
        : 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
    ...(selectedOption === item.text.toLowerCase().split(" ")[0] && {
      borderRight: '4px solid black',
    }),
  }}
>
  <ListItemIcon
    sx={{
      color:
        selectedOption === item.text.toLowerCase().split(" ")[0]
          ? 'black'
          : 'inherit', // Set icon color to black if selected
    }}
  >
    {item.icon}
  </ListItemIcon>
  <ListItemText
    primary={item.text}
    sx={{
      color:
        selectedOption === item.text.toLowerCase().split(" ")[0]
          ? 'black'
          : 'inherit', // Set text color to black if selected
    }}
  />
</ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
       
        <Routes>
          <Route path="station" element={<StationInfo />} />
          <Route path="logger" element={<LoggerInfo />} />
          <Route path="equipment" element={<EquipmentInfo />} />
          <Route path="visit" element={<VisitInfo />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default StationMetadataDashboard;