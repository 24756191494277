import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "../../context/TranslationContext";
import LanguageSelector from "./LanguageSelector";
import { pages } from "../../pages/StaticPages/pagesConfig";
import { useAuth } from "../Auth/AuthContext";
import Cookies from "js-cookie";

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { currentLanguage, setCurrentLanguage, translate } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isLoggedIn, logout } = useAuth();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
    setMobileOpen(false);
  };

  const handleLogoutConfirm = () => {
    logout();
    Cookies.remove("accessToken");
    setLogoutDialogOpen(false);
    navigate("/");
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const staticNavItems = [
    {
      title: "Home",
      path: "/",
    },
    ...Object.entries(pages)
      .filter(([_, page]) => page.showInNav)
      .sort((a, b) => (a[1].navOrder || 0) - (b[1].navOrder || 0))
      .map(([key, page]) => ({
        title: page.title,
        path: page.path,
      })),
  ];

  const drawer = (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ flexGrow: 1, pt: 4 }}>
        {staticNavItems.map((item) => (
          <ListItem
            key={item.path}
            component={Link}
            to={item.path}
            onClick={handleDrawerToggle}
            disableRipple
            sx={{
              textAlign: "center",
              py: 2,
              color: "inherit",
              fontWeight: location.pathname === item.path ? 700 : 400,
              textDecoration:
                location.pathname === item.path ? "underline" : "none",
              "&:hover": {
                bgcolor: "transparent",
                color: "black",
              },
              "&:active": {
                color: "inherit",
              },
              transition: "none",
            }}
          >
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                sx: {
                  fontWeight: "inherit",
                  textDecoration: "inherit",
                  color: "inherit",
                },
              }}
              disableTypography={false}
            />
          </ListItem>
        ))}
        {/* <Divider sx={{ my: 2 }} />
        {isLoggedIn ? (
          <ListItem 
            onClick={handleLogoutClick}
            sx={{
              textAlign: 'center',
              py: 2,
              cursor: 'pointer'
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={translate('Logout')} />
          </ListItem>
        ) : (
          <ListItem 
            component={Link}
            to="/auth"
            onClick={handleDrawerToggle}
            sx={{
              textAlign: 'center',
              py: 2
            }}
          >
            <ListItemText primary={translate('Login / Sign Up')} />
          </ListItem>
        )} */}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "inline-block",
              }}
            >
              <Typography variant="h6">{translate("Silanga")}</Typography>
            </Link>
          </Box>

          <LanguageSelector
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />

          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ ml: 1 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%", // Ensure full height
              }}
            >
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mx: 2,
                  height: "24px",
                  my: "auto", // Center vertically
                  borderColor: "rgba(255, 255, 255, 0.3)",
                  alignSelf: "center", // Ensure center alignment
                }}
              />

              {staticNavItems.map((item) => (
                <Button
                  key={item.path}
                  component={Link}
                  to={item.path}
                  color="inherit"
                  sx={{
                    ml: 2,
                    fontWeight: location.pathname === item.path ? 700 : 400,
                    textDecoration:
                      location.pathname === item.path ? "underline" : "none",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  {item.title}
                </Button>
              ))}
              {/* {isLoggedIn ? (
                <Button
                  color="inherit"
                  onClick={handleLogoutClick}
                  endIcon={<ExitToAppIcon />}
                  sx={{ ml: 2 }}
                >
                  {translate('Logout')}
                </Button>
              ) : (
                <Button
                  color="inherit"
                  component={Link}
                  to="/auth"
                  endIcon={<AccountCircleIcon />}
                  sx={{ ml: 2 }}
                >
                  {translate('Login / Sign Up')}
                </Button>
              )} */}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        {drawer}
      </Drawer>

      {/* <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
      >
        <DialogTitle>{translate("Logout")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("Are you sure you want to log out?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            {translate("Cancel")}
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            color="primary"
            sx={{
              border: "1px solid black",
            }}
            autoFocus
          >
            {translate("Logout")}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

export default Navbar;
