import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Button, CircularProgress, Box } from "@mui/material";
import { useQuery } from "react-query";
import { getFormData } from "../../../services/api";
import LoggerInfoDisplay from "./LoggerInfoDisplay";
import AddLoggerDialog from "./AddLoggerDialog";

const LoggerInfo = () => {
  const { uid } = useParams();
  const [showAddComponent, setShowAddComponent] = useState(false);

  const { data: loggerData, isLoading, error } = useQuery(
    ["loggerInfo", uid],
    () => getFormData(`metadata/logger-info/${uid}`),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">Error loading logger data: {error.message}</Typography>;
  }

  return (
    <div>
      {loggerData && loggerData.length > 0 ? (
        <LoggerInfoDisplay loggerData={loggerData} />
      ) : showAddComponent ? (
        <AddLoggerDialog onAdd={() => setShowAddComponent(false)} />
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography variant="h6" color="textSecondary">
            No logger information available
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setShowAddComponent(true)}>
            Add New Logger
          </Button>
        </Box>
      )}
    </div>
  );
};

export default LoggerInfo;