// CustomTable.js
import React, { useCallback, useRef } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	CircularProgress,
	Checkbox,
} from "@mui/material";
import OffsetTableRows from "./OffsetTableRows.js";
import FlagTableRows from "./FlagTableRows.js";
import AwsMappingTableRows from "./AwsMappingTableRows.js";

const CustomTable = ({
	dataArray,
	isLoading,
	isFetchingNextPage,
	editMode,
	deleteMode,
	selectedRows,
	setselectedRows,
	fetchNextPage,
	hasNextPage,
	refetchRows,
	setMessage,
	tableHeaders,
	cancelEditMode,
	TableId
}) => {
	const observer = useRef();
	const lastRowRef = useCallback(
		(node) => {
			if (isLoading || isFetchingNextPage) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasNextPage) {
					fetchNextPage();
				}
			});
			if (node) observer.current.observe(node);
		},
		[isLoading, isFetchingNextPage, hasNextPage, fetchNextPage]
	);
	const renderTableRow = (data, index) => {
		const commonProps = {
		  key: data.id,
		  data,
		  editMode,
		  deleteMode,
		  selectedRows,
		  setselectedRows,
		  refetchRows,
		  setMessage,
		  ref: index === dataArray.length - 1 ? lastRowRef : null,
		  cancelEditMode,
		};
	
		switch (TableId) {
		  case "offsets":
			return <OffsetTableRows {...commonProps} />;
		  case "flags":
			return <FlagTableRows {...commonProps} />;
		  case "aws_mapping":
			return <AwsMappingTableRows {...commonProps} />
		  default:
			return null; // Or a default row component if needed
		}
	  };
	

	return (
		<TableContainer style={{ maxHeight: 600, overflow: "auto" }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{deleteMode && <TableCell>Select</TableCell>}
						{editMode && <TableCell>Actions</TableCell>}
						{tableHeaders.map((heading, index) => (
							<TableCell key={index}>{heading}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading && dataArray.length === 0 ? (
						<TableRow>
							<TableCell colSpan={9} align="center">
								<CircularProgress />
							</TableCell>
						</TableRow>
					) : (
						dataArray.map((data, index) => renderTableRow(data, index))
					  )}
					{isFetchingNextPage && (
						<TableRow>
							<TableCell colSpan={9} align="center">
								<CircularProgress />
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CustomTable;
