import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Button,
	CircularProgress,
	Box,
	Grid,
	Divider,
	Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import { getFormData } from "../../../services/api";
import VisitInfoDisplay from "./VisitInfoDisplay";
import AddVisitInfoDialog from "./AddVisitInfoDialog";
import AddPersonDialog from "./AddPersonDialog"; // New component for adding person
import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const VisitInfo = () => {
	const { uid } = useParams();
	const [showAddVisit, setShowAddVisit] = useState(false);
	const [showAddPerson, setShowAddPerson] = useState(false); // New state for person dialog

	// Fetch visit info data
	const {
		data: visitData,
		isLoading: visitLoading,
		error: visitError,
		refetch: refetchVisits,
	} = useQuery(
		["visitInfo", uid],
		() => getFormData(`metadata/visit-info/${uid}`),
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	// Fetch persons data for assignment
	const {
		data: personsData,
		isLoading: personsLoading,
		error: personsError,
		refetch: refetchPersons,
	} = useQuery(["persons"], () => getFormData("metadata/person"), {
		staleTime: 5 * 60 * 1000,
	});

	// Fetch site persons data
	const {
		data: sitePersonsData,
		isLoading: sitePersonsLoading,
		error: sitePersonsError,
	} = useQuery(
		["sitePersons", uid],
		() => getFormData(`metadata/metadata-site-person/${uid}`),
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const handleAddVisitClose = useCallback(() => {
		setShowAddVisit(false);
		refetchVisits();
	}, [refetchVisits]);

	const handleAddPersonClose = useCallback(() => {
		setShowAddPerson(false);
		refetchPersons();
	}, [refetchPersons]);

	if (visitLoading || personsLoading || sitePersonsLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="200px"
			>
				<CircularProgress />
			</Box>
		);
	}

	if (visitError || personsError || sitePersonsError) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="200px"
			>
				<Typography color="error">
					An error occurred while fetching data.
				</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ p: 3 }}>
			<Grid container spacing={2} sx={{ mb: 3 }}>
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography variant="h5">Site Visits</Typography>
						<Box display="flex" gap={2}>
							<Tooltip title="Add new person to System">
								<Button
									variant="outlined"
									startIcon={<PersonAddIcon />}
									onClick={() => setShowAddPerson(true)}
									sx={{
										borderColor: "black",
										color: "black",
										"&:hover": {
											borderColor: "black",
											backgroundColor: "rgba(0, 0, 0, 0.04)",
										},
									}}
								>
									Add Person
								</Button>
							</Tooltip>
							<Divider orientation="vertical" flexItem />
							<Button
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() => setShowAddVisit(true)}
							>
								Add New Visit
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<VisitInfoDisplay
				visitData={visitData}
				personsData={personsData}
				sitePersonsData={sitePersonsData}
				onRefetch={refetchVisits}
			/>

			{showAddVisit && (
				<AddVisitInfoDialog
					open={showAddVisit}
					onClose={handleAddVisitClose}
					onSuccess={handleAddVisitClose}
					stationId={uid}
					personsData={personsData}
					onPersonAdded={refetchPersons}
				/>
			)}

			{showAddPerson && (
				<AddPersonDialog
					open={showAddPerson}
					onClose={handleAddPersonClose}
					onSuccess={handleAddPersonClose}
				/>
			)}
		</Box>
	);
};

export default VisitInfo;
