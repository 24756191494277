export const awsMappingConfig = {
	title: "Map station Add Form",
	fields: [
		{
			name: "id",
			label: "IMEI",
			type: "select",
			options: [], // This will be populated dynamically
		},
		{
			name: "metadata_mapping_id",
			label: "Metadata",
			type: "select",
			options: [], // This will be populated dynamically
		},
		// {
		// 	name: "latest_change_history_id",
		// 	label: "Has Been Changed ?",
		// 	type: "boolean",
		// 	// disabled: true, // Disable this field while inserting
		// },
	],
	validationSchema: {
		id: { required: true, minLength: 1 },
		metadata_mapping_id: {
			required: true,
		},
	},
};

export const awsMappingTableConfig = {
	TableId: "aws_mapping",
    awsMappingTableTitle: "Mapping btw Station and Metadata",
    awsMappingTableHeaders : [
        "ID",
        "IMEI",
        "UID",
        "Metadata Mapping",
		"Has Been Changed?",
    ]

}