// Flags.js
import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Button, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomTable from "./CustomTable";
import EditModeActions from "./EditModeActions";
import DeleteModeActions from "./DeleteModeActions";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import MessageDisplay from "../Form/MessageDisplay";
import CancelIcon from "@mui/icons-material/Cancel";
import { deleteOffset } from "../../services/api";
import { useAwsMapping } from "../../services/useAwsMapping";

const AwsMappingTable = ({TableConfig}) => {
  const {
    data,
    isLoading,
    error,
    refetchRows,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAwsMapping();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedRows, setselectedRows] = useState([]);
  const [cancelEditMode, setCancelEditMode] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    severity: "success",
    open: false,
  });

  const handleRefresh = async () => {
    await refetchRows();
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    setDeleteMode(false);
    setselectedRows([]);
  };

  const handleCancelMode = () => {
    setEditMode(false);
    setDeleteMode(false);
    setselectedRows([]);
    setCancelEditMode(true); 
  };
  useEffect(() => {
    if (cancelEditMode) {
      setCancelEditMode(false);
    }
  }, [cancelEditMode]);

  const handleCloseMessage = () => {
    setMessage((prev) => ({ ...prev, open: false }));
  };

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
}
  

  return (
    <Container>
			<Box sx={{ mt: 4, mb: 4 }}>
            <Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						mb: 2,
					}}
				>
         					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography variant="h5" sx={{ mr: 2 }}>
							{TableConfig.awsMappingTableTitle}
						</Typography>
						<IconButton
							onClick={handleRefresh}
							size="large"
							sx={{ p: 0 }}
							aria-label="refresh"
						>
							<RefreshIcon />
						</IconButton>
					</Box>
					<Box>
						{(editMode) && (
							<Button
								onClick={handleCancelMode}
								startIcon={<CancelIcon />}
								variant="outlined"
								size="small"
								sx={{ mr: 1 }}
							>
								Cancel
							</Button>
						)}

        <EditModeActions editMode={editMode} handleEditMode={handleEditMode} />
        	</Box>
        	</Box>
        <CustomTable
          dataArray={data ? data?.pages?.flatMap((page) => page.aws_mappings) : []}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          editMode={editMode}
          deleteMode={deleteMode}
          selectedRows={selectedRows}
          setselectedRows={setselectedRows}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          refetchRows={refetchRows}
          setMessage={setMessage}
          tableHeaders={TableConfig.awsMappingTableHeaders}  
          cancelEditMode={cancelEditMode}
          TableId={TableConfig.TableId}
        />
<MessageDisplay
  message={isLoading ? "Processing..." : message.text}
  severity={isLoading ? "info" : message.severity}
  open={isLoading || message.open}
  onClose={handleCloseMessage}
/>

      </Box>
    </Container>
  );
};

export default AwsMappingTable;