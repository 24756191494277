import React, { useState } from "react";
import {
	Typography,
	Grid,
	Box,
	Paper,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Chip,
	Tooltip,
	Divider,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TimelineIcon from "@mui/icons-material/Timeline";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import theme from "../../../utils/theme";
import InfoIcon from "@mui/icons-material/Info";
const EquipmentDisplay = ({ equipmentData, modelsData, attributesData }) => {
	const [selectedEquipment, setSelectedEquipment] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

	const formatDate = (date) => {
		if (!date) return "N/A";
		return new Date(date).toLocaleString();
	};

	// Get model details for an equipment
	const getModelDetails = (modelId) => {
		return modelsData?.find((model) => model.id === modelId) || null;
	};

	// Get attributes for a model
	const getModelAttributes = (modelId) => {
		return (
			attributesData?.filter((attr) => attr.equipment_model_id === modelId) ||
			[]
		);
	};

	const handleEdit = (type, id, event) => {
		event.stopPropagation();
		console.log(`Edit ${type} with ID:`, id);
	};

	const handleDelete = (type, id, event) => {
		event.stopPropagation();
		console.log(`Delete ${type} with ID:`, id);
	};

	const handleOpenDialog = (equipment, event) => {
		event.stopPropagation();
		const modelDetails = getModelDetails(equipment.model_id);
		const modelAttributes = getModelAttributes(equipment.model_id);

		setSelectedEquipment({
			...equipment,
			model: modelDetails,
			attributes: modelAttributes,
		});
		setOpenDialog(true);
	};
	const formatCalibrationInterval = (interval) => {
		if (!interval) return "N/A";

		const matches = interval.match(/(\d+) days?(?:, (\d+):(\d+):(\d+))?/);
		if (!matches) return interval;

		const days = parseInt(matches[1]);

		if (days >= 365) {
			const years = Math.floor(days / 365);
			const remainingDays = days % 365;
			const months = Math.floor(remainingDays / 30);

			let result = `${years} ${years === 1 ? "year" : "years"}`;
			if (months > 0) {
				result += ` ${months} ${months === 1 ? "month" : "months"}`;
			}
			return result;
		} else if (days >= 30) {
			const months = Math.floor(days / 30);
			const remainingDays = days % 30;

			let result = `${months} ${months === 1 ? "month" : "months"}`;
			if (remainingDays > 0) {
				result += ` ${remainingDays} ${remainingDays === 1 ? "day" : "days"}`;
			}
			return result;
		} else {
			return `${days} ${days === 1 ? "day" : "days"}`;
		}
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
		setSelectedEquipment(null);
	};

	const ModelDetailsDialog = () => (
		<Dialog
			open={openDialog}
			onClose={handleCloseDialog}
			maxWidth="md"
			fullWidth
		>
			{selectedEquipment && (
				<>
					<DialogTitle>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h6">Model & Attributes Details</Typography>
							
						</Box>
					</DialogTitle>
					<DialogContent dividers>
						{/* Model Information */}
						{selectedEquipment.model && (
							<Box mb={3}>
								<Paper elevation={0}       sx={{ 
        p: 2,
        border: '1px solid black',
        borderRadius: '8px',
      }}
>
									<Box display="flex" alignItems="center" gap={1} mb={2}>
										<Typography variant="h6">Model Information</Typography>
									</Box>
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<InfoRow
												label="Model Name"
												value={selectedEquipment.model.name}
											/>
											<InfoRow
												label="Manufacturer"
												value={selectedEquipment.model.manufacturer}
											/>
											<InfoRow
												label="Application"
												value={selectedEquipment.model.application}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<InfoRow
												label="Accuracy"
												value={selectedEquipment.model.accuracy}
											/>
											<InfoRow
												label="Precision"
												value={selectedEquipment.model.precision}
											/>
											<InfoRow
												label="Measurement Unit"
												value={selectedEquipment.model.measurement_unit}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<InfoRow
												label="Range"
												value={`${
													selectedEquipment.model.range_min || "N/A"
												} - ${selectedEquipment.model.range_max || "N/A"} ${
													selectedEquipment.model.measurement_unit || ""
												}`}
											/>
											<InfoRow
												label="Calibration Interval"
												value={formatCalibrationInterval(
													selectedEquipment.model.calibration_interval
												)}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Box>
						)}

						{/* Attributes Information */}
						{selectedEquipment.attributes &&
							selectedEquipment.attributes.length > 0 && (
								<Paper elevation={0}      sx={{ 
                  p: 2,
                  border: '1px solid black',
                  borderRadius: '8px',
                }}
          >
									<Box display="flex" alignItems="center" gap={1} mb={2}>
										<Typography variant="h6">Model Attributes</Typography>
									</Box>
									<Grid container spacing={2}>
										{selectedEquipment.attributes.map((attribute, index) => (
											<Grid item xs={12} md={6} key={index}>
												<Paper
													elevation={0}
													
												>
													<InfoRow
														label={attribute.attribute_name}
														value={attribute.attribute_value}
													/>
												</Paper>
											</Grid>
										))}
									</Grid>
								</Paper>
							)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog}>Close</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);

	return (
		<Box sx={{ p: 2 }}>
			{Array.isArray(equipmentData) &&
				equipmentData.map((equipment) => {
					const modelDetails = getModelDetails(equipment.model_id);

					return (
						<Accordion
            key={equipment.id} 
            sx={{ 
              mb: 2,
              boxShadow: 'none', // Remove shadow
              border: '1px solid black', // Add black border
              borderRadius: '8px !important', // Add rounded corners
              '&:before': {
                display: 'none', // Remove the default divider
              },
              // Fix for rounded corners in Accordion
              '& .MuiAccordionSummary-root': {
                borderRadius: '8px 8px 0 0',
                minHeight: '64px !important', // Set consistent height
                '& .MuiAccordionSummary-content': {
                  margin: '12px 0 !important', // Keep consistent margin
                }
              },
              '& .MuiAccordionDetails-root': {
                borderTop: '1px solid black', // Add separator between header and content
                borderRadius: '0 0 8px 8px',
                padding: '16px', // Set consistent padding
              },
              // Fix for expanded state
              '&.Mui-expanded': {
                margin: '0 0 16px 0 !important', // Keep consistent margin when expanded
              },
              // Fix for the last accordion
              '&:last-of-type': {
                marginBottom: '16px !important',
              }
            }}
                      >
							<AccordionSummary expandIcon={<ExpandMoreIcon />}               sx={{
                '& .MuiAccordionSummary-content': {
                  margin: '12px 0', // Add some vertical padding
                }
              }}
>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<Box display="flex" alignItems="center" gap={2}>
										<Typography variant="h6">{equipment.name}</Typography>
										<Chip
											label={equipment.status}
											size="small"
											variant="outlined"
											sx={{
												border: `1px solid ${theme.palette.grey[900]}`,
												backgroundColor: "transparent",
												"& .MuiChip-label": {
													color: theme.palette.grey[900],
												},
											}}
										/>
										{modelDetails && (
											<Tooltip title="View Model & Attributes"       placement="right"
>
												<IconButton
													size="small"
													onClick={(e) => handleOpenDialog(equipment, e)}
													sx={{ color: "black" }}
												>
													<InfoIcon />
												</IconButton>
											</Tooltip>
										)}
									</Box>
									<Button type="equipment" id={equipment.id} />
								</Box>
							</AccordionSummary>

							<AccordionDetails>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Paper elevation={0} sx={{ p: 2 }}>
											<Grid container spacing={2}>
												<Grid item xs={12} md={4}>
													<InfoRow
														label="Serial Number"
														value={equipment.serial_number}
													/>
													<InfoRow label="Type" value={equipment.type} />
												</Grid>
												<Grid item xs={12} md={4}>
													<InfoRow
														label="Purchase Date"
														value={formatDate(equipment.purchase_date)}
													/>
													<InfoRow
														label="Deployment Date"
														value={formatDate(equipment.deployment_date)}
													/>
												</Grid>
												<Grid item xs={12} md={4}>
													<InfoRow
														label="End Deployment"
														value={formatDate(equipment.end_deployment_date)}
													/>
												</Grid>
												<Grid item xs={12} >
													<InfoRow
														label="Comments"
														value={equipment.comments}
													/>
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					);
				})}

			<ModelDetailsDialog />
		</Box>
	);
};
const InfoRow = ({ label, value }) => (
	<Box display="flex" flexDirection="column" mb={1}>
		<Typography variant="caption" color="textSecondary" fontWeight="bold">
			{label}
		</Typography>
		<Typography variant="body2">{value || "N/A"}</Typography>
	</Box>
);

export default EquipmentDisplay;
