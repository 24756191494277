import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../context/TranslationContext";

function Footer() {
	const { translate} = useTranslation();

	return (
		<Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
			<Typography variant="body2" color="text.secondary" align="center">
				© {new Date().getFullYear()} {translate("Silanga")}. {translate("All rights reserved")}.
			</Typography>
		</Box>
	);
}

export default Footer;
