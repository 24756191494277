import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { postFormData } from "../../../services/api";
import MessageDisplay from "../../Form/MessageDisplay";

const AddLoggerDialog = ({ onAdd }) => {
  const { uid } = useParams();
  const [loggerInfo, setLoggerInfo] = useState({
    logger_make: "",
    description: "",
    logger_serial: null,
    is_active: true,
    date_begin: null,
    date_end: null,
    logger_version_hardware: "",
    logger_version_software: "",
    logger_interval_sampling: "",  
    logger_interval_logging: "",   
    logger_interval_transmit: "",  
    logger_github_software_code: "",
  });
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setLoggerInfo({ ...loggerInfo, [e.target.name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!loggerInfo.logger_make) newErrors.logger_make = "Logger make is required";
    if (!loggerInfo.logger_serial) newErrors.logger_serial = "Serial number is required";
    if (loggerInfo.logger_interval_sampling && isNaN(loggerInfo.logger_interval_sampling)) {
      newErrors.logger_interval_sampling = "Must be a number";
    }
    if (loggerInfo.logger_interval_logging && isNaN(loggerInfo.logger_interval_logging)) {
      newErrors.logger_interval_logging = "Must be a number";
    }
    if (loggerInfo.logger_interval_transmit && isNaN(loggerInfo.logger_interval_transmit)) {
      newErrors.logger_interval_transmit = "Must be a number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      const dataToSubmit = {
        ...loggerInfo,
        logger_serial: loggerInfo.logger_serial ? Number(loggerInfo.logger_serial) : null,
        logger_interval_sampling: loggerInfo.logger_interval_sampling ? 
          Number(loggerInfo.logger_interval_sampling) : null,
        logger_interval_logging: loggerInfo.logger_interval_logging ? 
          Number(loggerInfo.logger_interval_logging) : null,
        logger_interval_transmit: loggerInfo.logger_interval_transmit ? 
          Number(loggerInfo.logger_interval_transmit) : null,
        date_begin: loggerInfo.date_begin || null,
        date_end: loggerInfo.date_end || null
        };

      await postFormData(`/metadata/logger-info/${uid}`, dataToSubmit);
      setMessageContent("Logger information added successfully!");
      setSeverity("success");
      setMessageOpen(true);
      onAdd();
    } catch (error) {
      setMessageContent(`Failed to add logger information : ${error.message}`);
      setSeverity("error");
      setMessageOpen(true);
    }
  };
 


  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper elevation={3} sx={{ width: "60%", padding: "20px", marginTop: "20px" }}>
        <Typography variant="h6">Add New Logger Info</Typography>

        <TextField
          label="Logger Make"
          name="logger_make"
          value={loggerInfo.logger_make}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={!!errors.logger_make}
          helperText={errors.logger_make}
        />

        <TextField
          label="Description"
          name="description"
          value={loggerInfo.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />

        <TextField
          label="Serial Number"
          name="logger_serial"
          value={loggerInfo.logger_serial}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={!!errors.logger_serial}
          helperText={errors.logger_serial}
        />

        <FormControlLabel
          control={
            <Switch
              checked={loggerInfo.is_active}
              onChange={handleChange}
              name="is_active"
            />
          }
          label="Active"
        />

        <TextField
          label="Begin Date"
          name="date_begin"
          type="datetime-local"
          value={loggerInfo.date_begin}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="End Date"
          name="date_end"
          type="datetime-local"
          value={loggerInfo.date_end}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Hardware Version"
          name="logger_version_hardware"
          value={loggerInfo.logger_version_hardware}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Software Version"
          name="logger_version_software"
          value={loggerInfo.logger_version_software}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Sampling Interval (seconds)"
          name="logger_interval_sampling"
          value={loggerInfo.logger_interval_sampling}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="number"
          error={!!errors.logger_interval_sampling}
          helperText={errors.logger_interval_sampling}
        />

        <TextField
          label="Logging Interval (seconds)"
          name="logger_interval_logging"
          value={loggerInfo.logger_interval_logging}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="number"
          error={!!errors.logger_interval_logging}
          helperText={errors.logger_interval_logging}
        />

        <TextField
          label="Transmit Interval (seconds)"
          name="logger_interval_transmit"
          value={loggerInfo.logger_interval_transmit}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="number"
          error={!!errors.logger_interval_transmit}
          helperText={errors.logger_interval_transmit}
        />

        <TextField
          label="Github Software Code URL"
          name="logger_github_software_code"
          value={loggerInfo.logger_github_software_code}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <Box mt={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Add Logger
          </Button>
        </Box>

        <MessageDisplay
          message={messageContent}
          severity={severity}
          open={messageOpen}
          onAdd={() => setMessageOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default AddLoggerDialog;