// src/services/authenticate.js
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../components/Auth/userpool.js";
import Cookies from "js-cookie";

// src/services/authenticate.js

export const authenticate = async (
	Username,
	Password,
	isPostVerification = false
) => {
	const user = new CognitoUser({ Username, Pool: UserPool });
	const authDetails = new AuthenticationDetails({ Username, Password });

	return new Promise((resolve, reject) => {
		user.authenticateUser(authDetails, {
			onSuccess: (data) => {
				console.log("onSuccess:", data);
				const accessToken = data.getAccessToken().getJwtToken();
				Cookies.set("accessToken", accessToken, {
					secure: true,
					sameSite: "Strict",
				});
				resolve(data);
			},
			onFailure: (err) => {
				console.error("onFailure:", err);
				if (isPostVerification && err.code === "UserNotConfirmedException") {
					// If this is post-verification and the user is still not confirmed,
					// it might be a timing issue. Retry authentication after a short delay.
					setTimeout(() => {
						authenticate(Username, Password, true).then(resolve).catch(reject);
					}, 1000);
				} else {
					reject(err);
				}
			},
			newPasswordRequired: (data) => {
				console.log("newPasswordRequired:", data);
				resolve(data);
			},
		});
	});
};

export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          const refreshToken = session.getRefreshToken();
          user.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err);
            } else {
              const accessToken = session.getAccessToken().getJwtToken();
              Cookies.set("accessToken", accessToken, {
                secure: true,
                sameSite: "Strict",
              });
              resolve(session);
            }
          });
        }
      });
    } else {
      reject(new Error("No current user"));
    }
  });
};

