import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSelector = ({ currentLanguage, setCurrentLanguage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const languages = [
    { code: 'english', label: 'English' },
    { code: 'syllabics', label: 'ᐃᓄᒃᑎᑐᑦ' },
    // { code: 'roman', label: 'Inuktitut' }
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setCurrentLanguage(languageCode);
    handleClose();
  };


  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {languages.map((language) => (
        <Button
          key={language.code}
          onClick={() => setCurrentLanguage(language.code)}
          sx={{
            minWidth: 'auto',
            padding: '4px 12px',
            backgroundColor: currentLanguage === language.code ? 'white' : 'transparent',
            color: currentLanguage === language.code ? 'black' : 'white',
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: currentLanguage === language.code ? 'white' : 'rgba(255, 255, 255, 0.1)',
            },
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '14px',
            boxShadow: 'none',
            border: 'none',
          }}
        >
          {language.label}
        </Button>
      ))}
    </Box>
  );

};


export default LanguageSelector;