import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  Grid,
  Autocomplete,
  FormControl,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
} from "@mui/material";
import { postFormData, getFormData } from "../../../services/api";
import MessageDisplay from "../../Form/MessageDisplay";
import { createFilterOptions } from '@mui/material/Autocomplete';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';


const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
    ignoreCase: true

  });
  
const AddEquipmentModel = ({ onClose,onSkip  }) => {
  // State for options from DB
  const [manufacturers, setManufacturers] = useState([]);
  const [applicationOptions, setApplicationOptions] = useState([]);

  // Predefined application options
  const predefinedApplications = [
    "AWS",
    "ITB",
    "Weather Station",
    "Environmental Monitoring",
    "Climate Research",
    "Hydrology"
  ];

  const [modelInfo, setModelInfo] = useState({
    name: "",
    description: "",
    application: "",
    manufacturer: "",
    accuracy: "",
    precision: "",
    measurement_unit: "",
    range_min: "",
    range_max: "",
    calibration_interval: null,
    calibration_interval_value: '',
    calibration_interval_unit: 'Y',
    });

  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [severity, setSeverity] = useState("success");

  // Fetch manufacturers and applications from DB
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await getFormData('metadata/equipment-model');
        
        // Extract unique manufacturers
        const uniqueManufacturers = [...new Set(
          response
            .map(model => model.manufacturer)
            .filter(Boolean)
        )];
        setManufacturers(uniqueManufacturers);

        // Extract unique applications and combine with predefined ones
        const dbApplications = [...new Set(
          response
            .map(model => model.application)
            .filter(Boolean)
        )];
        
        const combinedApplications = [...new Set([
          ...predefinedApplications,
          ...dbApplications
        ])];
        
        setApplicationOptions(combinedApplications);
      } catch (error) {
        console.error("Error fetching options:", error);
        setMessageContent("Failed to fetch existing options");
        setSeverity("error");
        setMessageOpen(true);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (e) => {
    setModelInfo({ ...modelInfo, [e.target.name]: e.target.value });
  };

  const handleAutocompleteChange = (field, newValue) => {
    setModelInfo({ ...modelInfo, [field]: newValue });
    
    // Add new value to options if it doesn't exist
    if (newValue) {
      if (field === 'manufacturer' && !manufacturers.includes(newValue)) {
        setManufacturers(prev => [...prev, newValue]);
      } else if (field === 'application' && !applicationOptions.includes(newValue)) {
        setApplicationOptions(prev => [...prev, newValue]);
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    if (modelInfo.calibration_interval_value) {
        if (modelInfo.calibration_interval_value <= 0) {
          newErrors.calibration_interval = "Calibration interval must be greater than 0";
        }
        if (!modelInfo.calibration_interval_unit) {
          newErrors.calibration_interval = "Please select a unit for calibration interval";
        }
      }
      
    
    if (!modelInfo.name) newErrors.name = "Model name is required";
    if (!modelInfo.manufacturer) newErrors.manufacturer = "Manufacturer is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      const dataToSubmit = {
        ...modelInfo,
        calibration_interval: modelInfo.calibration_interval_value 
        ? `P${modelInfo.calibration_interval_value}${modelInfo.calibration_interval_unit}`
        : null,
      range_min: modelInfo.range_min ? Number(modelInfo.range_min) : null,
      range_max: modelInfo.range_max ? Number(modelInfo.range_max) : null,
      };
    // Remove helper fields before sending
    delete dataToSubmit.calibration_interval_value;
    delete dataToSubmit.calibration_interval_unit;

      // Remove empty strings
      Object.keys(dataToSubmit).forEach(key => {
        if (dataToSubmit[key] === "") {
          dataToSubmit[key] = null;
        }
      });

      const response=await postFormData('/metadata/equipment-model', dataToSubmit);
      setMessageContent("Equipment model added successfully!");
      setSeverity("success");
      setMessageOpen(true);
      // Pass the model ID back to the parent
      onClose(response.id);
    } catch (error) {
      let errorMessage = "Failed to add equipment model";
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setMessageContent(errorMessage);
      setSeverity("error");
      setMessageOpen(true);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Paper elevation={3} sx={{ width: "100%", maxWidth: "800px", p: 3 }}>
        <Typography variant="h6" gutterBottom>Add Equipment Model</Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TextField
              label="Model Name"
              name="name"
              value={modelInfo.name}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12} md={5}>
          <Autocomplete
  freeSolo
  options={manufacturers}
  value={modelInfo.manufacturer}
  onChange={(event, newValue) => handleAutocompleteChange('manufacturer', newValue)}
  filterOptions={(options, params) => {
    const filtered = filterOptions(options, params);
    
    if (params.inputValue !== '' && 
        !options.includes(params.inputValue) && 
        params.inputValue.length > 0) {
      filtered.push(params.inputValue);
    }
    
    return filtered;
  }}
  onBlur={(event) => {
    if (modelInfo.manufacturer && !manufacturers.includes(modelInfo.manufacturer)) {
      setManufacturers(prev => [...prev, modelInfo.manufacturer]);
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Manufacturer"
      required
      error={!!errors.manufacturer}
      helperText={errors.manufacturer}
    />
  )}
/>

          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={modelInfo.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12} md={7}>
          <Autocomplete
  freeSolo
  options={applicationOptions}
  value={modelInfo.application}
  onChange={(event, newValue) => handleAutocompleteChange('application', newValue)}
  filterOptions={(options, params) => {
    const filtered = filterOptions(options, params);
    
    // Only add the custom value if it's not empty and not already in options
    if (params.inputValue !== '' && 
        !options.includes(params.inputValue) && 
        params.inputValue.length > 0) {
      filtered.push(params.inputValue);
    }
    
    return filtered;
  }}
  onBlur={(event) => {
    // Add the custom value to options when focus is lost
    if (modelInfo.application && !applicationOptions.includes(modelInfo.application)) {
      setApplicationOptions(prev => [...prev, modelInfo.application]);
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Application"
      fullWidth
    />
  )}
/>
          </Grid>

          <Grid item xs={12} md={5}>
            <TextField
              label="Measurement Unit"
              name="measurement_unit"
              value={modelInfo.measurement_unit}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Accuracy"
              name="accuracy"
              value={modelInfo.accuracy}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Precision"
              name="precision"
              value={modelInfo.precision}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

  

          <Grid item xs={12} md={6}>
            <TextField
              label="Range Minimum"
              name="range_min"
              type="number"
              value={modelInfo.range_min}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Range Maximum"
              name="range_max"
              type="number"
              value={modelInfo.range_max}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
                
<Grid item xs={12}>
  <FormControl fullWidth>
    <Box display="flex" alignItems="center" gap={1} mb={1}>
      <Typography variant="subtitle2">
        Calibration Interval
      </Typography>
      <Tooltip title="Specify how often the equipment needs calibration" arrow placement="right">
        <InfoIcon fontSize="small" color="action" sx={{ cursor: 'help' }} />
      </Tooltip>
    </Box>
    
    <Box display="flex" gap={1}>
      <TextField
        type="number"
        label="Value"
        value={modelInfo.calibration_interval_value || ''}
        onChange={(e) => {
          const value = e.target.value;
          const unit = modelInfo.calibration_interval_unit || 'Y';
          setModelInfo({
            ...modelInfo,
            calibration_interval_value: value,
            calibration_interval: value ? `P${value}${unit}` : null
          });
        }}
        inputProps={{ min: 1 }}
        sx={{ width: '60%' }}
        error={!!errors.calibration_interval}
      />
      
      <FormControl sx={{ width: '40%' }}>
        <InputLabel>Unit</InputLabel>
        <Select
          value={modelInfo.calibration_interval_unit || 'Y'}
          label="Unit"
          onChange={(e) => {
            const unit = e.target.value;
            const value = modelInfo.calibration_interval_value || '';
            setModelInfo({
              ...modelInfo,
              calibration_interval_unit: unit,
              calibration_interval: value ? `P${value}${unit}` : null
            });
          }}
          error={!!errors.calibration_interval}
        >
          <MenuItem value="Y">Years</MenuItem>
          <MenuItem value="M">Months</MenuItem>
          <MenuItem value="W">Weeks</MenuItem>
          <MenuItem value="D">Days</MenuItem>
        </Select>
      </FormControl>
    </Box>

    {errors.calibration_interval && (
      <FormHelperText error>
        {errors.calibration_interval}
      </FormHelperText>
    )}
  </FormControl>
</Grid>

        </Grid>

        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Add Model
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>

        <MessageDisplay
          message={messageContent}
          severity={severity}
          open={messageOpen}
          onClose={() => setMessageOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default AddEquipmentModel;