import { useState, useEffect } from "react";
import axios from "axios";
import { stationMetaData } from "../utils/rawData";

const API_BASE_URL = "https://client-api.silanga.ca/aws";
const API_KEY = process.env.REACT_APP_API_TOKEN;
export const useCurrentWeatherData = (stationId) => {
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    const fetchCurrentData = async () => {
      if (!stationId) {
        setLoading(false);
        return;
      }
      // Check if station is operational
      const station = stationMetaData.find((s) => s.id === stationId);
      if (!station || station.status !== "Operational") {
        setError("Station is not operational");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(API_BASE_URL, {
          params: { uid: stationId },
          headers: { "x-api-key": API_KEY },
        });
        console.log(response.data[0]);
        setCurrentData(response.data[0]);
      } catch (err) {
        setError("Error fetching current weather data.");
        setCurrentData(null);
        console.error("Error fetching current weather data:", err);
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
      fetchCurrentData();
  
      // Schedule hourly refetch
      const scheduleNextRefetch = () => {
        const now = new Date();
        const nextRefetchTime = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours() + 1,
          3, // 3 minutes past the hour
          0
        );
        
                return setTimeout(() => {
          fetchCurrentData();
          scheduleNextRefetch();
        }, nextRefetchTime - now);
      };
  
      const timeoutId = scheduleNextRefetch();
      return () => clearTimeout(timeoutId);

  }, [stationId]);

  return { currentData, loading, error };
};

export const useLastWeekWeatherData = (stationId) => {
  const [lastWeekData, setLastWeekData] = useState(null);
  const [weekDataLoading, setLoading] = useState(true);
  const [weekDataError, setError] = useState(null);

    const fetchLastWeekData = async () => {
      if (!stationId) {
        setLoading(false);
        return;
      }
      // Check if station is operational
      const station = stationMetaData.find((s) => s.id === stationId);
      if (!station || station.status !== "Operational") {
        setError("Station is not operational");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(API_BASE_URL, {
          params: { uid: stationId, last_week: true },
          headers: { "x-api-key": API_KEY },
        });
        setLastWeekData(response.data);
      } catch (err) {
        // setError("Error fetching last week weather data.");
        setLastWeekData(null);
        console.error("Error fetching last week weather data:", err);
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
      fetchLastWeekData();
  
      // Schedule hourly refetch
      const scheduleNextRefetch = () => {
        const now = new Date();
        const nextRefetchTime = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours() + 1,
          3, // 3 minutes past the hour
          0
        );
        
                return setTimeout(() => {
                  fetchLastWeekData();
          scheduleNextRefetch();
        }, nextRefetchTime - now);
      };
  
      const timeoutId = scheduleNextRefetch();
      return () => clearTimeout(timeoutId);

  }, [stationId]);



  return { lastWeekData, weekDataLoading, weekDataError };
};

{
  /*uncomment this code to use the all weather data hook*/
}
// export const useAllWeatherData = (stationId, limit, offset) => {
// 	const [allData, setAllData] = useState(null);
// 	const [allDataTotalRecords, setallDataTotalRecords] = useState(0);
// 	const [allDataLoading, setLoading] = useState(true);
// 	const [allDataError, setError] = useState(null);

// 	useEffect(() => {
// 		const fetchAllData = async () => {
// 			if (!stationId) {
// 				setLoading(false);
// 				return;
// 			}

// 			setLoading(true);
// 			setError(null);

// 			try {
// 				const response = await axios.get(API_BASE_URL, {
// 					params: {
// 						uid: stationId,
// 						all_data: true,
// 						limit: limit,
// 						offset: offset,
// 					},
// 					headers: {
// 						"x-api-key": API_KEY,
// 					},
// 				});
// 				setAllData(response.data.data);
// 				setallDataTotalRecords(response.data.pagination.total_records);
// 				if (response.data.statusCode === 400) {
// 					// setError(JSON.parse(response.data.body).error + JSON.parse(response.data.body).details)
// 					setError(JSON.parse(response.data.body).error);
// 				}
// 			} catch (err) {
// 				setError("Error fetching All weather data.");
// 				setAllData(null);
// 				console.error("Error fetching All weather data:", err);
// 			} finally {
// 				setLoading(false);
// 			}
// 		};

// 		fetchAllData();
// 	}, [stationId, limit, offset]);

// 	return { allData, allDataLoading, allDataError, allDataTotalRecords };
// };
