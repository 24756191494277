import React, { useState } from "react";
import {
	Typography,
	Grid,
	Box,
	Paper,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Chip,
	IconButton,
	Tooltip,
	Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import theme from "../../../utils/theme";
import DOMPurify from "dompurify";
import { deleteFormData } from "../../../services/api";
import AddVisitInfoDialog from "./AddVisitInfoDialog";

const createMarkup = (html) => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const VisitInfoDisplay = ({
	visitData,
	personsData,
	sitePersonsData,
	onRefetch
	
}) => {
	console.log(sitePersonsData);
	const formatDate = (date) => {
		if (!date) return "N/A";

		// Check if the date string contains time information
		const hasTime = date.includes("T") || date.includes(":");

		if (hasTime) {
			// If it has time, format with date and time
			return new Date(date).toLocaleString("en-US", {
				year: "numeric",
				month: "short",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			});
		} else {
			// If it's date only, format only the date
			return new Date(date).toLocaleDateString("en-US", {
				year: "numeric",
				month: "short",
				day: "numeric",
			});
		}
	};
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [selectedVisit, setSelectedVisit] = useState(null);
  
	const handleEdit = (visit) => {
		setSelectedVisit(visit); // Set current visit data
		setEditDialogOpen(true); // Open edit dialog
	  };
	
	  const handleCloseEditDialog = () => {
		setEditDialogOpen(false);
		setSelectedVisit(null);
	  };
	
	  const handleUpdateSuccess = () => {
		onRefetch(); // Refetch data after successful update
		handleCloseEditDialog(); // Close dialog
	  };
	
		const handleDelete = async (type, id, event) => {
		event.stopPropagation(); // Prevents accordion expansion when clicking delete
		try {
			const endpoint = `metadata/visit-info`; // API endpoint for deleting visit info
			const params = { id }; // Pass the ID of the visit to be deleted
			await deleteFormData(endpoint, params); // Call delete API
			console.log(`Deleted ${type} with ID:`, id);
			onRefetch(); // Refetch data after deletion to update UI
		} catch (error) {
			console.error("Error deleting visit:", error.message);
		}
	};

	return (
		<Box>
			{Array.isArray(visitData) &&
				visitData.map((visit) => (
					<Accordion
						key={visit.site_visit_id}
						sx={{
							mb: 2,
							boxShadow: "none",
							border: "1px solid black",
							borderRadius: "8px !important",
							transition: "all 0.3s ease-in-out", // Add transition for smooth animation
							"&:before": {
								display: "none",
							},
							"& .MuiAccordionSummary-root": {
								borderRadius: "8px 8px 0 0",
								minHeight: "64px !important",
								transition: "all 0.3s ease-in-out", // Add transition for background color
								"& .MuiAccordionSummary-content": {
									margin: "12px 0 !important",
								},
								// Style when expanded
								"&.Mui-expanded": {
									backgroundColor: "#333333",
									transform: "scale(1.001)", // Slight scale effect
									"& .MuiTypography-root": {
										color: "#FFFFFF",
										transition: "color 0.3s ease-in-out",
									},
									"& .MuiChip-root": {
										borderColor: "#FFFFFF",
										transition: "all 0.3s ease-in-out",
										"& .MuiChip-label": {
											color: "#FFFFFF",
										},
									},
									"& .MuiIconButton-root": {
										color: "#FFFFFF",
										transition: "color 0.3s ease-in-out",
									},
									"& .MuiSvgIcon-root": {
										color: "#FFFFFF",
										transition: "color 0.3s ease-in-out",
									},
								},
								// Hover effect
								"&:hover": {
									backgroundColor: "rgba(0, 0, 0, 0.04)",
									"&.Mui-expanded": {
										backgroundColor: "#333333",
									},
								},
							},
							"& .MuiAccordionDetails-root": {
								borderTop: "1px solid black",
								borderRadius: "0 0 8px 8px",
								padding: "16px",
								// Add animation for content
								animation: "fadeIn 0.3s ease-in-out",
							},
							"&.Mui-expanded": {
								margin: "0 0 16px 0 !important",
								transform: "translateY(4px)", // Slight movement when expanded
							},
							"&:last-of-type": {
								marginBottom: "16px !important",
							},
							// Add keyframes for fade in animation
							"@keyframes fadeIn": {
								from: {
									opacity: 0,
									transform: "translateY(-10px)",
								},
								to: {
									opacity: 1,
									transform: "translateY(0)",
								},
							},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							sx={{
								"& .MuiAccordionSummary-content": {
									margin: "12px 0",
								},
							}}
						>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
							>
								<Box display="flex" alignItems="center" gap={2}>
									<Typography variant="h6">{visit.reason_for_visit}</Typography>
									<Chip
										label={formatDate(visit.visit_date)}
										size="small"
										variant="outlined"
										sx={{
											border: "1px solid black",
											backgroundColor: "transparent",
											"& .MuiChip-label": {
												color: "black",
											},
										}}
									/>
								</Box>
								<Box display="flex" gap={1}>
									<Tooltip title="Edit Visit">
										<IconButton size="small">
											<EditIcon fontSize="small" 
											onClick={() => handleEdit(visit)}/>
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete Visit">
										<IconButton
											size="small"
											onClick={(e) =>
												handleDelete("visit", visit.site_visit_id, e)
											}
										>
											<DeleteIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
						</AccordionSummary>

						<AccordionDetails>
							<Grid container spacing={3}>
								{/* Visit Details */}
								<Grid item xs={12} md={6}>
									<Paper
										elevation={0}
										sx={{
											p: 2,
											border: "1px solid black",
											borderRadius: "8px",
											height: "100%", // To maintain equal height with Notes & Issues
										}}
									>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mb={2}
										>
											<Typography variant="h6">Visit Period</Typography>
										</Box>
										<Box>
											<Box mb={2}>
												<Typography>
													<strong>Onsite:</strong>
												</Typography>
												<Typography variant="body2" sx={{ mt: 1 }}>
													Start: {formatDate(visit.onsite_start)}
												</Typography>
												<Typography variant="body2">
													End: {formatDate(visit.onsite_end)}
												</Typography>
											</Box>
											{
												<Box mt={2}>
													<Typography>
														<strong>Outage:</strong>
													</Typography>
													<Typography variant="body2" sx={{ mt: 1 }}>
														Start: {formatDate(visit.outage_start)}
													</Typography>
													<Typography variant="body2">
														End: {formatDate(visit.outage_end)}
													</Typography>
												</Box>
											}
										</Box>
									</Paper>
								</Grid>

								{/* Assigned Personnel Section */}
								<Grid item xs={12} md={6}>
									<Paper
										elevation={0}
										sx={{
											p: 2,
											border: "1px solid black",
											borderRadius: "8px",
										}}
									>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mb={2}
										>
											<Typography variant="h6">
												Assigned Personnel (
												{sitePersonsData?.filter(
													(sp) => sp.visit_info_id === visit.site_visit_id
												).length || 0}
												)
											</Typography>
											{/* <Button
												variant="outlined"
												size="small"
												startIcon={<PersonAddIcon />}
												onClick={(e) => {
													e.stopPropagation();
													onAddPerson(visit.site_visit_id);
												}}
												sx={{
													borderColor: "black",
													color: "black",
													"&:hover": {
														borderColor: "black",
														backgroundColor: "rgba(0, 0, 0, 0.04)",
													},
												}}
											>
												Add Person
											</Button> */}
										</Box>

										{sitePersonsData?.filter(
											(sp) => sp.visit_info_id === visit.site_visit_id
										).length > 0 ? (
											<>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														maxHeight: "300px",
														overflowY: "auto",
														mb: 2,
														pr: 2, // Add padding to the right to offset scrollbar
														// Custom scrollbar styling
														"&::-webkit-scrollbar": {
															width: "8px",
															marginLeft: "8px", // This won't work directly on the scrollbar
														},
														"&::-webkit-scrollbar-track": {
															backgroundColor: theme.palette.secondary.main,
															marginLeft: "10px", // This won't work directly on the track
															borderRadius: "10px",
														},
														"&::-webkit-scrollbar-thumb": {
															backgroundColor: theme.palette.primary.main,
															borderRadius: "10px",
															border: "2px solid transparent", // Add transparent border
															backgroundClip: "padding-box", // This creates space around the thumb
														},
														"&::-webkit-scrollbar-thumb:hover": {
															backgroundColor: theme.palette.primary.main,
														},
														// Create margin effect using a wrapper
														position: "relative",
														"&::before": {
															content: '""',
															position: "absolute",
															top: 0,
															right: 0,
															width: "10px", // Width of the margin
															height: "100%",
															backgroundColor: "transparent",
														},
													}}
												>
													{sitePersonsData
														?.filter(
															(sp) => sp.visit_info_id === visit.site_visit_id
														)
														.map((sitePerson) => (
															<Paper
																key={sitePerson.metadata_site_person_id}
																elevation={0}
																sx={{
																	p: 2,
																	border: "1px solid black",
																	borderRadius: "8px",
																	display: "flex",
																	alignItems: "center",
																	gap: 2,
																	width: "100%",
																}}
															>
																<PersonIcon sx={{ color: "grey.500" }} />
																<Box sx={{ flexGrow: 1 }}>
																	<Box
																		display="flex"
																		alignItems="center"
																		gap={1}
																	>
																		<Typography variant="subtitle2">
																			{sitePerson.person.firstname}{" "}
																			{sitePerson.person.lastname}
																		</Typography>
																		<Chip
																			label={sitePerson.role[0]}
																			size="small"
																			variant="outlined"
																			sx={{
																				border: "1px solid black",
																				height: "20px",
																				backgroundColor: "transparent",
																			}}
																		/>
																	</Box>
																	<Typography
																		variant="body2"
																		color="textSecondary"
																		sx={{ mt: 0.5 }}
																	>
																		{sitePerson.person.organization}
																	</Typography>
																</Box>
																{/* <Box>
																	<Tooltip title="Edit Assignment">
																		<IconButton
																			size="small"
																			// onClick={(e) => {
																			// 	e.stopPropagation();
																			// 	handleEdit(
																			// 		"person",
																			// 		sitePerson.metadata_site_person_id
																			// 	);
																			// }}
																		>
																			<EditIcon fontSize="small" />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Remove Assignment">
																		<IconButton
																			size="small"
																			// onClick={(e) => {
																			// 	e.stopPropagation();
																			// 	handleDelete(
																			// 		"person",
																			// 		sitePerson.metadata_site_person_id
																			// 	);
																			// }}
																		>
																			<DeleteIcon fontSize="small" />
																		</IconButton>
																	</Tooltip>
																</Box> */}
															</Paper>
														))}
												</Box>
											</>
										) : (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													gap: 2,
													py: 3,
													borderRadius: "4px",
												}}
											>
												<PersonIcon sx={{ fontSize: 40, color: "grey.500" }} />
												<Typography color="textSecondary">
													No personnel assigned to this visit yet
												</Typography>
											</Box>
										)}
									</Paper>
								</Grid>
								{/* Notes & Issues */}
								<Grid item xs={12} md={12}>
									<Paper
										elevation={0}
										sx={{
											p: 2,
											border: "1px solid black",
											borderRadius: "8px",
											height: "100%",
										}}
									>
										<Box
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											mb={2}
										>
											<Typography variant="h6">Notes & Issues</Typography>
										</Box>

										{/* Issues Section */}
										<Box mb={2}>
											<Typography>
												<strong>Issues:</strong>
											</Typography>
											<Typography variant="body2" sx={{ mt: 1 }}>
												{visit.visit_issues ? (
													<div
														dangerouslySetInnerHTML={{
															__html: visit.visit_issues,
														}}
													/>
												) : (
													<Typography variant="body2" color="text.secondary">
														N/A
													</Typography>
												)}
											</Typography>
										</Box>

										{/* Notes Section */}
										<Box>
											<Typography>
												<strong>Notes:</strong>
											</Typography>
											<Typography variant="body2" sx={{ mt: 1 }}>
												{visit.visit_notes ? (
													<div
														dangerouslySetInnerHTML={{
															__html: visit.visit_notes,
														}}
													/>
												) : (
													<Typography variant="body2" color="text.secondary">
														N/A
													</Typography>
												)}
											</Typography>
										</Box>
									</Paper>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				))}
				 {/* Edit Dialog */}
				 {selectedVisit && (
        <AddVisitInfoDialog
          open={editDialogOpen}
          onClose={handleCloseEditDialog}
          onSuccess={handleUpdateSuccess}
          stationId={selectedVisit.station_id}
          initialData={selectedVisit} // Pass prefilled data
		  sitePersonsData={sitePersonsData}

        />
      )}
		</Box>
	);
};

export default VisitInfoDisplay;
