// src/pages/StaticPages/PageTemplate.js
import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Chip,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useTranslation } from "../../context/TranslationContext";
// Import at the top
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PageTemplate = ({ content }) => {
  const { translate } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Helper function for safe translation
  const safeTranslate = (text) => {
    if (!text) return "";
    return translate(text);
  };


  // Bullet Point Component
  const BulletPoint = ({ content }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        mb: 2.5,
        pl: 2,
        maxWidth: "800px",
        mx: "auto",
      }}
    >
      <Typography
        component="span"
        sx={{
          mr: 2,
          color: "text.secondary",
          fontSize: { xs: "1rem", md: "1.1rem" },
        }}
      >
        •
      </Typography>
      <Typography
        component="span"
        sx={{
          lineHeight: 1.8,
          fontSize: { xs: "1rem", md: "1.1rem" },
          letterSpacing: "0.3px",
          color: "text.primary",
        }}
      >
        {content}
      </Typography>
    </Box>
  );

  // Combined text parsing function
  const parseText = (text) => {
    if (!text) return "";
  
    // First check if it's a bullet point
    if (text.trim().startsWith("•")) {
      // Translate the entire text including emails
      const translatedText = safeTranslate(text);
      // Remove bullet point and trim
      const content = translatedText.substring(1).trim();
      
      return <BulletPoint content={content} />;
    }
  
    // If not a bullet point, use existing link parsing
    return parseTextWithLinks(text);
  };
      // Metadata component for images
  const ImageMetadata = ({ author }) => {
    if (!author) return null;

    return (
      <Typography
        variant="caption"
        sx={{
          mt: 1,
          display: "block",
          textAlign: "center",
          color: "text.secondary",
          fontStyle: "italic",
        }}
      >
        {author}
      </Typography>
    );
  };

  const parseTextWithLinks = (text) => {
    if (!text) return "";

    const translatedText = safeTranslate(text);
    const parts = translatedText.split(/\[\[|\]\]/);

    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        const [linkText, url, external] = part.split("|");
        return (
          <Link
            key={index}
            href={url}
            target={external ? "_blank" : undefined}
            rel={external ? "noopener noreferrer" : undefined}
          >
            {linkText}
          </Link>
        );
      }
    });
  };

  // Links rendering
  const renderLinks = (links) => {
    if (!links?.length) return null;

    return links.map((link, index) => {
      if (!link?.url || !link?.text) return null;

      return (
        <Link
          key={index}
          component={link.external ? "a" : RouterLink}
          to={!link.external ? link.url : undefined}
          href={link.external ? link.url : undefined}
          target={link.external ? "_blank" : undefined}
          rel={link.external ? "noopener noreferrer" : undefined}
          sx={{ ml: 1 }}
        >
          {safeTranslate(link.text)}
        </Link>
      );
    });
  };

  // Text section rendering
  const renderTextSection = (section) => (
    <Box sx={{ mb: 4 }}>
      {section.title && (
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            textAlign: "center",
            mb: 3,
            fontWeight: 500,
          }}
        >
          {safeTranslate(section.title)}
        </Typography>
      )}
      {section.content?.map((item, index) => (
        <Typography
          key={index}
          paragraph
          sx={{
            maxWidth: "800px",
            mx: "auto",
            lineHeight: 1.8,
            fontSize: { xs: "1rem", md: "1.1rem" },
            letterSpacing: "0.3px",
            mb: 2.5,
            color: "text.primary",
          }}
        >
          {parseText(item.text)} {/* Remove safeTranslate here */}
        </Typography>
      ))}
    </Box>
  );

  // Single image rendering
  const renderImageSection = (section) => {
    if (!section.image) return null;

    return (
      <Box sx={{ mb: 4 }}>
        {section.title && (
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              fontWeight: 500,
            }}
          >
            {safeTranslate(section.title)}
          </Typography>
        )}
        {section.subtitle && (
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              color: "text.secondary",
            }}
          >
            {safeTranslate(section.subtitle)}
          </Typography>
        )}
        <Box
          component="img"
          src={section.image}
          alt={section.caption || section.title || ""}
          sx={{
            width: section.imageFit?.width || "100%",
            maxWidth: section.imageFit?.maxWidth || "100%",
            height: section.imageFit?.height || "auto",
            maxHeight: section.imageFit?.maxHeight || {
              xs: "300px",
              md: "500px",
            },
            objectFit: section.imageStyle || "cover",
            display: "block",
            margin: section.imagePosition === "center" ? "0 auto" : "0",
            marginLeft: section.imagePosition === "right" ? "auto" : undefined,
          }}
        />
        <Box sx={{ textAlign: "center" }}>
          {section.caption && (
            <Typography variant="caption" display="block" mt={1}>
              {safeTranslate(section.caption)}
            </Typography>
          )}
          <ImageMetadata author={section.author} />
        </Box>
      </Box>
    );
  };

  // Mixed content rendering
  const renderMixedSection = (section) => {
    if (!section.content?.length) return null;

    return (
      <Box sx={{ mb: 4 }}>
        {section.title && (
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              fontWeight: 500,
            }}
          >
            {safeTranslate(section.title)}
          </Typography>
        )}
        {section.subtitle && (
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              color: "text.secondary",
            }}
          >
            {safeTranslate(section.subtitle)}
          </Typography>
        )}
        {section.content.map((item, index) => {
          if (!item?.type) return null;

          return (
            <Box key={index}>
              {item.type === "text" && (
                <Typography
                  paragraph
                  sx={{
                    maxWidth: "800px",
                    mx: "auto",
                    lineHeight: 1.8,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    letterSpacing: "0.3px",
                    mb: 2.5,
                    color: "text.primary",
                  }}
                >
                  {safeTranslate(item.text)}
                  {renderLinks(item.links)}
                </Typography>
              )}
              {item.type === "image" && item.image && (
                <Box
                  sx={{
                    my: 3,
                    maxWidth: "900px",
                    mx: "auto",
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.caption || ""}
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxHeight: { xs: "300px", md: "500px" },
                      objectFit: "cover",
                      borderRadius: "12px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  />
                  {(item.caption || item.author) && (
                    <Box
                      sx={{
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      {item.caption && (
                        <Typography
                          variant="caption"
                          display="block"
                          sx={{
                            mb: 1,
                            color: "text.secondary",
                          }}
                        >
                          {safeTranslate(item.caption)}
                        </Typography>
                      )}
                      <ImageMetadata author={item.author} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };
  // Add state for popup
  const [selectedImage, setSelectedImage] = useState(null);

  const renderImageGrid = (section) => {
    if (!section.images?.length) return null;

    const handleImageClick = (item) => {
      setSelectedImage(item);
    };

    const handleClose = () => {
      setSelectedImage(null);
    };

    return (
      <Box sx={{ mb: 4 }}>
        {section.title && (
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              fontWeight: 500,
            }}
          >
            {safeTranslate(section.title)}
          </Typography>
        )}
        {section.subtitle && (
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 3,
              color: "text.secondary",
            }}
          >
            {safeTranslate(section.subtitle)}
          </Typography>
        )}

        <ImageList
          sx={{
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
          }}
          variant="quilted"
          cols={isMobile ? 1 : section.gridCols || 3}
          rowHeight={isMobile ? "auto" : 200}
          gap={8}
        >
          {section.images.map((item, index) => {
            if (!item?.img) return null;

            return (
              <ImageListItem
                key={index}
                cols={isMobile ? 1 : item.cols || 1}
                rows={isMobile ? 1 : item.rows || 1}
                onClick={() => handleImageClick(item)}
                sx={{
                  cursor: "pointer",
                  height: `${(item.rows || 1) * 200}px !important`,
                  "& img": {
                    height: "100% !important",
                  },
                }}
              >
                <img
                  src={item.img}
                  alt={item.title || ""}
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
                <ImageListItemBar
                  title={safeTranslate(item.title)}
                  subtitle={
                    <Box>
                      {item.subtitle && safeTranslate(item.subtitle)}
                      {item.author && (
                        <Typography
                          variant="caption"
                          sx={{ display: "block", fontStyle: "italic" }}
                        >
                          {item.author}
                        </Typography>
                      )}
                    </Box>
                  }
                  position="bottom"
                />
              </ImageListItem>
            );
          })}
        </ImageList>

        {/* Image Popup Dialog */}
        <Dialog
          open={Boolean(selectedImage)}
          onClose={handleClose}
          maxWidth="md" // Changed from xl to md for smaller size
          fullWidth
          sx={{
            "& .MuiDialog-paper": {
              width: "90%",
              height: "auto", // Changed from 100% to auto
              margin: { xs: 2, sm: 3 },
              overflow: "hidden",
              borderRadius: "12px", // Add curved borders
            },
          }}
        >
          <DialogContent
            sx={{
              p: 0,
              position: "relative",
              bgcolor: "background.paper", // Changed from black to paper background
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "text.primary",
                bgcolor: "rgba(255,255,255,0.7)",
                zIndex: 2,
                "&:hover": {
                  bgcolor: "rgba(255,255,255,0.9)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {selectedImage && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={selectedImage.img}
                    alt={selectedImage.title}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "70vh",
                      objectFit: "contain",
                      margin: "auto",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    p: 2,
                    bgcolor: "background.paper",
                  }}
                >
                  {selectedImage.title && (
                    <Typography variant="h6" gutterBottom>
                      {safeTranslate(selectedImage.title)}
                    </Typography>
                  )}
                  {selectedImage.subtitle && (
                    <Typography variant="body2" gutterBottom>
                      {safeTranslate(selectedImage.subtitle)}
                    </Typography>
                  )}
                  {selectedImage.author && (
                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        fontStyle: "italic",
                        color: "text.secondary",
                        // mt: 1
                      }}
                    >
                      {selectedImage.author}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  // Main section renderer
  const renderSection = (section) => {
    if (!section?.type) return null;

    switch (section.type) {
      case "text":
        return renderTextSection(section);
      case "image":
        return renderImageSection(section);
      case "imageGrid":
        return renderImageGrid(section);
      case "mixed":
        return renderMixedSection(section);
      default:
        return null;
    }
  };

  // Main render
  return (
    <Container
      maxWidth="lg"
      sx={{
        py: { xs: 3, md: 5 },
        px: { xs: 2, md: 3 },
      }}
    >
      {content?.heading && (
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontSize: { xs: "2rem", md: "3rem" },
            textAlign: "center",
            mb: 3,
            fontWeight: 500,
            color: "text.primary",
          }}
        >
          {safeTranslate(content.heading)}
        </Typography>
      )}
      {content?.subtitle && (
        <Typography
          variant="h6"
          gutterBottom
          color="text.secondary"
          sx={{
            fontSize: { xs: "1.1rem", md: "1.25rem" },
            textAlign: "center",
            mb: 4,
            maxWidth: "800px",
            mx: "auto",
            lineHeight: 1.6,
          }}
        >
          {safeTranslate(content.subtitle)}
        </Typography>
      )}
      <Box
        sx={{
          maxWidth: "900px",
          mx: "auto",
          "& > *": { mb: 5 },
        }}
      >
        {content?.sections?.map((section, index) => (
          <React.Fragment key={index}>{renderSection(section)}</React.Fragment>
        ))}
      </Box>
    </Container>
  );
};

export default PageTemplate;
