import React from "react";
import { Button, CircularProgress } from "@mui/material";

const FormSubmitButton = ({ 
  label = "Submit", 
  loading = false, 
  disabled = false 
}) => (
  <Button 
    type="submit" 
    fullWidth 
    variant="contained" 
    disabled={disabled || loading}
    sx={{ mt: 3, mb: 2 }}
  >
    {loading ? <CircularProgress size={24} color="inherit" /> : label}
  </Button>
);

export default FormSubmitButton;