import { useInfiniteQuery, useQueryClient } from "react-query";
import { getFormData } from "./api";

const fetchAwsMapping = async ({ pageParam = 0 }) => {
  const response = await getFormData("aws-mappings", { page: pageParam, limit: 5 });
  return {
    aws_mappings: response.aws_mappings,
    nextPage: response.has_more ? pageParam + 1 : undefined,
    totalCount: response.total_count,
  };
};

export const useAwsMapping = () => {
  const queryClient = useQueryClient();

  const query = useInfiniteQuery("aws-mappings", fetchAwsMapping, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const refetchRows = async () => {
    await queryClient.resetQueries("aws-mappings");
    return query.refetch();
  };

  return { ...query, refetchRows };
};

