import { Box, Tooltip, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { getCardinalDirection, getQCFlag } from "../../utils/rawFunction";
import { useTranslation } from '../../context/TranslationContext';
import { WindCompass } from "./WindCompass";

const WeatherInfoItem = ({
  label,
  value,
  unit,
  icon: Icon,
  element,
  rotateIcon,
}) => {
  const qcFlag = getQCFlag(element);
  const cardinalDirection = rotateIcon ? getCardinalDirection(value) : "";
  const { translate ,currentLanguage} = useTranslation();

  // Check if there's any issue (either error or warning)
  const hasIssue = element?.qc_value !== undefined && element?.qc_value !== 100;
  const translateCardinalDirection = (cardinalDirection, translate, currentLanguage) => {
    if (currentLanguage === 'english') {
      // For English, return the original compound direction
      return cardinalDirection;
    } else {
      // For syllabics and roman, split and translate individual directions
      const directions = cardinalDirection.split('').filter(char => 'NSEW'.includes(char));
      return directions.map(dir => translate(dir)).join(' ');
    }
  };
  // if (label === "Wind Speed") {
  //   return (
  //     <WindCompass 
  //       windSpeed={element?.wind_speed?.value || 0}
  //       windDirection={element?.wind_direction?.value || 0}
  //     />
  //   );
  // }

  
  return (
    <Box
      sx={{
        borderRadius: "5px",
        border: "1px solid rgba(5, 5, 5, 0.1)",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        {rotateIcon ? (
          <Icon sx={{ mr: 1, transform: `rotate(${value}deg)` }} />
        ) : (
          <Icon sx={{ mr: 1 }} />
        )}
        {!hasIssue && (
          <Typography
            variant="h4"
            sx={{ fontSize: "clamp(1rem, 2vw, 1.5rem)" }}
          >
            {value.toFixed(1)}
            {unit}
          </Typography>
        )}
        {qcFlag && (
          <Tooltip
            title={`QC Flag: ${qcFlag}${
              element.comment ? ` - ${element.comment}` : ""
            }`}
          >
            <WarningIcon color="error" sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Box>
      <Typography variant="caption">
        {translate(label)} {rotateIcon && !hasIssue &&     `(${translateCardinalDirection(cardinalDirection, translate, currentLanguage)})`}

      </Typography>
    </Box>
  );
};

export default WeatherInfoItem;
// import { Box, Tooltip, Typography } from "@mui/material";
// import WarningIcon from "@mui/icons-material/Warning";
// import { getCardinalDirection, getQCFlag } from "../../utils/rawFunction";

// const WeatherInfoItem = ({
// 	label,
// 	value,
// 	unit,
// 	icon: Icon,
// 	element,
// 	rotateIcon,
// }) => {
// 	const qcFlag = getQCFlag(element);
// 	const cardinalDirection = rotateIcon ? getCardinalDirection(value) : "";

// 	// Check if value should be hidden based on QC flag
// 	const shouldHideValue =
// 		element?.qc_value === 0;

// 	// Check if should show red warning icon
// 	const showRedWarning = element?.qc_value === 0;

// 	return (
// 		<Box
// 			sx={{
// 				borderRadius: "5px",
// 				border: "1px solid rgba(5, 5, 5, 0.1)",
// 				padding: "12px",
// 				display: "flex",
// 				flexDirection: "column",
// 				alignItems: "center",
// 				justifyContent: "center",
// 				height: "100%",
// 			}}
// 		>
// 			<Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
// 				{rotateIcon ? (
// 					<Icon sx={{ mr: 1, transform: `rotate(${value}deg)` }} />
// 				) : (
// 					<Icon sx={{ mr: 1 }} />
// 				)}
// 				{!shouldHideValue && (
// 					<Typography variant="h4" sx={{ fontSize: "clamp(1rem, 2vw, 1.5rem)" }}>
// 						{value.toFixed(1)}
// 						{unit}
// 					</Typography>
// 				)}
// 				{qcFlag && (
// 					<Tooltip title={`QC Flag: ${qcFlag}${element.comment ? ` - ${element.comment}` : ''}`}>
// 						<WarningIcon
// 							color={showRedWarning ? "error" : "warning"}
// 							sx={{ ml: 1 }}
// 						/>
// 					</Tooltip>
// 				)}
// 			</Box>
// 			<Typography variant="caption">
// 				{label} {rotateIcon && !shouldHideValue && `(${cardinalDirection})`}
// 			</Typography>
// 		</Box>
// 	);
// };
// export default WeatherInfoItem;
