export const flagFormConfig = {
	title: "Add flag value",
	fields: [
		{
			name: "variable",
			label: "Variable",
			type: "select",
			options: [
				{ value: "temperature_ext", label: "Temperature (External)" },
				{ value: "humidity_ext", label: "Humidity (External)" },
				{ value: "pressure_int", label: "Pressure (Internal)" },
				{ value: "pitch", label: "Pitch" },
				{ value: "roll", label: "Roll" },
				{ value: "wind_speed", label: "Wind Speed" },
				{ value: "wind_direction", label: "Wind Direction" },
				{ value: "wind_gust_speed", label: "Wind Gust Speed" },
				{ value: "humidity_int", label: "Humidity (Internal)" },
				{ value: "wind_gust_direction", label: "Wind Gust Direction" },
				{ value: "voltage", label: "Voltage" },
				{ value: "pressure_ext", label: "Pressure (External)" },
				{ value: "solar", label: "Solar" },
				{ value: "snow_depth", label: "Snow Depth" },
				{ value: "temperature_int", label: "Temperature (Internal)" },
			],
		},
		{ name: "from_date", label: "From Date", type: "datetime-local" },
		{
			name: "to_date",
			label: "To Date",
			type: "datetime-local",
		},
		{
			name: "QC_value",
			label: "Quality Control Value",
			type: "select",
			options: [
				{ value: "-10", label: "Suppressed (-10)" },
				{ value: "-1", label: "Suspect (-1)" },
				{ value: "0", label: "Error (0)" },
				{ value: "10", label: "Doubtful (10)" },
				{ value: "15", label: "Suspect/Warning (15)" },
				{ value: "20", label: "Inconsistent (20)" },
				{ value: "100", label: "Acceptable/Passed (100)" },
					],
		},
		{ name: "comment", label: "Comment", type: "text" },
		{
			name: "aws_mapping_id",
			label: "Station",
			type: "select",
			options: [], // This will be populated dynamically
		},
	],
	validationSchema: {
		variable: { required: true },
		from_date: { required: true },
		QC_value: { required: true },
		aws_mapping_id: { required: true },
		to_date: { required: false },
		until_change: { required: false },
	},
};

export const flagTableConfig = {
	TableId: "flags",
    flagTableTitle: "Flag Data",
    flagTableHeaders : [
        "ID",
        "Station",
        "Variable",
        "From Date",
        "To Date",
        "QC Value",
        "Comment",
        "AWS Mapping ID"
      ],

}