import { useInfiniteQuery, useQueryClient } from "react-query";
import { getFormData } from "./api";
import moment from "moment";

const formatOffset = (offset) => ({
  id: offset.id,
  field_name: offset.field_name,
  from_date: offset.from_date
    ? moment(offset.from_date).format("MM/DD/YYYY, h:mm:ss a")
    : null,
  to_date: offset.to_date
    ? moment(offset.to_date).format("MM/DD/YYYY, h:mm:ss a")
    : null,
    offset_value: offset.offset_value,
  comment: offset.comment,
  aws_mapping_id: offset.aws_mapping_id,
  uid: offset.uid,
  operator: offset.operator,
  custom_expression: offset.custom_expression
});


const fetchOffset = async ({ pageParam = 0 }) => {
  const response = await getFormData("offset", { page: pageParam, limit: 5 });
  console.log(response);
  return {
    offsets: response.offsets.map(formatOffset),
    nextPage: response.has_more ? pageParam + 1 : undefined,
    totalCount: response.total_count,
  };
};

export const useOffsets = () => {
  const queryClient = useQueryClient();

  const query = useInfiniteQuery("offset", fetchOffset, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const refetchRows = async () => {
    await queryClient.resetQueries("offset");
    return query.refetch();
  };

  return { ...query, refetchRows };
};

