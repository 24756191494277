import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";
import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconImage from "./weather-station(color).png";
import pinIcon from "./gps.png";

import axios from "axios";
import { stationMetaData } from "../../utils/rawData";
import {
  IconButton,
  CircularProgress,
  Box,
  Typography,
  Switch,
} from "@mui/material";
import { useQuery, QueryClientProvider, useQueryClient } from "react-query";
import { useWeatherStationsMap } from "../../services/useWeatherStationsMap";

// import RefreshIcon from "@mui/icons-material/Refresh";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "../../context/TranslationContext";
import { createUrlFriendlyName, findStationByNameOrId } from "../../utils/rawFunction";
const DEFAULT_CENTER = [72.49408333333334, -81.69515];
const DEFAULT_ZOOM = 5.2;


const createCustomPinIcon = (iconUrl, text = "", color = "black") => {
  const html = `
	  <div style='text-align: center; position: relative;'>
		<img src='${iconUrl}' style='width: 40px; height: 40px;' />
		<div style='font-size: 12px; color: ${color}; border: 2px dotted red; display: inline-block; padding: 2px 4px; white-space: nowrap;'>${text}</div>
	  </div>
	`;

  return L.divIcon({
    html: html,
    className: "custom-icon",
    iconSize: [40, 60], // Increase the iconSize height to accommodate the text box
    iconAnchor: [20, 60], // Adjust the iconAnchor to position the text box below the icon
  });
};

const UserLocationMarker = ({ isLocationEnabled }) => {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (isLocationEnabled) {
      map.locate().on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, 10);
      });
    } else {
      setPosition(null);
      map.flyTo(DEFAULT_CENTER, DEFAULT_ZOOM);
    }
  }, [map, isLocationEnabled]);

  return position === null ? null : (
    <Marker
      position={position}
      icon={createCustomPinIcon(pinIcon, "You are here", "red")}
    >
      <Popup>You are here</Popup>
    </Marker>
  );
};

const NorthernCanadaMap = () => {
  const MapController = () => {
    const map = useMap();
    const { translate, currentLanguage } = useTranslation();

    useEffect(() => {
      if (map) {
        // Force a map refresh
        setTimeout(() => {
          map.invalidateSize();
        }, 100);
      }
    }, [map]);

    return null;
  };
  const { translate } = useTranslation();

  const navigate = useNavigate();
  const {
    data: weatherStations,
    isLoading,
    isFetching,
    error,
  } = useWeatherStationsMap();
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const mapRef = useRef(null);

  const handleStationClick = (stationId) => {
    const station = findStationByNameOrId(stationId);
    if (station) {
      const urlFriendlyName = createUrlFriendlyName(station.name);
      navigate(`/${urlFriendlyName}`);
    }
  };
  
  
  const handleLocationToggle = () => {
    setIsLocationEnabled(!isLocationEnabled);
  };
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (error)
    return (
      <Typography color="error">An error occurred: {error.message}</Typography>
    );
  // Show loading indicator for both initial load and refreshes
  const showLoadingIndicator = isLoading || isFetching;
// Function to create a custom icon with the station name and weather info below the image
const createCustomIcon = (
  iconUrl,
  stationName,
  info,
  hasError = false,
  status
) => {
  let iconColor, statusText, textColor;
  let opacity = 1;
  let scale = 1;

  switch (status) {
    case "Non-Operational":
      iconColor = "#ad4242"; // Red
      statusText = "Non-Operational";
      textColor = "#ad4242";
      opacity = 0.6;
      break;
    case "Warning":
      iconColor = "#f2d666"; // Yellow
      statusText = "Warning";
      textColor = "#d4a012";
      break;
    case "Operational":
      iconColor = "#78a62b"; // Green
      statusText = info || "Operational";
      textColor = "blue";
      break;
    default:
      iconColor = "#E0E0E0"; // Grey
      statusText = "Unknown Status";
      textColor = "#666666";
      opacity = 0.7;
  }

  // If there's an error, override the status styling
  if (hasError) {
    iconColor = "#ad4242";
    statusText = "Error";
    textColor = "#ad4242";
    opacity = 0.6;
  }

  const html = `
	  <div style='text-align: center; position: relative;'>
		<div style='position: relative; display: inline-block;'>
		  <img 
			src='${iconUrl}' 
			style='
			  width: 40px; 
			  height: 40px; 
			  opacity: ${opacity};
			  filter: drop-shadow(0 2px 2px rgba(0,0,0,0.2));
			'
		  />
		  ${
        hasError || status === "Non-Operational"
          ? `
			<div style="
			  position: absolute;
			  top: -5px;
			  right: -5px;
			  background-color: ${iconColor};
			  color: white;
			  border-radius: 50%;
			  width: 20px;
			  height: 20px;
			  font-size: 16px;
			  line-height: 20px;
			  text-align: center;
			  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
			">×</div>
		  `
          : ""
      }
		</div>
		<div style='
		  font-size: 12px; 
		  color: #333;
		  font-weight: 500;
		  margin-top: 2px;
		  text-shadow: 0 1px 2px rgba(255,255,255,0.8);
		'>
        ${translate(stationName)}
		</div>
		<div style='
  font-size: 10px; 
  color: ${textColor};
  background-color: rgba(255,255,255,0.9);
  padding: 2px 6px;
  border-radius: 10px;
  margin-top: 2px;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  display: inline-block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
'>
  ${translate(statusText)}
</div>


	  </div>
	`;

  return L.divIcon({
    html: html,
    className: "custom-icon",
    iconSize: [60, 80],
    iconAnchor: [30, 40], // Adjust these values
    popupAnchor: [0, -40], // Add this to position popups correctly
  });
};
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        width: "100%",
        border: "1px solid black",
        "& .leaflet-container": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <MapContainer
        center={DEFAULT_CENTER}
        zoom={DEFAULT_ZOOM}
        style={{ height: "100%", width: "100%" }}
        zoomControl={false} // Disable the default zoom control
        minZoom={3}
        maxZoom={10}
        scrollWheelZoom={true}
        ref={mapRef}
        whenReady={(map) => {
          map.target.invalidateSize();
        }}
      >
        <MapController />

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {weatherStations &&
          weatherStations.map((station) => (
            <Marker
              key={station.id}
              position={[station.lat, station.lon]}
              icon={createCustomIcon(
                iconImage,
                station.name,
                station.info,
                station.hasError,
                station.status // Pass the station status
              )}
              eventHandlers={{ click: () => handleStationClick(station.id) }}
            >
              {/* <Popup>
                <div>
                  <p>Station: {station.name}</p>
                  <p>Community: {station.Community}</p>
                  <p>Status: {station.status}</p>
                  <p>Type: {station.type}</p>
                  <p>Location: {station.location}</p>
                  <p>{station.info || "Loading..."}</p>
                </div>
              </Popup> */}
            </Marker>
          ))}
        <UserLocationMarker isLocationEnabled={isLocationEnabled} />
        <ZoomControl position="bottomright" />
      </MapContainer>

      <Box
        sx={{
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: 1000,
          backgroundColor: "white",
          padding: "8px 12px",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
          transition: "background-color 0.2s",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
        onClick={handleLocationToggle}
      >
        <Typography
          variant="body2"
          sx={{
            mr: 1,
            fontWeight: "bold",
            color: isLocationEnabled ? "primary.main" : "text.primary",
          }}
        >
          {translate("Find My Location")}
        </Typography>
        <Switch
          checked={isLocationEnabled}
          color="primary"
          size="small"
          sx={{
            "& .MuiSwitch-thumb": {
              backgroundColor: isLocationEnabled ? "primary.main" : "grey.400",
            },
            "& .MuiSwitch-track": {
              backgroundColor: isLocationEnabled ? "primary.light" : "grey.300",
            },
          }}
        />
      </Box>

      {showLoadingIndicator && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} />
          <Box sx={{ mt: 2 }}>Loading...</Box>
        </Box>
      )}
    </Box>
  );
};

export default NorthernCanadaMap;

//cluster code

// import React, { useState, useEffect, useRef } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   ZoomControl,
//   Popup,
//   useMapEvents,
//   useMap,
// } from "react-leaflet";
// import MarkerClusterGroup from 'react-leaflet-cluster';
// import { useNavigate } from "react-router-dom";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import iconImage from "./weather-station(color).png";
// import pinIcon from "./gps.png";
// import { stationMetaData } from "../../utils/rawData";
// import {
//   IconButton,
//   CircularProgress,
//   Box,
//   Typography,
//   Switch,
// } from "@mui/material";
// import { useWeatherStationsMap } from "../../services/useWeatherStationsMap";

// const DEFAULT_CENTER = [72.49408333333334, -81.69515];
// const DEFAULT_ZOOM = 5.2;

// const createCustomIcon = (iconUrl, stationName, info, hasError = false, status) => {
//   let iconColor, statusText, textColor;
//   let opacity = 1;

//   switch (status) {
//     case "Non-Operational":
//       iconColor = "#ad4242";
//       statusText = "Non-Operational";
//       textColor = "#ad4242";
//       opacity = 0.6;
//       break;
//     case "Warning":
//       iconColor = "#f2d666";
//       statusText = "Warning";
//       textColor = "#d4a012";
//       break;
//     case "Operational":
//       iconColor = "#78a62b";
//       statusText = info || "Operational";
//       textColor = "#2b5f1e";
//       break;
//     default:
//       iconColor = "#E0E0E0";
//       statusText = "Unknown Status";
//       textColor = "#666666";
//       opacity = 0.7;
//   }

//   if (hasError) {
//     iconColor = "#ad4242";
//     statusText = "Error";
//     textColor = "#ad4242";
//     opacity = 0.6;
//   }

//   const html = `
//     <div style='text-align: center; position: relative;'>
//       <div style='position: relative; display: inline-block;'>
//         <img
//           src='${iconUrl}'
//           style='
//             width: 40px;
//             height: 40px;
//             opacity: ${opacity};
//             filter: drop-shadow(0 2px 2px rgba(0,0,0,0.2));
//           '
//         />
//         ${hasError || status === "Non-Operational" ? `
//           <div style="
//             position: absolute;
//             top: -5px;
//             right: -5px;
//             background-color: ${iconColor};
//             color: white;
//             border-radius: 50%;
//             width: 20px;
//             height: 20px;
//             font-size: 16px;
//             line-height: 20px;
//             text-align: center;
//             box-shadow: 0 2px 4px rgba(0,0,0,0.2);
//           ">×</div>
//         ` : ''}
//       </div>
//       <div style='
//         font-size: 12px;
//         color: #333;
//         font-weight: 500;
//         margin-top: 2px;
//         text-shadow: 0 1px 2px rgba(255,255,255,0.8);
//       '>
//         ${stationName}
//       </div>
//       <div style='
//         font-size: 10px;
//         color: ${textColor};
//         background-color: rgba(255,255,255,0.9);
//         padding: 2px 6px;
//         border-radius: 10px;
//         margin-top: 2px;
//         font-weight: 500;
//         box-shadow: 0 1px 3px rgba(0,0,0,0.1);
//       '>
//         ${statusText}
//       </div>
//     </div>
//   `;

//   return L.divIcon({
//     html: html,
//     className: 'custom-icon',
//     iconSize: [60, 80],
//     iconAnchor: [30, 80],
//   });
// };

// const createCustomPinIcon = (iconUrl, text = "", color = "black") => {
//   const html = `
//     <div style='text-align: center; position: relative;'>
//       <img src='${iconUrl}' style='width: 40px; height: 40px;' />
//       <div style='
//         font-size: 12px;
//         color: ${color};
//         border: 2px dotted red;
//         display: inline-block;
//         padding: 2px 4px;
//         white-space: nowrap;
//         background-color: white;
//       '>${text}</div>
//     </div>
//   `;

//   return L.divIcon({
//     html: html,
//     className: "custom-icon",
//     iconSize: [40, 60],
//     iconAnchor: [20, 60],
//   });
// };
// const createClusterCustomIcon = (cluster) => {
//   const count = cluster.getChildCount();
//   const markers = cluster.getAllChildMarkers();

//   // Check cluster status
//   const hasNonOperational = markers.some(marker =>
//     marker.options.status === "Non-Operational" || marker.options.hasError
//   );
//   const hasWarning = !hasNonOperational && markers.some(marker =>
//     marker.options.status === "Warning"
//   );

//   let backgroundColor, textColor;
//   if (hasNonOperational) {
//     backgroundColor = '#ad4242';
//     textColor = '#ffffff';
//   } else if (hasWarning) {
//     backgroundColor = '#f2d666';
//     textColor = '#000000';
//   } else {
//     backgroundColor = '#78a62b';
//     textColor = '#ffffff';
//   }

//   return L.divIcon({
//     html: `
//       <div style="
//         background-color: ${backgroundColor};
//         color: ${textColor};
//         width: 36px;
//         height: 36px;
//         border-radius: 50%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 14px;
//         font-weight: bold;
//         border: 2px solid white;
//         box-shadow: 0 2px 5px rgba(0,0,0,0.2);
//       ">
//         ${count}
//       </div>
//     `,
//     className: 'custom-marker-cluster',
//     iconSize: [36, 36],
//     iconAnchor: [18, 18]
//   });
// };

// const UserLocationMarker = ({ isLocationEnabled }) => {
//   const [position, setPosition] = useState(null);
//   const map = useMap();

//   useEffect(() => {
//     if (isLocationEnabled) {
//       map.locate().on("locationfound", function (e) {
//         setPosition(e.latlng);
//         map.flyTo(e.latlng, 10);
//       });
//     } else {
//       setPosition(null);
//       map.flyTo(DEFAULT_CENTER, DEFAULT_ZOOM);
//     }
//   }, [map, isLocationEnabled]);

//   return position === null ? null : (
//     <Marker
//       position={position}
//       icon={createCustomPinIcon(pinIcon, "You are here", "red")}
//     >
//       <Popup>You are here</Popup>
//     </Marker>
//   );
// };

// const NorthernCanadaMap = () => {
//   const navigate = useNavigate();
//   const {
//     data: weatherStations,
//     isLoading,
//     isFetching,
//     error,
//   } = useWeatherStationsMap();
//   const [isLocationEnabled, setIsLocationEnabled] = useState(false);
//   const mapRef = useRef(null);

//   const handleStationClick = (stationId) => {
//     navigate(`/${stationId}`);
//   };

//   const handleLocationToggle = () => {
//     setIsLocationEnabled(!isLocationEnabled);
//   };

//   if (error) return (
//     <Typography color="error">An error occurred: {error.message}</Typography>
//   );

//   const showLoadingIndicator = isLoading || isFetching;

//   return (
//     <Box
//       sx={{
//         position: "relative",
//         height: "100vh",
//         width: "100%",
//         border: "1px solid black",
//       }}
//     >
//       <MapContainer
//         center={DEFAULT_CENTER}
//         zoom={DEFAULT_ZOOM}
//         style={{ height: "100%", width: "100%" }}
//         zoomControl={false}
//         minZoom={3}
//         maxZoom={10}
//         scrollWheelZoom={true}
//         ref={mapRef}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />

//         <MarkerClusterGroup
//           chunkedLoading
//           iconCreateFunction={createClusterCustomIcon}
//           maxClusterRadius={50}
//           spiderfyOnMaxZoom={true}
//           showCoverageOnHover={false}
//           zoomToBoundsOnClick={true}
//         >
//           {weatherStations &&
//             weatherStations.map((station) => (
//               <Marker
//                 key={station.id}
//                 position={[station.lat, station.lon]}
//                 icon={createCustomIcon(
//                   iconImage,
//                   station.name,
//                   station.info,
//                   station.hasError,
//                   station.status
//                 )}
//                 eventHandlers={{ click: () => handleStationClick(station.id) }}
//                 status={station.status}
//                 hasError={station.hasError}
//               >
//                 <Popup>
//                   <div style={{ minWidth: '200px' }}>
//                     <p><strong>Station:</strong> {station.name}</p>
//                     <p><strong>Community:</strong> {station.Community}</p>
//                     <p><strong>Status:</strong> {station.status}</p>
//                     <p><strong>Type:</strong> {station.type}</p>
//                     <p><strong>Location:</strong> {station.location}</p>
//                     <p>{station.info || "Loading..."}</p>
//                   </div>
//                 </Popup>
//               </Marker>
//             ))}
//         </MarkerClusterGroup>

//         <UserLocationMarker isLocationEnabled={isLocationEnabled} />
//         <ZoomControl position="bottomright" />
//       </MapContainer>

//       <Box
//         sx={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           zIndex: 1000,
//           backgroundColor: "white",
//           padding: "8px 12px",
//           borderRadius: "4px",
//           boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
//           display: "flex",
//           alignItems: "center",
//           cursor: "pointer",
//           userSelect: "none",
//           transition: "background-color 0.2s",
//           "&:hover": {
//             backgroundColor: "rgba(0, 0, 0, 0.1)",
//           },
//         }}
//         onClick={handleLocationToggle}
//       >
//         <Typography
//           variant="body2"
//           sx={{
//             mr: 1,
//             fontWeight: "bold",
//             color: isLocationEnabled ? "primary.main" : "text.primary",
//           }}
//         >
//           Find My Location
//         </Typography>
//         <Switch
//           checked={isLocationEnabled}
//           color="primary"
//           size="small"
//           sx={{
//             "& .MuiSwitch-thumb": {
//               backgroundColor: isLocationEnabled ? "primary.main" : "grey.400",
//             },
//             "& .MuiSwitch-track": {
//               backgroundColor: isLocationEnabled ? "primary.light" : "grey.300",
//             },
//           }}
//         />
//       </Box>

//       {showLoadingIndicator && (
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             zIndex: 1000,
//             backgroundColor: "rgba(255, 255, 255, 0.8)",
//             padding: "20px",
//             borderRadius: "10px",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <CircularProgress size={50} />
//           <Box sx={{ mt: 2 }}>Loading...</Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default NorthernCanadaMap;
