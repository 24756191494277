import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Container,
  Skeleton,
  Box,
  Drawer,
  IconButton,
  Button,
  Backdrop,
  useMediaQuery,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PushPinIcon from "@mui/icons-material/PushPin";
import WeatherDashboard from "../WeatherDashboard/WeatherDashboard";
import { useCurrentWeatherData } from "../../hooks/useWeatherData";
import { useLastWeekWeatherData } from "../../hooks/useWeatherData";
import { useAllWeatherData } from "../../hooks/useWeatherData";

import WeatherChart from "../Chart/WeatherChart";
import AllStationsList from "../AllStations/AllStationsList";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import DataTable from "../StationDataTable/DataTable";
import ReportIssueButton from "./ReportIssueButton.js";
import NonOperationalStation from "../WeatherDashboard/NonOperationalStation.js";
import { stationMetaData } from "../../utils/rawData.js";
import { createUrlFriendlyName, findStationByNameOrId } from "../../utils/rawFunction.js";
import NotFound from "../PageNotAFound/NotFound.js";

function StationDashboard() {
  const { stationIdentifier } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const urlFriendlyName = createUrlFriendlyName(stationIdentifier);
    if (stationIdentifier !== urlFriendlyName) {
      navigate(`/${urlFriendlyName}`, { replace: true });
    }
  }, [stationIdentifier, navigate]);
  
  

  const stationId = findStationByNameOrId(stationIdentifier)?.id;
  
console.log("stationIdentifier:",stationIdentifier);
console.log("stationId",stationId);
 


  const { currentData, loading, error } = useCurrentWeatherData(stationId);
  
  const { lastWeekData, weekDataLoading, weekDataError } =
    useLastWeekWeatherData(stationId);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  {
    /* Uncomment the following lines to use the all weather data hook */
  }
  // const { allData, allDataLoading, allDataError, allDataTotalRecords } = useAllWeatherData(stationId, limit, offset);

  const [drawerOpen, setDrawerOpen] = useState(false); // close by default
  const [pinned, setPinned] = useState(false);

  // Use media query to determine screen size
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const drawerWidth = isSmallScreen ? "70%" : "350px"; // Adjust drawer size based on screen size

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const togglePin = () => {
    setPinned(!pinned);
  };
  const station = stationMetaData.find((s) => s.id === stationId);
  const isOperational = station?.status === "Operational";
  // if (!station) {
  //   return <Navigate to="/404" replace />;
  // }
  if (!stationId) {
    return <NotFound />;
  }


  const renderSkeletonWithLoader = (errorText) => (
    <Box sx={{ position: "relative", width: "100%", height: "400px" }}>
      <Skeleton variant="rectangular" width="100%" height="100%" />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          bgcolor: "white", // Optional background for better visibility
        }}
      >
        {errorText ? (
          <Typography color="error" variant="h6">
            {errorText}
          </Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
  return (
    <>
      <Tooltip title="Expand Menu" placement="left">
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: "fixed",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: "30px", // Adjust width for better visibility
            height: "80px", // Adjust height for better visibility
            backgroundColor: "black",
            color: "white",
            borderRadius: "5px 0 0 5px", // Rounded left side
            "&:hover": {
              backgroundColor: "black", // Optional hover effect
            },
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
      </Tooltip>

      <Backdrop
        open={drawerOpen && !pinned}
        onClick={toggleDrawer}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
      <Drawer
        anchor="right"
        open={drawerOpen || pinned}
        onClose={toggleDrawer}
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronRightIcon />
          </IconButton>
          <IconButton onClick={togglePin}>
            <PushPinIcon color={pinned ? "primary" : "inherit"} />
          </IconButton>
          <AllStationsList />
        </Box>
      </Drawer>
      <Container>
        {!isOperational ? (
          <NonOperationalStation stationName={station?.name || stationId} />
        ) : (
          <>
            <Container
              // maxWidth="md"
              sx={{
                mb: 4,
                transition: "margin-left 0.3s ease",
              }}
            >
              {loading ? (
                renderSkeletonWithLoader()
              ) : currentData ? (
                <WeatherDashboard data={currentData} stationId={stationId} />
              ) : error ? (
                renderSkeletonWithLoader(error)
              ) : (
                renderSkeletonWithLoader("No data available.")
              )}
            </Container>
            <Container
              // maxWidth="md"
              sx={{
                mb: 4,
                transition: "margin-left 0.3s ease",
              }}
            >
              {weekDataLoading ? (
                renderSkeletonWithLoader()
              ) : lastWeekData ? (
                <WeatherChart weekWeatherData={lastWeekData} />
              ) : weekDataError ? (
                renderSkeletonWithLoader(weekDataError)
              ) : (
                renderSkeletonWithLoader("No data available.")
              )}
            </Container>
          </>
        )}

        {/* <Container
					// maxWidth="md"
					sx={{
						mb: 4,
						transition: "margin-left 0.3s ease",
					}}
				>
					{allDataLoading ? (
						renderSkeletonWithLoader()
					) : allData ? (
						<DataTable
							allData={allData}
							limit={limit}
							offset={offset}
							onLimitChange={setLimit}
							onOffsetChange={setOffset}
							total_records={allDataTotalRecords}
						/>
					) : allDataError ? (
						renderSkeletonWithLoader(allDataError)
					) : (
						renderSkeletonWithLoader("No more data available.")
					)}
				</Container> */}
      </Container>

      {/* <ReportIssueButton /> */}
    </>
  );
}

export default StationDashboard;
