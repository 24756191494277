// FlagTableRows.js
import React, { useState, forwardRef, useEffect } from "react";
import {
	TableCell,
	TableRow,
	Checkbox,
	Button,
	TextField,
	Select,
	MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateFlag } from "../../services/api";
import moment from "moment";
import { awsMappingConfig } from "../../config/formConfigs/awsMappingConfig";

const AwsMappingTableRows = forwardRef(
	(
		{
			data,
			editMode,
			deleteMode,
			selectedRows,
			setselectedRows,
			refetchRows,
			setMessage,
			cancelEditMode,
		},
		ref
	) => {
		const [editingId, setEditingId] = useState(null);
		const [editedRow, seteditedRow] = useState({});
		const [isLoading, setIsLoading] = useState(false);

		const handleEdit = (data) => {
			setEditingId(data.id);
			seteditedRow({
				...data,
			});
		};

		const handleSave = async () => {
			setIsLoading(true);
			try {
				const formattedRow = {
					...editedRow,
				};
				console.log(formattedRow);

				await updateFlag(formattedRow.id, formattedRow);
				setEditingId(null);
				refetchRows();
				setMessage({
					text: "Mapping updated successfully",
					severity: "success",
					open: true,
				});
			} catch (error) {
				console.error("Error updating Mapping:", error);
				setMessage({
					text: error.message || "Error updating Mapping",
					severity: "error",
					open: true,
				});
			} finally {
				setIsLoading(false);
			}
		};

		const handleCancel = () => {
			setEditingId(null);
			seteditedRow({});
		};
		// Use useEffect to listen for changes in cancelEditMode
		useEffect(() => {
			if (cancelEditMode) {
				handleCancel();
			}
		}, [cancelEditMode]);
		const handleChange = (e, field) => {
			seteditedRow({ ...editedRow, [field]: e.target.value });
		};

		const handleSelectedRow = (id) => {
			setselectedRows((prev) =>
				prev.includes(id)
					? prev.filter((dataId) => dataId !== id)
					: [...prev, id]
			);
		};


		return (
			<TableRow ref={ref}>
				
				{editMode && (
					<TableCell>
						{editingId === data.id ? (
							<>
								<Button
									onClick={handleSave}
									startIcon={<SaveIcon />}
									size="small"
								>
									Save
								</Button>
								<Button
									onClick={handleCancel}
									startIcon={<CancelIcon />}
									size="small"
								>
									Cancel
								</Button>
							</>
						) : (
							<Button
								onClick={() => handleEdit(data)}
								startIcon={<EditIcon />}
								size="large"
							/>
						)}
					</TableCell>
				)}
				<TableCell>{data.id}</TableCell>
				<TableCell>{data.imei}</TableCell>
                <TableCell>{data.uid}</TableCell>
                <TableCell>{ data.metadata_mapping_id !== null ? data.metadata_mapping_id : "-"
                }</TableCell>

				
				
				<TableCell>{
                    data.latest_change_history_id !== null ? data.latest_change_history_id : "-"

                }</TableCell>
			</TableRow>
		);
	}
);

export default AwsMappingTableRows;
