import React from "react";
import { Box, Typography, Container } from "@mui/material";

const NotFound = () => {
	return (
		<Container maxWidth="sm">
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "100vh",
				}}
			>
				<Typography
					variant="h1"
					component="h1"
					gutterBottom
					sx={{ fontSize: "8rem", fontWeight: "bold" }}
				>
					404
				</Typography>
				<Typography variant="h5" component="h2">
					Page Not Found
				</Typography>
			</Box>
		</Container>
	);
};

export default NotFound;
