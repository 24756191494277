import React, { useState, useEffect } from "react";
import {
	TextField,
	Button,
	Box,
	IconButton,
	InputAdornment,
	Alert,
	Typography,
	Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import UserPool from "./userpool";

const Signup = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [otp, setOtp] = useState("");
	const [showOtpInput, setShowOtpInput] = useState(false);
	const [error, setError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [success, setSuccess] = useState(false);
	const [resendCounter, setResendCounter] = useState(30);

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				setError("");
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [error]);

	useEffect(() => {
		let interval;
		if (showOtpInput && resendCounter > 0) {
			interval = setInterval(() => {
				setResendCounter((prevCounter) => prevCounter - 1);
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [showOtpInput, resendCounter]);

	const validatePassword = (password) => {
		let error = "";
		if (password.length < 8) {
			error += "At least 8 characters. ";
		}
		if (!/[a-z]/.test(password)) {
			error += "One lowercase letter. ";
		}
		if (!/[A-Z]/.test(password)) {
			error += "One uppercase letter. ";
		}
		if (!/\d/.test(password)) {
			error += "One number. ";
		}
		if (!/[\W_]/.test(password)) {
			error += "One special character. ";
		}
		return error;
	};

	const handlePasswordBlur = () => {
		setPasswordError(validatePassword(password));
	};

	const onSubmit = (event) => {
		event.preventDefault();

		if (passwordError) {
			setError("Password does not meet the requirements");
			return;
		}

		if (password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}

		UserPool.signUp(email, password, [], null, (err, data) => {
			if (err) {
				setError(err.message);
				return;
			}
			setShowOtpInput(true);
			setResendCounter(30);
			console.log("Sign-up successful. OTP sent to email.");
		});
	};

	const handleVerifyOtp = (event) => {
		event.preventDefault();

		if (!otp.trim()) {
			setError("Please enter the OTP");
			return;
		}

		const user = new CognitoUser({
			Username: email,
			Pool: UserPool,
		});

		user.confirmRegistration(otp.trim(), true, (err, result) => {
			if (err) {
				setError(err.message);
				return;
			}
			setSuccess(true);
			console.log("OTP verification successful:", result);
			// Redirect to Success page after a short delay
			navigate("/success");

			// Clear form fields and reset state
			setEmail("");
			setPassword("");
			setConfirmPassword("");
			setOtp("");
			setShowOtpInput(false);
			setError("");
			setPasswordError("");
			setSuccess(false);
			setResendCounter(30);
		});
	};

	const handleResendOtp = () => {
		const user = new CognitoUser({
			Username: email,
			Pool: UserPool,
		});

		user.resendConfirmationCode((err, result) => {
			if (err) {
				setError(err.message);
				return;
			}
			setResendCounter(30);
			console.log("OTP resent successfully:", result);
		});
	};

	return (
		<Box>
			<Box component="form" onSubmit={onSubmit}>
				<TextField
					fullWidth
					margin="normal"
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					fullWidth
					margin="normal"
					type={showPassword ? "text" : "password"}
					label="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onBlur={handlePasswordBlur}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)}>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				{passwordError && (
					<Alert severity="warning" sx={{ mt: 1 }}>
						{passwordError}
					</Alert>
				)}
				<TextField
					fullWidth
					margin="normal"
					type={showConfirmPassword ? "text" : "password"}
					label="Confirm Password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								>
									{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				{!showOtpInput && (
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{ mt: 2 }}
					>
						Signup
					</Button>
				)}
				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error}
					</Alert>
				)}
			</Box>
			{showOtpInput && (
				<Box component="form" onSubmit={handleVerifyOtp}>
					<TextField
						fullWidth
						margin="normal"
						label="Enter OTP"
						value={otp}
						onChange={(e) => setOtp(e.target.value)}
						required
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{ mt: 2 }}
					>
						Verify OTP
					</Button>
					<Box sx={{ mt: 2, textAlign: "center" }}>
						{!resendCounter ? (
							<Link
								component="button"
								variant="body2"
								onClick={handleResendOtp}
							>
								Resend OTP
							</Link>
						) : (
							<Typography variant="body2">
								Resend OTP in {resendCounter} seconds
							</Typography>
						)}
					</Box>
				</Box>
			)}
			{success && (
				<Alert severity="success" sx={{ mt: 2 }}>
					Verification successful! You can now log in.
				</Alert>
			)}
		</Box>
	);
};

export default Signup;
