import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { 
  Typography, 
  Button, 
  CircularProgress, 
  Box, 
  Grid,
  Divider,
  Tooltip
} from "@mui/material";
import { useQuery } from "react-query";
import { getFormData } from "../../../services/api";
import EquipmentDisplay from "./EquipmentDisplay";
import AddEquipment from "./AddEquipment";
import AddEquipmentModel from "./AddEquipmentModel";
import AddEquipmentAttribute from "./AddEquipmentAttribute";
import EquipmentStepper from "./EquipmentStepper";
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DevicesIcon from '@mui/icons-material/Devices';

const EquipmentInfo = () => {
  const { uid } = useParams();
  const [showAddEquipment, setShowAddEquipment] = useState(false);
  const [showAddModel, setShowAddModel] = useState(false);
  const [showAddAttribute, setShowAddAttribute] = useState(false);
  const [showStepper, setShowStepper] = useState(false);

  // Fetch equipment data with related models and attributes
  const { data: equipmentData, isLoading: equipmentLoading } = useQuery(
    ["equipmentInfo", uid],
    () => getFormData(`metadata/logger-equipment/${uid}`),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  // Fetch models data
  const { data: modelsData, isLoading: modelsLoading } = useQuery(
    ["equipmentModels"],
    () => getFormData('metadata/equipment-model'),
    {
      staleTime: 5 * 60 * 1000,
    }
  );
  // Fetch attributes data
  const { data: attributesData, isLoading: attributesLoading } = useQuery(
    ["equipmentAttributes"],
    () => getFormData('metadata/equipment-attribute'),
    {
      staleTime: 5 * 60 * 1000,
    }
  );

  const isLoading = equipmentLoading || modelsLoading || attributesLoading;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (showStepper) {
    return <EquipmentStepper onClose={() => setShowStepper(false)} />;
  }

  const handleClose = () => {
    setShowAddEquipment(false);
    setShowAddModel(false);
    setShowAddAttribute(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      {!showAddEquipment && !showAddModel && !showAddAttribute ? (
        <>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                gap: 2,
                flexWrap: 'wrap',
                '& .MuiButton-root': {
                  minWidth: '200px',
                  justifyContent: 'flex-start',
                  px: 3
                }
              }}>

                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {/* <Tooltip title="Add equipment with model and attributes in steps"     
                > */}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PlaylistAddIcon />}
                    onClick={() => setShowStepper(true)}
                    sx={{ mr: 2 }}
                  >
                    Add Equipment From Scratch
                  </Button>
                {/* </Tooltip> */}

                <Divider orientation="vertical" flexItem />

                  {/* <Tooltip title="Add new equipment to existing model"> */}
                    <Button 
                      variant="contained"
                      startIcon={<DevicesIcon />}
                      onClick={() => setShowAddEquipment(true)}
                    >
                      Add Equipment
                    </Button>
                  {/* </Tooltip> */}

                  {/* <Tooltip title="Create new equipment model"> */}
                    <Button 
                      variant="contained"
                      startIcon={<SettingsIcon />}
                      onClick={() => setShowAddModel(true)}
                    >
                      Add Equipment Model
                    </Button>
                  {/* </Tooltip> */}

                  {/* <Tooltip title="Add attributes to existing model"> */}
                    <Button 
                      variant="contained"
                      startIcon={<ListAltIcon />}
                      onClick={() => setShowAddAttribute(true)}
                    >
                      Add Model Attribute
                    </Button>
                  {/* </Tooltip> */}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {equipmentData && equipmentData.length > 0 ? (
            <EquipmentDisplay 
              equipmentData={equipmentData}
              modelsData={modelsData}
              attributesData={attributesData}
           
            
            />
          ) : (
            <Box 
              sx={{ 
                textAlign: 'center', 
                py: 8,
                bgcolor: 'background.paper',
                borderRadius: 1
              }}
            >
              <Typography variant="h6" color="textSecondary" gutterBottom>
                No Equipment Information Available
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Start by adding equipment from scratch or add individual components
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          {showAddEquipment && (
            <AddEquipment 
              onClose={handleClose}
              availableModels={modelsData}
            />
          )}
          {showAddModel && (
            <AddEquipmentModel 
              onClose={handleClose}
            />
          )}
          {showAddAttribute && (
            <AddEquipmentAttribute 
              onClose={handleClose}
              availableModels={modelsData}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default EquipmentInfo;