import React, { createContext, useState, useContext, useEffect } from 'react';
import translations from '../translations/dictionary.json';
import { languageService } from './languageService';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => 
    languageService.getPreferredLanguage()
  );

  // Update localStorage when language changes
  const handleLanguageChange = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    languageService.setPreferredLanguage(newLanguage);
  };

  const translate = (key) => {
    if (!translations[key]) return key;
    return translations[key][currentLanguage] || translations[key]['syllabics'];
  };
  // const getFontSize = (type) => {
  //   const sizes = {
  //     syllabics: {
  //       title: "1.1rem",
  //       body: "0.9rem",
  //       subtitle: "0.8rem",
  //       heading: "1.3rem",
  //       button: "0.85rem"
  //     },
  //     roman: {
  //       title: "1rem",
  //       body: "0.85rem",
  //       subtitle: "0.75rem",
  //       heading: "1.2rem",
  //       button: "0.8rem"
  //     },
  //     english: {
  //       title: "1.2rem",
  //       body: "1rem",
  //       subtitle: "0.9rem",
  //       heading: "1.4rem",
  //       button: "0.9rem"
  //     }
  //   };
  //   return sizes[currentLanguage]?.[type] || sizes.english[type];
  // };

  return (
    <TranslationContext.Provider value={{ 
      currentLanguage,
      setCurrentLanguage: handleLanguageChange,
      translate ,
      // getFontSize
    }}>
      {children}
    </TranslationContext.Provider>
  );
};


export const useTranslation = () => useContext(TranslationContext);
