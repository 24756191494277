import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Autocomplete,
	Paper,
	Typography,
	Box,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";

import { postFormData, getFormData } from "../../../services/api"; // Import your API function
import MessageDisplay from "../../Form/MessageDisplay"; // Import the MessageDisplay component

const AddStationDialog = ({ onAdd }) => {
	const { uid } = useParams();
	const [stationInfo, setStationInfo] = useState({
		variable_name: "",
		station_name: "",
		alternative_name: "",
		community_name: "",
		url: "",
		latitude: null,
		longitude: null,
		elevation: null,
		site_description: "",
	});
	const [images, setImages] = useState([]);
	const [errors, setErrors] = useState({});
	const [messageOpen, setMessageOpen] = useState(false);
	const [messageContent, setMessageContent] = useState("");
	const [severity, setSeverity] = useState("success");

	const handleChange = (e) => {
		setStationInfo({ ...stationInfo, [e.target.name]: e.target.value });
	};
	const onDrop = useCallback((acceptedFiles) => {
		setImages((prevImages) => [
			...prevImages,
			...acceptedFiles.map((file) => ({
				file,
				caption: "",
			})),
		]);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: true,
	});
	const handleImageChange = (e) => {
		const files = Array.from(e.target.files);
		setImages((prevImages) => [
			...prevImages,
			...files.map((file) => ({
				file,
				caption: "", // Default caption is empty for custom input
			})),
		]);
	};

	const handleCaptionChange = (index, newCaption) => {
		setImages(
			images.map((img, i) =>
				i === index ? { ...img, caption: newCaption } : img
			)
		);
	};

	const validate = () => {
		const newErrors = {};

		if (!stationInfo.station_name.trim()) {
			newErrors.station_name = "Station Name is required";
		}

		if (stationInfo.latitude && isNaN(stationInfo.latitude)) {
			newErrors.latitude = "Latitude must be a number";
		}

		if (stationInfo.longitude && isNaN(stationInfo.longitude)) {
			newErrors.longitude = "Longitude must be a number";
		}

		if (stationInfo.elevation && isNaN(stationInfo.elevation)) {
			newErrors.elevation = "Elevation must be a number";
		}

		setErrors(newErrors);

		return Object.keys(newErrors).length === 0;
	};
	const handleDeleteImage = (index) => {
		setImages(images.filter((_, i) => i !== index));
	};

	const getPresignedUrl = async (fileName, caption) => {
		try {
			const response = await getFormData(`/s3-presigned-url/${uid}`, {
				fileName: fileName,
				caption: caption,
			});
			// if (!response.ok) {
			//     throw new Error('Failed to get presigned URL');
			// }
			console.log(JSON.parse(response));
			return JSON.parse(response); // Parse the JSON string
		} catch (error) {
			console.error("Error getting presigned URL:", error);
			throw error;
		}
	};

	const uploadToS3 = async (file, presignedUrl) => {
		try {
			const response = await fetch(presignedUrl, {
				method: "PUT",
				body: file,
			});
			if (!response.ok) {
				const errorText = await response.text();
				console.error("S3 response:", response.status, errorText);
				throw new Error(
					`Failed to upload to S3: ${response.status} ${errorText}`
				);
			}
			console.log("Upload successful");
		} catch (error) {
			console.error("Error uploading to S3:", error);
			throw error;
		}
	};
	const handleSubmit = async () => {
		if (!validate()) return;

		try {
			const imageUrls = await Promise.all(
				images.map(async (img) => {
					const { presigned_url, s3_key, s3_url, unique_id } =
						await getPresignedUrl(img.file.name, img.caption);
					console.log(presigned_url, s3_key, s3_url, unique_id);
					await uploadToS3(img.file, presigned_url);
					return {
						url: s3_url,
						caption: img.caption,
						filename: img.file.name,
						unique_id: unique_id,
					};
				})
			);

			const dataToSubmit = {
				...stationInfo,
				photo_collection: JSON.stringify(imageUrls),
			};
			console.log(dataToSubmit);
			await postFormData(`/metadata/station-info/${uid}`, dataToSubmit);
			setMessageContent("Station information added successfully!");
			setSeverity("success");
			setMessageOpen(true);
			onAdd();
		} catch (error) {
			console.error("Error adding station:", error.message);
			setMessageContent(`Failed to add station information: ${error.message}`);
			setSeverity("error");
			setMessageOpen(true);
		}
	};

	return (
		<>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
			>
				<Paper
					elevation={3}
					sx={{
						width: "60%",
						padding: "20px",
						marginTop: "20px",
					}}
				>
					<Typography variant="h6">Add New Station Info</Typography>

					{/* Existing TextFields */}
					<TextField
						label="Variable Name"
						name="variable_name"
						value={stationInfo.variable_name}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Station Name"
						name="station_name"
						value={stationInfo.station_name}
						onChange={handleChange}
						fullWidth
						margin="normal"
						required
						error={!!errors.station_name}
						helperText={errors.station_name}
					/>
					<TextField
						label="Alternative Name"
						name="alternative_name"
						value={stationInfo.alternative_name}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Community Name"
						name="community_name"
						value={stationInfo.community_name}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="URL"
						name="url"
						value={stationInfo.url}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Latitude"
						name="latitude"
						value={stationInfo.latitude}
						onChange={handleChange}
						fullWidth
						margin="normal"
						error={!!errors.latitude}
						helperText={errors.latitude}
					/>
					<TextField
						label="Longitude"
						name="longitude"
						value={stationInfo.longitude}
						onChange={handleChange}
						fullWidth
						margin="normal"
						error={!!errors.longitude}
						helperText={errors.longitude}
					/>
					<TextField
						label="Elevation"
						name="elevation"
						value={stationInfo.elevation}
						onChange={handleChange}
						fullWidth
						margin="normal"
						error={!!errors.elevation}
						helperText={errors.elevation}
					/>
					<TextField
						label="Site Description"
						name="site_description"
						value={stationInfo.site_description}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>

					{/* Display Uploaded Images with Autocomplete for Captions */}
					{images.map((img, index) => (
						<Box key={index} display="flex" alignItems="center" mt={2}>
							<img
								src={URL.createObjectURL(img.file)}
								alt={img.file.name}
								style={{ width: "50px", height: "50px", marginRight: "10px" }}
							/>
							<span>{img.file.name}</span>
							<Autocomplete
								freeSolo
								options={[
									"towards_north",
									"towards_north_east",
									"towards_east",
									"towards_south_east",
									"towards_south",
									"towards_south_west",
									"towards_west",
									"towards_north_west",
									"away_north",
									"away_north_east",
									"away_east",
									"away_south_east",
									"away_south",
									"away_south_west",
									"away_west",
									"away_north_west",
									"station_photo",
									"enclosure_photo",
									"team_photo",
								]}
								value={img.caption}
								onInputChange={(event, newInputValue) =>
									handleCaptionChange(index, newInputValue)
								}
								onChange={(event, newValue) =>
									handleCaptionChange(index, newValue)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Caption"
										variant="outlined"
										sx={{ width: "200px", marginLeft: "10px" }} // Adjust width and margin here
									/>
								)}
							/>
							<IconButton
								onClick={() => handleDeleteImage(index)}
								sx={{ color: "black", marginLeft: "10px" }} // Add margin to separate from Autocomplete
							>
								<DeleteIcon />
							</IconButton>
						</Box>
					))}
					{/* Image Upload Section */}
					{/* <Button variant="contained" component="label">
						{images.length > 0 ? "Upload More Images" : "Upload Images"}
						<input
							type="file"
							hidden
							multiple
							accept="image/*"
							onChange={handleImageChange}
						/>
					</Button> */}
					{/* Image Upload Section */}
					<Box
						{...getRootProps()}
						border={`2px dashed ${isDragActive ? "black" : "gray"}`}
						sx={{
							borderRadius: 2,
							p: 2,
							mt: 2,
							textAlign: "center",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "action.hover",
							},
						}}
					>
						<input {...getInputProps()} />
						{isDragActive ? (
							<Typography>Drop the images here ...</Typography>
						) : (
							<Typography>
								Drag and drop images here, or click to select images
							</Typography>
						)}
					</Box>

					{/* </DialogContent> */}

					<Box mt={2}>
						<Button onClick={handleSubmit} color="primary" variant="contained">
							Add
						</Button>
					</Box>

					{/* Success/Error Message Display */}
					<MessageDisplay
						message={messageContent}
						severity={severity}
						open={messageOpen}
						onAdd={() => setMessageOpen(false)}
					/>
				</Paper>
			</Box>
		</>
	);
};

export default AddStationDialog;
