import { colorRanges } from "../components/WeatherDashboard/colorRanges";

const getArrowColor = (speed) => {
    for (let i = colorRanges.length - 1; i >= 0; i--) {
      if (speed >= colorRanges[i][0]) {
        const [r, g, b] = colorRanges[i][1];
        return `rgb(${r}, ${g}, ${b})`;
      }
    }
  
    // Default to black if no match
    return "rgb(0 ,0 ,0)";
  };
  
  export default getArrowColor;