import React from "react";
import { Alert, Snackbar } from "@mui/material";

const MessageDisplay = ({ message, severity, open, onClose }) => {
	return (
		<Snackbar   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
 		open={open} autoHideDuration={5000} onClose={onClose}>
			<Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default MessageDisplay;
