import axios from "axios";
import Cookies from "js-cookie";
import { refreshSession } from "./authenticate";
import {
	redirectToLogin,
	redirectToUserHasNoAccess,
} from "../utils/rawFunction";

const API_BASE_URL = "https://api.silanga.ca/v1";

const getAccessToken = () => {
	return Cookies.get("accessToken");
};

const api = axios.create({
	baseURL: API_BASE_URL,
});

// Request interceptor to add the auth token header
api.interceptors.request.use(
	async (config) => {
		const token = getAccessToken();
		if (!token) {
			try {
				const session = await refreshSession();
				token = session.getAccessToken().getJwtToken();
				Cookies.set("accessToken", token, {
					secure: true,
					sameSite: "Strict",
				});
			} catch (error) {
				// Handle refresh failure (e.g., redirect to login)
				redirectToLogin();
				return Promise.reject(error);
			}
		}

		if (token) {
			config.headers["token"] = `${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (
			error.response.status === 403 &&
			error.response.data.message ===
				"User does not have required permissions(Error code: 403)"
		) {
			redirectToUserHasNoAccess(error.response.data.message);
			return;
		}

		if (error.response.status === 403 && !originalRequest._retry) {
			// actually status code should be 401 but we use 403 for now as we have the custom authorization lambda which gives 403 instead of 401
			originalRequest._retry = true;
			try {
				console.log("--", error.response.data.message);
				const session = await refreshSession();
				const token = session.getAccessToken().getJwtToken();
				axios.defaults.headers.common["token"] = `${token}`;
				Cookies.set("accessToken", token, {
					secure: true,
					sameSite: "Strict",
				});
				return api(originalRequest);
			} catch (refreshError) {
				// Handle refresh failure (e.g., redirect to login)
				redirectToLogin();
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(error);
	}
);
export const deleteFormData = async (endpoint, params) => {
	try {
		const response = await api.delete(endpoint, { params });
		return response.data;
	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			throw new Error(error.response.data.message || "An error occurred");
		} else if (error.request) {
			// The request was made but no response was received
			throw new Error("No response received from server");
		} else {
			// Something happened in setting up the request that triggered an Error
			throw new Error("Error setting up the request");
		}
	}
};


export const postFormData = async (endpoint, data) => {
	try {
		const response = await api.post(endpoint, data, {
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data;
	} catch (error) {
		console.log(error);

		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			throw new Error(error.response.data.message || error.response.data.error|| "An error occurred");
		} else if (error.request) {
			// The request was made but no response was received
			throw new Error("No response received from server");
		} else {
			// Something happened in setting up the request that triggered an Error
			throw new Error("Error setting up the request");
		}
	}
};

export const getFormData = async (endpoint, params) => {
	try {
		const response = await api.get(endpoint, { params });
		return response.data;
	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			throw new Error(error.response.data.message || "An error occurred");
		} else if (error.request) {
			// The request was made but no response was received
			throw new Error("No response received from server");
		} else {
			// Something happened in setting up the request that triggered an Error
			throw new Error("Error setting up the request");
		}
	}
};

export const updateMetadataMapping = async (id, data) => {
	try {
		const response = await api.patch(`/aws-metadata-mapping`, data, {
			params: { id: id },
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response?.data?.error || "Error updating Metadata mapping");
	}
};


export const updateFlag = async (id, data) => {
	try {
		const response = await api.patch(`/flag`, data, {
			params: { id: id },
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response?.data?.message || "Error updating flag");
	}
};

export const deleteFlags = async (ids) => {
	const results = [];
	const failedIds = [];

	for (const id of ids) {
		try {
			const response = await api.delete("/flag", {
				params: { id: id },
			});
			results.push(response.data);
		} catch (error) {
			console.error(`Error deleting flag with id ${id}:`, error);
			failedIds.push(id);
		}
	}

	if (failedIds.length > 0) {
		throw new Error(`Failed to delete flags with ids: ${failedIds.join(", ")}`);
	}

	return { message: `Successfully deleted ${results.length} flags` };
};


export const updateOffset = async (id, data) => {
	try {
		const response = await api.patch(`/offset`, data, {
			params: { id: id },
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response?.data?.message || "Error updating offset");
	}
};

export const deleteOffset = async (ids) => {
	const results = [];
	const failedIds = [];

	for (const id of ids) {
		try {
			const response = await api.delete("/offset", {
				params: { id: id },
			});
			results.push(response.data);
		} catch (error) {
			console.error(`Error deleting offset with id ${id}:`, error);
			failedIds.push(id);
		}
	}

	if (failedIds.length > 0) {
		throw new Error(`Failed to delete offset with ids: ${failedIds.join(", ")}`);
	}

	return { message: `Successfully deleted ${results.length} offset` };
};
