import React, { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DateTime } from "luxon";
import "chart.js/auto";
import {
  CircularProgress,
  Box,
  IconButton,
  Modal,
  Paper,
  Grid,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";
import "chartjs-adapter-date-fns"; // Add this import
import { calculateWindChill } from "../WeatherDashboard/calculateWindChill";
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const WeatherChart = ({ weekWeatherData = [] }) => {
  const [selectedChart, setSelectedChart] = useState(null);
  const chartRefs = useRef([]);

  const variableColors = {
    temperature: {
      primary: "rgb(255, 99, 132)", // Keep only primary color for temperature
    },
    humidity: {
      primary: "rgb(54, 162, 235)",
    },
    wind: {
      primary: "rgb(75, 192, 192)",
    },
    windGust: {
      primary: "rgb(153, 102, 255)",
    },
  };

  const createChartData = (
    timestamps,
    primaryData,
    secondaryData = null,
    labels,
    colorKey,
    isZoomed = false
  ) => ({
    labels: timestamps,
    datasets: [
      {
        label: labels[0],
        data: primaryData,
        borderColor: variableColors[colorKey].primary,
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: isZoomed ? 4 : 0, // Show points only in zoomed view
        pointHoverRadius: isZoomed ? 6 : 0, // Show hover points only in zoomed view
        yAxisID: "y",
      },
      secondaryData && {
        label: labels[1],
        data: secondaryData,
        borderColor: "rgb(54, 100, 235)",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: isZoomed ? 4 : 0, // Show points only in zoomed view
        pointHoverRadius: isZoomed ? 6 : 0, // Show hover points only in zoomed view
        yAxisID: "y",
        borderDash: [1, 1], // Optional: makes the secondary line dashed
      },
    ].filter(Boolean), // Remove null/undefined datasets
  });

  const createArrowPlugin = (directionData) => {
    // Helper function to convert degrees to cardinal direction
    const degreesToCardinal = (degrees) => {
      const directions = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW",
      ];
      const index = Math.round(((degrees + 360) % 360) / 22.5) % 16;
      return directions[index];
    };

    return {
      id: "windDirectionArrows",
      afterDatasetsDraw: (chart) => {
        const { ctx } = chart;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;
        const chartArea = chart.chartArea;

        // Get visible range
        const visibleRange = {
          min: xAxis.min,
          max: xAxis.max,
        };

        // Calculate visible data points
        const visiblePoints = timestamps.filter((t) => {
          const timestamp = t.getTime();
          return timestamp >= visibleRange.min && timestamp <= visibleRange.max;
        });

        // Show only 5 arrows evenly spaced
        const maxArrows = 5;
        const step = Math.max(1, Math.floor(visiblePoints.length / maxArrows));

        // Calculate arrow area dimensions
        const topMargin = 15; // Adjust this value to increase/decrease top space

        const arrowAreaTop = chartArea.top + topMargin; // Position arrows below the title

        visiblePoints.forEach((timestamp, index) => {
          if (index % step !== 0) return;

          const dataIndex = timestamps.findIndex(
            (t) => t.getTime() === timestamp.getTime()
          );
          const direction = directionData[dataIndex];

          if (direction !== null && direction !== undefined) {
            const x = xAxis.getPixelForValue(timestamp);

            // Skip if outside chart area
            if (x < chartArea.left || x > chartArea.right) return;
            const adjustedDirection = (direction + 180) % 360;

            // Draw arrow
            ctx.save();
            ctx.translate(x, arrowAreaTop);
            ctx.rotate((adjustedDirection  * Math.PI) / 180);

            // Draw arrow with trailing shaft
            ctx.beginPath();

            // Draw the trailing shaft line
            ctx.moveTo(0, 8); // Start of shaft
            ctx.lineTo(0, -8); // End of shaft/arrow head

            // Draw the arrow head
            ctx.moveTo(-4, -3); // Left point of arrow head
            ctx.lineTo(0, -8); // Top point
            ctx.lineTo(4, -3); // Right point of arrow head

            ctx.strokeStyle = "rgba(0, 0, 0, 0.6)";
            ctx.lineWidth = 3;
            ctx.stroke();
            ctx.restore();

            // Draw cardinal direction 
            ctx.save();
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
            ctx.font = "13px Arial";
            ctx.fillText(degreesToCardinal(direction ), x, arrowAreaTop + 15);
            ctx.restore();
          }
        });
      },
    };
  };

  const createChartOptions = (title, primaryUnit, hasDirections = false) => {
    const minTime = Math.min(...timestamps);
    const maxTime = Math.max(...timestamps);
    const oneHour = 60 * 60 * 1000;

    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: function (context) {
              const timestamp = context[0].parsed.x;
              return DateTime.fromMillis(timestamp).toFormat(
                "MMM dd, yyyy HH:mm ZZZZ"
              );
            },
            label: function (context) {
              const value = context.parsed.y;
              return `${context.dataset.label}: ${
                typeof value === "number" ? value.toFixed(1) : value
              } ${primaryUnit}`;
            },
          },
        },
        legend: {
          position: "top",
          labels: {
            boxWidth: 20,
            font: { size: window.innerWidth < 768 ? 10 : 12 },
          },
        },
        title: {
          display: true,
          text: title,
          font: {
            size: window.innerWidth < 768 ? 14 : 16,
            weight: "bold",
          },
        },
        zoom: {
          limits: {
            x: {
              min: minTime,
              max: maxTime,
              minRange: oneHour,
            },
          },
          zoom: {
            wheel: {
              enabled: true,
              speed: 0.1,
            },
            pinch: { enabled: true },
            mode: "x",
          },
          pan: {
            enabled: true,
            mode: "x",
          },
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "HH:mm",
            },
            tooltipFormat: "MMM dd, yyyy HH:mm",
          },
          min: minTime,
          max: maxTime,
          ticks: {
            source: "auto",
            autoSkip: true,
            maxTicksLimit: window.innerWidth < 768 ? 4 : 7,
            maxRotation: 0,
            callback: function (value, index, ticks) {
              // Replace moment with Luxon
              const date = DateTime.fromMillis(value);
              const isScrolling =
                this.chart.scales.x.min !== minTime ||
                this.chart.scales.x.max !== maxTime;

              if (isScrolling) {
                // Show date with time and timezone
                return [date.toFormat("LLL dd"), `(${date.toFormat("HH:mm")})`];
              } else {
                return [date.toFormat("LLL dd")];
              }
            },
          },
          grid: {
            display: true,
          },
          border: {
            display: true,
          },
        },
        y: {
          position: "left",
          title: {
            display: true,
            text: primaryUnit,
            font: { size: window.innerWidth < 768 ? 10 : 12 },
          },
          ticks: {
            font: { size: window.innerWidth < 768 ? 8 : 10 },
            callback: function (value) {
              // Check if the value is a number and has decimals
              if (typeof value === "number" && !Number.isInteger(value)) {
                return value.toFixed(1); // Limit to 1 decimal places
              }
              return value;
            },
          },
          // Add extra space at the top for arrows
          suggestedMax: function (context) {
            const maxValue = Math.max(...context.chart.data.datasets[0].data);
            return hasDirections ? maxValue * 1.2 : maxValue * 1.1; // 20% extra space for charts with arrows
          },
        },
      },
    };
  };
  if (!Array.isArray(weekWeatherData) || weekWeatherData.length === 0) {
    return <CircularProgress />;
  }

  //   const timestamps = weekWeatherData.map(entry => moment(entry.timestamp).format('HH:mm'));

  // Modify timestamps creation to use Date objects instead of formatted strings
  const timestamps = weekWeatherData.map((entry) =>
    DateTime.fromISO(entry.timestamp).toJSDate()
  );

  const charts = [
    {
      data: createChartData(
        timestamps,
        // weekWeatherData.map(
        //   (entry) => entry.elements.temperature_ext?.value || 0
        // ),

        weekWeatherData.map((entry) => {
          const temperature_ext = entry.elements.temperature_ext;
          if (temperature_ext === undefined || temperature_ext === null)
            return null;

          const qcValue = temperature_ext.qc_value;

          return qcValue === undefined || qcValue === 100
            ? temperature_ext.value === 0 //the actual temperature_ext value could be 0 too
              ? 0
              : temperature_ext.value || null
            : null;
        }),

        weekWeatherData.map((entry) => {
          const temperature_ext = entry.elements.temperature_ext;
          const wind_speed = entry.elements.wind_speed;

          // Check if temperature_ext exists
          if (temperature_ext === undefined || temperature_ext === null)
            return null;

          const tempQcValue = temperature_ext.qc_value;
          if (!(tempQcValue === undefined || tempQcValue === 100)) return null;

          // Calculate wind chill if temperature check passed
          const windChill = calculateWindChill(temperature_ext, wind_speed);
          return windChill === 0 ? 0 : windChill || null;
        }),

        ["Temperature", "Wind Chill"],
        "temperature"
      ),
      options: createChartOptions("Temperature & Wind Chill", "°C", true),
    },
    {
      data: createChartData(
        timestamps,
        // weekWeatherData.map((entry) => entry.elements.humidity_ext?.value || 0),
        weekWeatherData.map((entry) => {
          const humidity_ext = entry.elements.humidity_ext;
          if (humidity_ext === undefined || humidity_ext === null) return null;

          const qcValue = humidity_ext.qc_value;
          // Explicitly check for undefined or 100 qcValue

          return qcValue === undefined || qcValue === 100
            ? humidity_ext.value === 0 //the actual humidity_ext value could be 0 too
              ? 0
              : humidity_ext.value || null
            : null;
        }),

        null,
        ["Humidity"],
        "humidity"
      ),
      options: createChartOptions("Humidity", "%", null, false), // No directions
    },
    {
      data: createChartData(
        timestamps,
        // weekWeatherData.map((entry) => entry.elements.wind_speed?.value || 0),
        weekWeatherData.map((entry) => {
          const wind_speed = entry.elements.wind_speed;
          if (wind_speed === undefined || wind_speed === null) return null;
          const qcValue = wind_speed?.qc_value;
          // Return the wind_speed value only if qcValue is undefined or 100

          return qcValue === undefined || qcValue === 100
            ? wind_speed.value === 0 //the actual wind_speed value could be 0 too
              ? 0
              : wind_speed.value || null
            : null;
        }),
        null,
        ["Wind Speed"],
        "wind"
      ),
      options: createChartOptions("Wind Speed", "km/h", true), // Add hasDirections flag
      plugins: [
        createArrowPlugin(
          // weekWeatherData.map(
          //   (entry) => entry.elements.wind_direction?.value || 0
          // )
          weekWeatherData.map((entry) => {
            const wind_direction = entry.elements.wind_direction;
            if (wind_direction === undefined || wind_direction === null)
              return null;
            const qcValue = wind_direction?.qc_value;
            // Return the wind_direction value only if qcValue is undefined or 100
            return qcValue === undefined || qcValue === 100
              ? wind_direction.value === 0 //the actual wind_direction value could be 0 too
                ? 0
                : wind_direction.value || null
              : null;
          })
        ),
      ],
    },
    {
      data: createChartData(
        timestamps,
        // weekWeatherData.map(
        //   (entry) => entry.elements.wind_gust_speed?.value || 0
        // ),
        weekWeatherData.map((entry) => {
          const wind_gust_speed = entry.elements.wind_gust_speed;
          if (!wind_gust_speed) return null;
          const qcValue = wind_gust_speed?.qc_value;
          // Return the wind_gust_speed value only if qcValue is undefined or 100
          return qcValue === undefined || qcValue === 100
            ? wind_gust_speed.value === 0 //the actual wind_gust_speed value could be 0 too
              ? 0
              : wind_gust_speed.value || null
            : null;
        }),

        null,
        ["Wind Gust Speed"],
        "windGust"
      ),
      options: createChartOptions("Wind Gust Speed", "km/h", true), // Add hasDirections flag
      plugins: [
        createArrowPlugin(
          // weekWeatherData.map(
          //   (entry) => entry.elements.wind_gust_direction?.value || 0
          // )
          weekWeatherData.map((entry) => {
            const wind_gust_direction = entry.elements.wind_gust_direction;
            if (!wind_gust_direction) return null;
            const qcValue = wind_gust_direction?.qc_value;

            return qcValue === undefined || qcValue === 100
              ? wind_gust_direction.value === 0 //the actual wind_gust_direction value could be 0 too
                ? 0
                : wind_gust_direction.value || null
              : null;
          })
        ),
      ],
    },
  ];
  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {charts.map((chart, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 0,
                  height: "300px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    display: "flex",
                    gap: 1,
                    zIndex: 1,
                  }}
                >
                  {/* Remove close/reset button */}
                  <IconButton
                    onClick={() => setSelectedChart(index)}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}
                  >
                    <FullscreenIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Line
                  ref={(el) => (chartRefs.current[index] = el)}
                  data={chart.data}
                  options={chart.options}
                  plugins={chart.plugins || []}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Fullscreen Modal */}
      <Modal
        open={selectedChart !== null}
        onClose={() => setSelectedChart(null)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "95%", sm: "90%", md: "80%" },
            height: { xs: "80%", sm: "85%", md: "90%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 1,
          }}
        >
          <IconButton
            onClick={() => setSelectedChart(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedChart !== null && (
            <Box sx={{ height: "100%", pt: 2 }}>
              <Line
                data={createChartData(
                  timestamps,
                  charts[selectedChart].data.datasets[0].data,
                  charts[selectedChart].data.datasets[1]?.data, // Include secondary dataset if it exists
                  [
                    charts[selectedChart].data.datasets[0].label,
                    charts[selectedChart].data.datasets[1]?.label,
                  ].filter(Boolean),
                  Object.keys(variableColors)[selectedChart],
                  true
                )}
                options={charts[selectedChart].options}
                plugins={charts[selectedChart].plugins || []}
              />
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default WeatherChart;
