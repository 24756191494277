import React, { useState, useEffect } from "react";
import useForm from "../hooks/useForm";
import { postFormData, getFormData } from "../services/api";
import {
  offsetFormConfig,
  offsetTableConfig,
} from "../config/formConfigs/offsetConfig";
import moment from "moment";
import FormBuilder from "../components/Form/FormBuilder";
import { Container, Grid } from "@mui/material";
import OffsetsTable from "../components/Tables/OffsetsTable";
import { useOffsets } from "../../src/services/useOffsets";


const OffsetForm = () => {
  const [awsMappings, setAwsMappings] = useState([]);
  const [isAwsMappingsLoading, setIsAwsMappingsLoading] = useState(true);
  const [disabledFields, setDisabledFields] = useState({ offset_value: false });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { refetchRows } = useOffsets();

  useEffect(() => {
    const fetchAwsMappings = async () => {
      try {
        const response = await getFormData("aws-mappings");
        const mappings = response.map((mapping) => ({
          value: mapping.id,
          label: mapping.uid,
        }));
        setAwsMappings(mappings);
      } catch (error) {
        console.error("Error fetching AWS mappings:", error);
      } finally {
        setIsAwsMappingsLoading(false);
      }
    };

    fetchAwsMappings();
  }, []);

  const initialState = {
    field_name: "",
    offset_value: 0,
    operator: "",
    custom_expression: "", // For custom expressions
    aws_mapping_id: "",
    from_date: "",
    to_date: "",
    comment: "",
  };

  const onSubmit = async (formData) => {
    try {
      setIsSubmitting(true); // Start loading

      const formattedData = {
        ...formData,
        custom_expression:formData.custom_expression ? formData.custom_expression:null,
        from_date: formData.from_date
          ? moment(formData.from_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
          : null,
        to_date: formData.to_date
          ? moment(formData.to_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
          : null,
      };

      console.log(formattedData);
      const response = await postFormData("offset", formattedData);
      await refetchRows(); // Refetch after successful submission
      return response;
    } catch (error) {
      throw error;
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  const handleOperatorChange = (e) => {
    const operator = e.target.value;

    // Disable offset_value for specific operators
    const disableOffsetValue = [
      "abs",
      "round",
      "floor",
      "ceiling",
      "custom",
    ].includes(operator);

    setDisabledFields({ offset_value: disableOffsetValue });
  };

  const {
    formState,
    handleChange,
    handleSubmit,
    errors,
    message,
    handleCloseMessage,
    resetForm,
  } = useForm(initialState, offsetFormConfig.validationSchema, onSubmit);

  // Update the aws_mapping_id options with fetched data
  const updatedFields = offsetFormConfig.fields.map((field) =>
    field.name === "aws_mapping_id"
      ? { ...field, options: awsMappings, isLoading: isAwsMappingsLoading }
      : field.name === "operator"
      ? { ...field, onChange: handleOperatorChange } // Add operator change handler
      : field
  );

  return (
    <Container maxWidth="xl" style={{ marginTop: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormBuilder
            title={offsetFormConfig.title}
            fields={updatedFields}
            onSubmit={handleSubmit}
            formState={formState}
            handleChange={(e) => {
              handleChange(e);
              if (e.target.name === "operator") handleOperatorChange(e);
            }}
            errors={errors}
            message={message}
            handleCloseMessage={handleCloseMessage}
            resetForm={resetForm}
            disabledFields={disabledFields} // Pass disabled fields to FormBuilder
            isSubmitting={isSubmitting} // Pass loading state

         />
        </Grid>
        <Grid item xs={12} md={6}>
          <OffsetsTable TableConfig={offsetTableConfig} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default OffsetForm;
