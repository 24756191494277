export const offsetFormConfig = {
	title: "Add Offset value",
	fields: [
		{
			name: "field_name",
			label: "Field Name",
			type: "select",
			options: [
				{ value: "temperature_ext", label: "Temperature (External)" },
				{ value: "humidity_ext", label: "Humidity (External)" },
				{ value: "pressure_int", label: "Pressure (Internal)" },
				{ value: "pitch", label: "Pitch" },
				{ value: "roll", label: "Roll" },
				{ value: "wind_speed", label: "Wind Speed" },
				{ value: "wind_direction", label: "Wind Direction" },
				{ value: "wind_gust_speed", label: "Wind Gust Speed" },
				{ value: "humidity_int", label: "Humidity (Internal)" },
				{ value: "wind_gust_direction", label: "Wind Gust Direction" },
				{ value: "voltage", label: "Voltage" },
				{ value: "pressure_ext", label: "Pressure (External)" },
				{ value: "solar", label: "Solar" },
				{ value: "snow_depth", label: "Snow Depth" },
				{ value: "temperature_int", label: "Temperature (Internal)" },
			],
		},
		{
			name: "operator",
			label: "Operator", 
			type: "select",
			options: [
                { value: '+', label: "Add" },
                { value: '-', label: "Subtract" },
                { value: '*', label: "Multiply" },
                { value: '/', label: "Divide" },
                { value: '%', label: "Modulo" },
                { value: 'abs', label: "Absolute Value" },
                { value: 'round', label: "Round" },
                { value: 'floor', label: "Floor" },
                { value: 'ceiling', label: "Ceiling" },
				{ value: 'custom', label: "Custom Expression" } // New option

                          ],
		},
		
		{ name: "offset_value", label: "Offset value", type: "number" },
		
		{
			name: "aws_mapping_id",
			label: "Station",
			type: "select",
			options: [], // This will be populated dynamically
		},
		{ name: "from_date", label: "From Date", type: "datetime-local" },
		{
			name: "to_date",
			label: "To Date",
			type: "datetime-local",
		},		{ name: "comment", label: "Comment", type: "text" },

	],
	validationSchema: {
		field_name: { required: true },
		// offset_value: { required: true },
		operator: { required: true },
		aws_mapping_id: { required: true },
		from_date: { required: true },	},
};


export const offsetTableConfig = {
	TableId:"offsets",
    offsetTableTitle: "Offset Data",
    offsetTableHeaders : [
        "ID",
        "Station",
        "Field Name",
        "offset Value",
        "Operator",
        "From Date",
        "To Date",
        "Comment"
	      ],

}