import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
} from "@mui/material";
import { postFormData, getFormData } from "../../../services/api";
import MessageDisplay from "../../Form/MessageDisplay";
import { createFilterOptions } from '@mui/material/Autocomplete';
// Create a custom filter function at the top of your component
const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });
  
const AddEquipment = ({ onClose ,modelId, onSkip}) => {
  const { uid } = useParams();
  const [equipmentInfo, setEquipmentInfo] = useState({
    name: "",
    type: "",
    serial_number: "",
    purchase_date: null,
    deployment_date: null,
    status: "Active",
    comments: "",
    model_id: "",
    end_deployment_date: null,
  });
  const [availableModels, setAvailableModels] = useState([]);
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [severity, setSeverity] = useState("success");
    // Pre-select the model if provided
    useEffect(() => {
        if (modelId) {
          setEquipmentInfo(prev => ({
            ...prev,
            model_id: modelId
          }));
        }
      }, [modelId]);
    
  // Predefined options for status and type
  const [customTypes, setCustomTypes] = useState([
    "Anemometer",
    "Wind Monitor",
    "Temperature Sensor",
    "Humidity Sensor",
    "Pressure Sensor",
    "Rain Gauge"
  ]);

  const [customStatuses, setCustomStatuses] = useState([
    "Available for deployment",
    "Operational",
    "Non-operational"
  ]);

  const handleAutocompleteChange = (field, newValue) => {
    // Update equipment info with the new value
    setEquipmentInfo({ ...equipmentInfo, [field]: newValue });

    // If it's a new value, add it to the corresponding options list
    if (newValue && field === 'type' && !customTypes.includes(newValue)) {
      setCustomTypes(prev => [...prev, newValue]);
    }
    if (newValue && field === 'status' && !customStatuses.includes(newValue)) {
      setCustomStatuses(prev => [...prev, newValue]);
    }
  };

  useEffect(() => {
    // Fetch available equipment models
    const fetchModels = async () => {
      try {
        const response = await getFormData('metadata/equipment-model');
        setAvailableModels(response);
      } catch (error) {
        console.error("Error fetching models:", error);
        setMessageContent("Failed to fetch equipment models");
        setSeverity("error");
        setMessageOpen(true);

      }
    };
    fetchModels();
  }, []);

  const handleChange = (e) => {
    setEquipmentInfo({ ...equipmentInfo, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    if (!equipmentInfo.name) newErrors.name = "Equipment name is required";
    if (!equipmentInfo.type) newErrors.type = "Equipment type is required";
    if (!equipmentInfo.serial_number) newErrors.serial_number = "Serial number is required";
    if (!equipmentInfo.status) newErrors.status = "Status is required";
    if (!equipmentInfo.model_id) newErrors.model_id = "Equipment model is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
              // Create submission data with proper date handling
      const dataToSubmit = {
        ...equipmentInfo,
        purchase_date: equipmentInfo.purchase_date || null,
        deployment_date: equipmentInfo.deployment_date || null,
        end_deployment_date: equipmentInfo.end_deployment_date || null
      };


      const response=await postFormData(`/metadata/logger-equipment/${uid}`, dataToSubmit);
          // Get the model name for display
    const selectedModel = availableModels.find(model => model.id === equipmentInfo.model_id);

      setMessageContent("Equipment added successfully!");
      setSeverity("success");
      setMessageOpen(true);
    // Pass back complete equipment data including the ID and model name
    onClose({
        id: response.id,
        name: equipmentInfo.name,
        type: equipmentInfo.type,
        serial_number: equipmentInfo.serial_number,
        status: equipmentInfo.status,
        model_id: equipmentInfo.model_id,
        model_name: selectedModel ? selectedModel.name : '',
        // Include other relevant fields
      });
  
    } catch (error) {
      let errorMessage = "Failed to add equipment";
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setMessageContent(errorMessage);
      setSeverity("error");
      setMessageOpen(true);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Paper elevation={3} sx={{ width: "100%", maxWidth: "800px", p: 3 }}>
        <Typography variant="h6" gutterBottom>Add New Equipment</Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Equipment Name"
              name="name"
              value={equipmentInfo.name}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
  <Autocomplete
    freeSolo
    options={customTypes}
    value={equipmentInfo.type}
    onChange={(event, newValue) => handleAutocompleteChange('type', newValue)}
    filterOptions={(options, params) => {
      const filtered = filterOptions(options, params);
      
      // Only add the custom value if it's not empty and not already in options
      if (params.inputValue !== '' && 
          !options.includes(params.inputValue) && 
          params.inputValue.length > 0) {
        filtered.push(params.inputValue);
      }
      
      return filtered;
    }}
    onBlur={(event) => {
      // Add the custom value to options when focus is lost
      if (equipmentInfo.type && !customTypes.includes(equipmentInfo.type)) {
        setCustomTypes(prev => [...prev, equipmentInfo.type]);
      }
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Equipment Type"
        required
        error={!!errors.type}
        helperText={errors.type}
      />
    )}
  />
</Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Serial Number"
              name="serial_number"
              value={equipmentInfo.serial_number}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.serial_number}
              helperText={errors.serial_number}
            />
          </Grid>

          <Grid item xs={12} md={6}>
  <Autocomplete
    freeSolo
    options={customStatuses}
    value={equipmentInfo.status}
    onChange={(event, newValue) => handleAutocompleteChange('status', newValue)}
    filterOptions={(options, params) => {
      const filtered = filterOptions(options, params);
      
      // Only add the custom value if it's not empty and not already in options
      if (params.inputValue !== '' && 
          !options.includes(params.inputValue) && 
          params.inputValue.length > 0) {
        filtered.push(params.inputValue);
      }
      
      return filtered;
    }}
    onBlur={(event) => {
      // Add the custom value to options when focus is lost
      if (equipmentInfo.status && !customStatuses.includes(equipmentInfo.status)) {
        setCustomStatuses(prev => [...prev, equipmentInfo.status]);
      }
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Status"
        required
        error={!!errors.status}
        helperText={errors.status}
      />
    )}
  />
</Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Purchase Date"
              name="purchase_date"
              type="datetime-local"
              value={equipmentInfo.purchase_date}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Deployment Date"
              name="deployment_date"
              type="datetime-local"
              value={equipmentInfo.deployment_date}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
          <FormControl 
            fullWidth 
            error={!!errors.model_id}
            required
            // sx={{ mt: 2, mb: 1 }}
          >
            <InputLabel>Equipment Model</InputLabel>
            <Select
              name="model_id"
              value={equipmentInfo.model_id || ''}
              onChange={handleChange}
              label="Equipment Model"
            >
              {availableModels.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  {model.name} - {model.manufacturer}
                </MenuItem>
              ))}
            </Select>
            {errors.model_id && (
              <Typography variant="caption" color="error">
                {errors.model_id}
              </Typography>
            )}
          </FormControl>
        </Grid>

          <Grid item xs={12}>
            <TextField
              label="Comments"
              name="comments"
              value={equipmentInfo.comments}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="End Deployment Date"
              name="end_deployment_date"
              type="datetime-local"
              value={equipmentInfo.end_deployment_date}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

        </Grid>

        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Add Equipment
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>

        <MessageDisplay
          message={messageContent}
          severity={severity}
          open={messageOpen}
          onClose={() => setMessageOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default AddEquipment;

