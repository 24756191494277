import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "90vh",
			}}
		>
			<CheckCircleOutlineIcon sx={{ fontSize: 100, marginBottom: 2 }} />
			<Typography variant="h5" gutterBottom>
				Thank you!
			</Typography>
			<Typography variant="body1" gutterBottom>
				Your account has been successfully created.
			</Typography>
			<Typography
				onClick={() => navigate("/auth")}
				sx={{ marginTop: 2, textDecoration: "underline", cursor: "pointer" }}
			>
				Click here to login
			</Typography>
		</Box>
	);
};

export default SuccessPage;
