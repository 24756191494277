import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { stationMetaData } from "../utils/rawData";
import { useEffect } from "react";
// import { isConnected, subscribe, sendMessage } from './websocketManager';

const API_BASE_URL = "https://client-api.silanga.ca/aws";
const API_KEY = process.env.REACT_APP_API_TOKEN;

const fetchWeatherData = async (stationId) => {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: { uid: stationId },
      headers: { "x-api-key": API_KEY },
    });
    return response.data[0];
  } catch (error) {
    console.error(`Error fetching data for station ${stationId}:`, error);
    return null;
  }
};

const fetchWeatherStations = async () => {
  const updatedStations = [];
  for (const station of stationMetaData) {
    const data = await fetchWeatherData(station.id);
    if (data && data.elements) {
      const temperature = data.elements.temperature_ext?.value;
      const qcValue = data.elements.temperature_ext?.qc_value;

      updatedStations.push({
        ...station,
        lat: station.default_latitude,
        lon: station.default_longitude,
        info:
          qcValue !== undefined
            ? qcValue !== 100
              ? " - °C"
              : `${temperature}°C`
            : temperature !== undefined
            ? `${temperature}°C`
            : "N/A",
        hasError: false,
      });
    } else {
      updatedStations.push({
        ...station,
        lat: station.default_latitude,
        lon: station.default_longitude,
        info: "Non - Operational",
        hasError: true,
      });
    }
  }
  return updatedStations;
};

export const useWeatherStationsMap = () => {
  const queryClient = useQueryClient();

  const query = useQuery("weatherStations", fetchWeatherStations, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: true,
  });

  // const updateStationData = (data) => {
  //     queryClient.setQueryData("weatherStations", (oldData) => {
  //         if (!oldData) return oldData;
  //         return oldData.map(station => {
  //             if (station.id === data.uid) {
  //                 const qcValue = data.QC_value; // Extract QC_value
  //                 return {
  //                     ...station,
  //                     info:
  //                         qcValue !== undefined && qcValue !== 100
  //                             ? ' - °C' // If QC_value exists and is not 100, set to '-'
  //                             : data.temperature_ext !== undefined
  //                             ? `${data.temperature_ext}°C` // Otherwise, show temperature
  //                             : 'N/A', // Fallback if temperature is not available
  //                     hasError: false,
  //                 };
  //             }
  //             return station;
  //         });
  //     });
  // };
  useEffect(() => {
    const scheduleNextRefetch = () => {
      const now = new Date();
      // Calculate time until next hour + 3 minutes
      const nextRefetchTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() + 1, // Next hour
        3, // 3 minutes past the hour
        0
      );

      const timeUntilNextRefetch = nextRefetchTime.getTime() - now.getTime();
      return setTimeout(async () => {
        await fetchWeatherStations().then((newData) => {
          queryClient.setQueryData("weatherStations", newData);
        });
        // Schedule the next refetch
        scheduleNextRefetch();
      }, timeUntilNextRefetch);
    };

    const timeoutId = scheduleNextRefetch();

    // Cleanup on unmount
    return () => clearTimeout(timeoutId);
  }, [queryClient]);

  const refetchWeatherStations = async () => {
    const newData = await fetchWeatherStations();
    queryClient.setQueryData("weatherStations", newData);

    return true;
  };

  // useEffect(() => {
  //     const unsubscribe = subscribe((data) => {

  //         if (typeof data === 'object' && data.uid && data.temperature_ext !== undefined) {
  //             updateStationData(data);
  //         }
  //     });

  //     return () => {
  //         unsubscribe();
  //     };
  // }, []);

  // useEffect(() => {
  //     const keepAlive = () => {
  //         sendMessage('');
  //     };

  //     const interval = setInterval(keepAlive, 540000); // Send keep-alive every 9 minutes

  //     return () => {
  //         clearInterval(interval);
  //     };
  // }, []);

  // return { ...query, refetchWeatherStations, isConnected };
  return { ...query, refetchWeatherStations };
};

// // hooks/useWeatherStations.js
// import { useQuery, useQueryClient } from 'react-query';
// import axios from 'axios';
// import { stationMetaData } from "../utils/rawData";

// const API_BASE_URL = "https://client-api.silanga.ca/aws";
// const API_KEY = process.env.REACT_APP_API_TOKEN;

// const fetchWeatherData = async (stationId) => {
//     try {
//         const response = await axios.get(API_BASE_URL, {
//             params: { uid: stationId },
//             headers: { "x-api-key": API_KEY },
//         });
//         return response.data[0];
//     } catch (error) {
//         console.error(`Error fetching data for station ${stationId}:`, error);
//         return null;
//     }
// };

// const fetchWeatherStations = async () => {
//     const updatedStations = [];
//     for (const station of stationMetaData) {
//         const data = await fetchWeatherData(station.id);
//         if (data && data.elements) {
//             const temperature = data.elements.temperature_ext?.value;
//             updatedStations.push({
//                 ...station,
//                 lat: station.default_latitude,
//                 lon: station.default_longitude,
//                 info: `(${temperature !== undefined ? temperature + "°C" : "N/A"})`,
//                 hasError: false,
//             });
//         } else {
//             updatedStations.push({
//                 ...station,
//                 lat: station.default_latitude,
//                 lon: station.default_longitude,
//                 info: "Non - Operational",
//                 hasError: true,
//             });
//         }
//     }
//     return updatedStations;
// };

// const loadFromLocalStorage = () => {
//     const data = localStorage.getItem('weatherStations');
//     return data ? JSON.parse(data) : null;
// };

// const saveToLocalStorage = (data) => {
//     localStorage.setItem('weatherStations', JSON.stringify(data));
// };

// export const useWeatherStationsMap = () => {
//     const queryClient = useQueryClient();

//     const query = useQuery("weatherStations", async () => {
//         const cachedData = loadFromLocalStorage();
//         if (cachedData) {
//             return cachedData;
//         }
//         const freshData = await fetchWeatherStations();
//         saveToLocalStorage(freshData);
//         return freshData;
//     }, {
//         staleTime: 1 * 60 * 1000, // 1 minutes
//         cacheTime: 1 * 60 * 60 * 1000, // 1 hour
//         refetchOnWindowFocus: false,
//         refetchOnMount: false,
//         refetchOnReconnect: false,
//     });

//     const refetchWeatherStations = async () => {
//         const newData = await fetchWeatherStations();
//         const currentData = queryClient.getQueryData("weatherStations");
//         const hasNewData = JSON.stringify(newData) !== JSON.stringify(currentData);

//         if (hasNewData) {
//             queryClient.setQueryData("weatherStations", newData);
//             saveToLocalStorage(newData);
//         }

//         return hasNewData;
//     };

//     return { ...query, refetchWeatherStations };
// };
