import React, { useState, useEffect } from "react";
import useForm from "../hooks/useForm";
import {
	postFormData,
	getFormData,
	updateMetadataMapping,
} from "../services/api";
import {
	offsetFormConfig,
	offsetTableConfig,
} from "../config/formConfigs/offsetConfig";
import moment from "moment";
import FormBuilder from "../components/Form/FormBuilder";
import { CircularProgress, Container, Grid } from "@mui/material";
import OffsetsTable from "../components/Tables/OffsetsTable";
import { awsMappingConfig, awsMappingTableConfig } from "../config/formConfigs/awsMappingConfig";
import AwsMappingTable from "../components/Tables/AwsMappingTable";

const AwsMapping = () => {
	const [metaDataMapping, setMetaDataMapping] = useState([]);
	const [isMetadataMappingLoading, setIsMetadataMappingLoading] =
		useState(true);
	const [awsMapping, setAwsMapping] = useState([]);
	const [isAwsMappingLoading, setIsAwsMappingLoading] = useState(true);
	useEffect(() => {
		const fetchAwsMapping = async () => {
			try {
				const response = await getFormData("aws-mappings");
				const mappings = response.map((mapping) => ({
					value: mapping.id,
					label: `${mapping.imei} - ${mapping.uid}`,
				}));
				setAwsMapping(mappings);
			} catch (error) {
				console.error("Error fetching AWS mappings imei:", error);
			} finally {
				setIsAwsMappingLoading(false);
			}
		};

		const fetchmetadataMapping = async () => {
			try {
				const response = await getFormData("aws-metadata-mapping");
				const mappings = response.map((mapping) => ({
					value: mapping.id,
					label: mapping.station_name,
				}));
				setMetaDataMapping(mappings);
			} catch (error) {
				console.error("Error fetching AWS metadata mappings:", error);
			} finally {
				setIsMetadataMappingLoading(false);
			}
		};

		fetchAwsMapping();

		fetchmetadataMapping();
	}, []);

	const initialState = {
		id: "",
		metadata_mapping_id: "",
	};

	const onSubmit = async (formData) => {
		try {
			const id = parseInt(formData.id); //imei linked id in the table
			const formattedData = {
				metadata_mapping_id: parseInt(formData.metadata_mapping_id),
			};
			const response = await updateMetadataMapping(id, formattedData);
			// If the submission is successful, the form will be reset automatically
			return response ;
		} catch (error) {
			throw error;
		}
	};

	const {
		formState,
		handleChange,
		handleSubmit,
		errors,
		message,
		handleCloseMessage,
		resetForm,
	} = useForm(initialState, awsMappingConfig.validationSchema, onSubmit);

	// Update the aws_mapping_id options with the fetched data
	const updatedFields = awsMappingConfig.fields.map((field) => {
		if (field.name === "metadata_mapping_id") {
			return {
				...field,
				options: metaDataMapping,
				isLoading: isMetadataMappingLoading,
			};
		} else if (field.name === "id") {
			return {
				...field,
				options: awsMapping,
				isLoading: isAwsMappingLoading,
			};
		} else {
			return field;
		}
	});

	return (
		<Container maxWidth="xl" style={{ marginTop: "20px" }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<FormBuilder
						title={awsMappingConfig.title}
						fields={updatedFields}
						onSubmit={handleSubmit}
						formState={formState}
						handleChange={handleChange}
						errors={errors}
						message={message}
						handleCloseMessage={handleCloseMessage}
						resetForm={resetForm}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<AwsMappingTable TableConfig={awsMappingTableConfig}/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default AwsMapping;
