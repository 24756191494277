import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { postFormData, getFormData } from "../../../services/api";
import MessageDisplay from "../../Form/MessageDisplay";

const AddEquipmentAttribute = ({ onClose, modelId, equipments = [] }) => { // Changed equipmentIds to equipments
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });

  const predefinedAttributes = [
    "Temperature",
    "Humidity",
    "Power Supply",
    "Measurement Range",
    "Resolution",
    "Voltage"
  ];

  const [attributeNames, setAttributeNames] = useState(predefinedAttributes);
  const [availableModels, setAvailableModels] = useState([]);
  const [attributeInfo, setAttributeInfo] = useState({
    equipment_model_id: modelId || "",
    attribute_name: "",
    attribute_value: "",
    // equipment_id: "" // Added equipment_id to state
  });
  const [errors, setErrors] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch models
        const modelsResponse = await getFormData('metadata/equipment-model');
        setAvailableModels(modelsResponse);

        // Fetch existing attributes
        const attributesResponse = await getFormData('metadata/equipment-attribute');
        
        // Extract unique attribute names from the response
        const dbAttributes = [...new Set(
          attributesResponse
            .map(attr => attr.attribute_name)
            .filter(Boolean)
        )];

        // Combine predefined and database attributes, removing duplicates
        const combinedAttributes = [...new Set([
          ...predefinedAttributes,
          ...dbAttributes
        ])];

        setAttributeNames(combinedAttributes);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessageContent("Failed to fetch data");
        setSeverity("error");
        setMessageOpen(true);
      }
    };

    fetchData();
  }, []);

  // Update model_id when prop changes
  useEffect(() => {
    if (modelId) {
      setAttributeInfo(prev => ({
        ...prev,
        equipment_model_id: modelId
      }));
    }
  }, [modelId]);

  const handleChange = (e) => {
    setAttributeInfo({ ...attributeInfo, [e.target.name]: e.target.value });
  };

  const handleAttributeNameChange = (event, newValue) => {
    setAttributeInfo({ ...attributeInfo, attribute_name: newValue });
    
    if (newValue && !attributeNames.includes(newValue)) {
      setAttributeNames(prev => [...prev, newValue]);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!attributeInfo.equipment_model_id) newErrors.equipment_model_id = "Equipment model is required";
    // if (!attributeInfo.equipment_id) newErrors.equipment_id = "Equipment selection is required";
    if (!attributeInfo.attribute_name) newErrors.attribute_name = "Attribute name is required";
    if (!attributeInfo.attribute_value) newErrors.attribute_value = "Attribute value is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      await postFormData('/metadata/equipment-attribute', {
        ...attributeInfo,
        equipment_model_id: modelId || attributeInfo.equipment_model_id,
      });
      
      setMessageContent("Equipment attribute added successfully!");
      setSeverity("success");
      setMessageOpen(true);
      setTimeout(onClose, 2000);
    } catch (error) {
      let errorMessage = "Failed to add equipment attribute";
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setMessageContent(errorMessage);
      setSeverity("error");
      setMessageOpen(true);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Paper elevation={3} sx={{ width: "100%", maxWidth: "800px", p: 3 }}>
        <Typography variant="h6" gutterBottom>Add Equipment Attribute</Typography>
        
        <Grid container spacing={2}>
          {/* Equipment Selection */}
          {/* <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.equipment_id}>
              <InputLabel>Select Equipment</InputLabel>
              <Select
                name="equipment_id"
                value={attributeInfo.equipment_id}
                onChange={handleChange}
                required
                label="Select Equipment"
              >
                {equipments.map((equipment) => (
                  <MenuItem key={equipment.id} value={equipment.id}>
                    {equipment.name} - {equipment.serial_number}
                  </MenuItem>
                ))}
              </Select>
              {errors.equipment_id && (
                <Typography color="error" variant="caption">
                  {errors.equipment_id}
                </Typography>
              )}
            </FormControl>
          </Grid> */}

          {/* Model Selection (if modelId not provided) */}
          {!modelId && (
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.equipment_model_id}>
                <InputLabel>Equipment Model</InputLabel>
                <Select
                  name="equipment_model_id"
                  value={attributeInfo.equipment_model_id}
                  onChange={handleChange}
                  required
                  label="Equipment Model"
                >
                  {availableModels.map((model) => (
                    <MenuItem key={model.id} value={model.id}>
                      {model.name} - {model.manufacturer}
                    </MenuItem>
                  ))}
                </Select>
                {errors.equipment_model_id && (
                  <Typography color="error" variant="caption">
                    {errors.equipment_model_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Autocomplete
              freeSolo
              options={attributeNames}
              value={attributeInfo.attribute_name}
              onChange={handleAttributeNameChange}
              filterOptions={(options, params) => {
                const filtered = filterOptions(options, params);
                
                if (params.inputValue !== '' && 
                    !filtered.includes(params.inputValue) && 
                    !options.includes(params.inputValue)) {
                  filtered.push(params.inputValue);
                }
                
                return filtered;
              }}
              onBlur={(event) => {
                if (attributeInfo.attribute_name && 
                    !attributeNames.includes(attributeInfo.attribute_name)) {
                  setAttributeNames(prev => [...prev, attributeInfo.attribute_name]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Attribute Name"
                  required
                  error={!!errors.attribute_name}
                  helperText={errors.attribute_name}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Attribute Value"
              name="attribute_value"
              value={attributeInfo.attribute_value}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.attribute_value}
              helperText={errors.attribute_value}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Add Attribute
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>

        <MessageDisplay
          message={messageContent}
          severity={severity}
          open={messageOpen}
          onClose={() => setMessageOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default AddEquipmentAttribute;