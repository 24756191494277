import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import { stationMetaData } from "../utils/rawData";
import Cookies from "js-cookie";
import PermissionDenied from "../components/PermissionDenied/PermissionDenied";

export const createUrlFriendlyName = (name) => {
	return name
	  .toLowerCase()
	  .replace(/\s+/g, '-')
	  .replace(/[^a-z0-9-]/g, ''); // Remove special characters
  };
  
  export const findStationByNameOrId = (identifier) => {
	if (!identifier) return null;
	
  // Force lowercase for consistency
  const normalizedIdentifier = decodeURIComponent(identifier).toLowerCase().replace(/%20/g, '-'); // Replace any remaining %20 with hyphens
  ;
	
  const station= stationMetaData.find(station => 
	  station.id.toLowerCase() === normalizedIdentifier ||
	  createUrlFriendlyName(station.name) === createUrlFriendlyName(normalizedIdentifier)
	);
	return station || null; // Explicitly return null if no station found

  };
  
  
export const getStatusColor = (status) => {
	switch (status) {
		case "Operational":
			return "#DFF2BF"; // Green
		case "Non Operational":
			return "#FFBABA"; // Red
		case "Warning":
			return "#FEEFB3"; // Yellow
		default:
			return "#E0E0E0"; // Grey for unknown statuses
	}
};

export const getStatusIcon = (status) => {
	let icon;
	let color;

	switch (status) {
		case "Operational":
			icon = <CheckCircleIcon />;
			color = "#78a62b";
			break;
		case "Non-Operational":
			icon = <CancelIcon />;
			color = "#ad4242";
			break;
		case "Warning":
			icon = <WarningIcon />;
			color = "#f2d666";
			break;
		default:
			icon = null;
			color = "#E0E0E0";
	}

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "2em",
				height: "2em",
				borderRadius: "50%",
				border: `2px solid ${color}`,
				color: color,
				marginRight: "0.5em",
			}}
		>
			{icon}
		</Box>
	);
};

// Utility function to convert degrees to cardinal directions
export const getCardinalDirection = (angle) => {
	// Adjust angle to be within the range of 0 to 360
	if (angle < 0) {
		angle = 360 + angle;
	}
	const directions = [
		"N",
		"NNE",
		"NE",
		"ENE",
		"E",
		"ESE",
		"SE",
		"SSE",
		"S",
		"SSW",
		"SW",
		"WSW",
		"W",
		"WNW",
		"NW",
		"NNW",
	];
	const index = Math.round(angle / 22.5) % 16;
	return directions[index];
};

export const convertMSToKMH = (speedInMS) => speedInMS * 3.6;

export const getQCFlag = (element) => {
	if (!element.qc_value && element.qc_value !== 0) return null;
	const flags = {
		"-10": "Suppressed",
		"-1": "Missing",
		0: "Error",
		10: "Doubtful",
		15: "Suspect/Warning",
		20: "Inconsistent",
		100: "Acceptable/Passed",
	};
	return flags[element.qc_value.toString()] || "Unknown";
};

export const getStationMetadata = (stationID) => {
	return stationMetaData.find((station) => station.id === stationID) || null;
};
export const redirectToLogin = () => {
  // Clear any existing tokens or user data
  Cookies.remove("accessToken");
  // Add any other cookies or local storage items you want to clear

  // Redirect to the login page
  window.location.href = "/auth";
};

export const redirectToUserHasNoAccess = (ErrorMessage) => {

  // Redirect to the login page
  window.location.href = "/permissionDenied";
  console.log(ErrorMessage);
};

