// FlagTableRows.js
import React, { useState, forwardRef, useEffect } from "react";
import {
	TableCell,
	TableRow,
	Checkbox,
	Button,
	TextField,
	Select,
	MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateFlag } from "../../services/api";
import { flagFormConfig } from "../../config/formConfigs/flagConfig";
import moment from "moment";

const FlagTableRows = forwardRef(
	(
		{
			data,
			editMode,
			deleteMode,
			selectedRows,
			setselectedRows,
			refetchRows,
			setMessage,
			cancelEditMode,
		},
		ref
	) => {
		const [editingId, setEditingId] = useState(null);
		const [editedRow, seteditedRow] = useState({});
		const [isLoading, setIsLoading] = useState(false);

		const handleEdit = (data) => {
			setEditingId(data.id);
			seteditedRow({
				...data,
				from_date: convertDateFormat(data?.from_date),
				to_date: data?.to_date ? convertDateFormat(data.to_date) : "",
			});
		};

		const handleSave = async () => {
			setIsLoading(true);
			try {
				const formattedRow = {
					...editedRow,
					from_date: editedRow.from_date
						? moment(editedRow.from_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
						: null,
					to_date: editedRow.to_date
						? moment(editedRow.to_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
						: null,
				};
				console.log(formattedRow);

				await updateFlag(formattedRow.id, formattedRow);
				setEditingId(null);
				refetchRows();
				setMessage({
					text: "Flag updated successfully",
					severity: "success",
					open: true,
				});
			} catch (error) {
				console.error("Error updating flag:", error);
				setMessage({
					text: error.message || "Error updating flag",
					severity: "error",
					open: true,
				});
			} finally {
				setIsLoading(false);
			}
		};

		const handleCancel = () => {
			setEditingId(null);
			seteditedRow({});
		};
		// Use useEffect to listen for changes in cancelEditMode
		useEffect(() => {
			if (cancelEditMode) {
				handleCancel();
			}
		}, [cancelEditMode]);
		const handleChange = (e, field) => {
			seteditedRow({ ...editedRow, [field]: e.target.value });
		};

		const handleSelectedRow = (id) => {
			setselectedRows((prev) =>
				prev.includes(id)
					? prev.filter((dataId) => dataId !== id)
					: [...prev, id]
			);
		};

		const convertDateFormat = (dateString) => {
			if (!dateString) return "";
			const parsedDate = moment(dateString, "MM/DD/YYYY, h:mm:ss a");
			return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DDTHH:mm") : "";
		};
		const cellStyles = {
			display: {
			  id: { minWidth: '80px' },
			  uid: { minWidth: '120px' },
			  variable: { minWidth: '200px' },
			  dates: { minWidth: '200px' },
			  qc_value: { minWidth: '150px' },
			  comment: { minWidth: '250px' },
			  aws_mapping: { minWidth: '120px' }
			}
		  };
	  
		  const commonCellStyle = {
			whiteSpace: 'pre-wrap',
			wordBreak: 'break-word',
			padding: '16px 8px'
		  };
	  
		  return (
			<TableRow ref={ref}>
			  {deleteMode && (
				<TableCell padding="checkbox" sx={{ width: '60px' }}>
				  <Checkbox
					checked={selectedRows.includes(data.id)}
					onChange={() => handleSelectedRow(data.id)}
				  />
				</TableCell>
			  )}
	  
			  {editMode && (
				<TableCell sx={{ width: '120px' }}>
				  {editingId === data.id ? (
					<>
					  <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">
						Save
					  </Button>
					  <Button onClick={handleCancel} startIcon={<CancelIcon />} size="small">
						Cancel
					  </Button>
					</>
				  ) : (
					<Button onClick={() => handleEdit(data)} startIcon={<EditIcon />} size="large" />
				  )}
				</TableCell>
			  )}
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.id }}>
				{data.id}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.uid }}>
				{data.uid}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.variable }}>
				{editMode && editingId === data.id ? (
				  <Select
					value={editedRow.variable}
					onChange={(e) => handleChange(e, "variable")}
					size="small"
					fullWidth
					sx={{ minWidth: cellStyles.display.variable.minWidth }}
				  >
					{flagFormConfig.fields
					  .find((f) => f.name === "variable")
					  .options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
						  {option.label}
						</MenuItem>
					  ))}
				  </Select>
				) : (
				  <div style={{ ...commonCellStyle }}>
					{data.variable}
				  </div>
				)}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.dates }}>
				{editingId === data.id ? (
				  <TextField
					type="datetime-local"
					value={editedRow.from_date}
					onChange={(e) => handleChange(e, "from_date")}
					size="small"
					fullWidth
					sx={{ minWidth: cellStyles.display.dates.minWidth }}
				  />
				) : (
				  <div style={{ ...commonCellStyle }}>
					{data.from_date || 'n/a'}
				  </div>
				)}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.dates }}>
				{editingId === data.id ? (
				  <TextField
					type="datetime-local"
					value={editedRow.to_date}
					onChange={(e) => handleChange(e, "to_date")}
					size="small"
					fullWidth
					sx={{ minWidth: cellStyles.display.dates.minWidth }}
				  />
				) : (
				  <div style={{ ...commonCellStyle }}>
					{data.to_date || 'n/a'}
				  </div>
				)}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.qc_value }}>
				{editingId === data.id ? (
				  <Select
					value={editedRow.QC_value}
					onChange={(e) => handleChange(e, "QC_value")}
					size="small"
					fullWidth
					sx={{ minWidth: cellStyles.display.qc_value.minWidth }}
				  >
					{flagFormConfig.fields
					  .find((f) => f.name === "QC_value")
					  .options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
						  {option.label}
						</MenuItem>
					  ))}
				  </Select>
				) : (
				  <div style={{ ...commonCellStyle }}>
					{data.QC_value}
				  </div>
				)}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.comment }}>
				{editingId === data.id ? (
				  <TextField
					value={editedRow.comment}
					onChange={(e) => handleChange(e, "comment")}
					multiline
					minRows={2}
					maxRows={4}
					fullWidth
					sx={{
					  minWidth: cellStyles.display.comment.minWidth,
					  '& .MuiInputBase-root': {
						padding: '8px',
						resize: 'vertical'
					  }
					}}
				  />
				) : (
				  <div style={{ 
					...commonCellStyle,
					maxHeight: '150px',
					overflow: 'auto'
				  }}>
					{data?.comment || 'n/a'}
				  </div>
				)}
			  </TableCell>
	  
			  <TableCell sx={{ ...commonCellStyle, ...cellStyles.display.aws_mapping }}>
				{data.aws_mapping_id}
			  </TableCell>
			</TableRow>
		  );
		}
	  );
	  
	  
export default FlagTableRows;
