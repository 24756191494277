export const pages = {
  about: {
    title: "About Us",
    path: "/pages/about",
    showInNav: true,
    navOrder: 1,
    content: {
      heading: "About Us",
      // subtitle: "Learn more about what we do", // optional
      sections: [
        // Text Section Example
        {
          type: "text",
          title: "Communities", // optional
          // subtitle: "What drives us forward", // optional
          content: [
            {
              text: "Silanga.ca is brought to you by Silanniarviit, an Inuit-led group in Nunavut who are involved with setting up community weather station networks.  Currently, we are involved with 4 community weather station networks: Igloolik, Arctic Bay, Pond Inlet and Sanirajak (coming soon). There is a single station in Grise Fiord and several research weather stations as well.",
            },
            {
              text: "We also acknowledge the excellent work by the Clyde River weather station network [[(https://clyderiverweather.org/)|https://clyderiverweather.org/|external]] run by Ittaq Heritage and Research Centre. Our weather station sites were chosen by community members and local technicians were trained to deploy and maintain them. They are meant to complement Inuit knowledge and other weather information available at [[windy.com|https://www.windy.com/|external]], [[Environment Canada|https://weather.gc.ca/|external]] and elsewhere.",
            }
          ],
        },
        {
          type: "image",
          title: "",
          subtitle: "",
          image: require("./images/Nuvuit_DSC_8163.jpg"),
          caption: "Sun setting behind the Nuvuit weather station",
          imagePosition: "center",
          imageStyle: "cover", // Options: 'cover', 'contain', 'fill', 'none', 'scale-down'
          imageFit: {
            width: "100%", // Optional: custom width
            maxWidth: "800px", // Optional: custom max width
            height: "auto", // Optional: custom height
            maxHeight: { xs: "300px", md: "500px" }, // Optional: responsive max height
          },
        },
        {
          type: "text",
          title: "", // optional
          subtitle: "", // optional
          content: [
            {
              text: "The weather information displayed here belongs to the communities that maintain the stations.  The information above is provided on a best-effort basis. This preliminary data has not been quality-controlled and we can’t guarantee these systems will work perfectly 100% of the time.",
            },
          ],
        },
        {
          type: "text",
          title:
            "If you have questions or comments about the stations, please contact:", // optional
          subtitle: "", // optional
          content: [
            {
              text: "• Travis Qaunaq or Daniel Attagutaluk (travisqaunaq@hotmail.com and attagutalukdaniel@gmail.com) for the Municipality of Igloolik",
            },
            {
              text: "• Andrew Arreak (aarreak@smartice.org) for SmartICE in Pond Inlet",
            },
            {
              text: "• Sheena Shappa (ikajutit@baffinhto.ca) for Ikajutit Hunters and Trappers Association in Arctic Bay",
            },
            {
              text: "• Thomasie Issigaitok (thomasissigaitok@hotmail.com) for Hall Beach Hunters and Trappers Association in Sanirajak (weather stations coming in August 2025).",
            },
          ],
        },
        // // Image Grid Example
        // {
        //   type: "imageGrid",
        //   title: "Gallery", // optional
        //   // subtitle: "Recent projects", // optional
        //   gridCols: 3, // optional, default: 3
        //   images: [
        //     // {
        //     //   img: require("./images/Tuqqajaat_DSC_8740.jpg"),
        //     //   title: "The Tuqqajaat weather station", // optional
        //     //   // subtitle: "Phase 1", // optional
        //     //   author: "Photo: A Loewen", // optional
        //     //   // cols: 2, // optional
        //     //   // rows: 2, // optional
        //     // },
        //     {
        //       img: require("./images/Nuvuit_DSC_8163.jpg"),
        //       title: "Sun setting behind the Nuvuit weather station", // optional
        //       // subtitle: "Phase 1", // optional
        //       author: "Photo: D Mueller", // optional
        //       cols: 2, // optional
        //       rows: 2, // optional
        //     },
        //     {
        //       img: require("./images/Qikiqtatannak.jpg"),
        //       title: "The Qikiqtatannak weather station", // optional
        //       // subtitle: "Phase 1", // optional
        //       author: "Photo: A Loewen", // optional
        //       cols: 1, // optional
        //       rows: 2, // optional
        //     },
        //     {
        //       img: require("./images/Ivisaaruqtuuq_20230520_184625.jpg"),
        //       title: "Setting up Ivisaaruqtuuq weather station", // optional
        //       // subtitle: "Phase 1", // optional
        //       author: "Photo: D Mueller", // optional
        //       // cols: 2, // optional
        //       // rows: 2, // optional
        //     },
        //     {
        //       img: require("./images/Aqigiliq_DSC_7503.jpg"),
        //       title:
        //         "Camille Lavallee attaches the instrument wires to the enclosure of the Agigiliq weather station", // optional
        //       // subtitle: "Phase 1", // optional
        //       author: "Photo: A Loewen", // optional
        //       // cols: 1, // optional
        //       // rows: 1, // optional
        //     },
        //   ],
        // },
        {
          type: "text",
          title: "", // optional
          subtitle: "", // optional
          content: [
            {
              text: "We are also working with community representatives to develop TV displays that can be used to show the same weather information that is available on this website in publicly accessible areas such as Hamlet and HTO offices.",
            },
          ],
        },
        // Single Image Example
        {
          type: "image",
          title: "",
          subtitle: "",
          image: require("./images/TV-kiosk.png"),
          caption: "A map from the TV display we are developing",
          imagePosition: "center",
          imageStyle: "cover", // Options: 'cover', 'contain', 'fill', 'none', 'scale-down'
          imageFit: {
            width: "100%", // Optional: custom width
            maxWidth: "800px", // Optional: custom max width
            height: "auto", // Optional: custom height
            maxHeight: { xs: "300px", md: "500px" }, // Optional: responsive max height
          },
        },
        {
          type: "text",
          title: "", // optional
          subtitle: "", // optional
          content: [
            {
              text: "Silanniarviit are supported by researchers at the Water and Ice Research Lab (WIRL) at Carleton University. The stations in Arctic Bay, Igloolik, Pond Inlet, and Sanirajak are based on the [[Cryologger|https://cryologger.org/|external]] automatic weather station design. Web design: Krish Gondaliya, WIRL.",
            },
          ],
        },

        {
          type: "image",
          // title: "Partners and Funding",
          subtitle: "Partners and Funding",
          image: require("./images/Partners and Funding.png"),
          // caption: "Partners and Funding",
          imagePosition: "center",
          imageStyle: "cover", // Options: 'cover', 'contain', 'fill', 'none', 'scale-down'
          imageFit: {
            width: "100%", // Optional: custom width
            maxWidth: "800px", // Optional: custom max width
            height: "auto", // Optional: custom height
            maxHeight: { xs: "300px", md: "500px" }, // Optional: responsive max height
          },
        },

        // Mixed Content Example
        // {
        //   type: "mixed",
        //   title: "Latest Updates", // optional
        //   subtitle: "What's new", // optional
        //   content: [
        //     {
        //       type: "text",
        //       text: "Recent developments",
        //       links: [
        //         // optional
        //         {
        //           text: "Read more",
        //           url: "/blog",
        //           external: false,
        //         },
        //       ],
        //     },
        //     {
        //       type: "image",
        //       image: require("./images/Aqigiliq_DSC_7503.jpg"),
        //       caption: "Team meeting", // optional
        //       author: "Mike Wilson", // optional
        //     },
        //   ],
        // },
      ],
    },
  },
  // gallery: {
  //   title: "Gallery",
  //   path: "/pages/gallery",
  //   showInNav: true,
  //   navOrder: 2,
  //   content: {
  //     heading: "Gallery",
  //     // subtitle: "Learn more about what we do", // optional
  //     sections: [
  //       // Text Section Example
  //       // Image Grid Example
  //       {
  //         type: "imageGrid",
  //         title: "Weather Stations", // optional
  //         // subtitle: "Recent projects", // optional
  //         gridCols: 3, // optional, default: 3
  //         images: [
  //           // {
  //           //   img: require("./images/Tuqqajaat_DSC_8740.jpg"),
  //           //   title: "The Tuqqajaat weather station", // optional
  //           //   // subtitle: "Phase 1", // optional
  //           //   author: "Photo: A Loewen", // optional
  //           //   // cols: 2, // optional
  //           //   // rows: 2, // optional
  //           // },
  //           {
  //             img: require("./images/Nuvuit_DSC_8163.jpg"),
  //             title: "Sun setting behind the Nuvuit weather station", // optional
  //             // subtitle: "Phase 1", // optional
  //             author: "Photo: D Mueller", // optional
  //             cols: 2, // optional
  //             rows: 2, // optional
  //           },
  //           {
  //             img: require("./images/Qikiqtatannak.jpg"),
  //             title: "The Qikiqtatannak weather station", // optional
  //             // subtitle: "Phase 1", // optional
  //             author: "Photo: A Loewen", // optional
  //             cols: 1, // optional
  //             rows: 2, // optional
  //           },
  //           {
  //             img: require("./images/Ivisaaruqtuuq_20230520_184625.jpg"),
  //             title: "Setting up Ivisaaruqtuuq weather station", // optional
  //             // subtitle: "Phase 1", // optional
  //             author: "Photo: D Mueller", // optional
  //             // cols: 2, // optional
  //             // rows: 2, // optional
  //           },
  //           {
  //             img: require("./images/Aqigiliq_DSC_7503.jpg"),
  //             title:
  //               "Camille Lavallee attaches the instrument wires to the enclosure of the Agigiliq weather station", // optional
  //             // subtitle: "Phase 1", // optional
  //             author: "Photo: A Loewen", // optional
  //             // cols: 1, // optional
  //             // rows: 1, // optional
  //           },
  //         ],
  //       },
  //     ]}}
};
