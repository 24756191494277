export const stationMetaData = [
  {
    id: "MPC",
    name: "Innaarulaalikuluup Nuvua",
    Community: "Arctic Bay",
    status: "Non-Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 73.52923,
    default_longitude: -85.44611,
  },
  {
    id: "TST",
    name: "Experimental Farm",
    Community: "",
    status: "Operational",
    type: "Tracking Beacons",
    location: "Ottawa",
    default_latitude: 45.382576,
    default_longitude: -75.714455,
  },
  {
    id: "DVD",
    name: "Ausuittuq",
    Community: "Grise Fiord",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 76.42336,
    default_longitude: -82.91111,
  },
  {
    id: "LOA",
    name: "Tuqqajaat",
    Community: "Pond Inlet",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 72.48496,
    default_longitude: -79.75987,
  },
  {
    id: "ALW",
    name: "Aqiggilik",
    Community: "Arctic Bay",
    status: "Non-Operational",
    type: "Tracking Beacons",
    location: "North Articbay",
    default_latitude: 73.560873,
    default_longitude: -83.761054,
  },
  {
    id: "TDY",
    name: "Ivisaaruqtuuq",
    Community: "Igloolik",
    status: "Non-Operational",
    type: "Sea Ice Monitoring",
    location: "North Articbay",
    default_latitude: 70.136567,
    default_longitude: -82.216802,
  },
  {
    id: "UBY",
    name: "Kapuiviit Nuvua",
    Community: "Igloolik",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 69.57053,
    default_longitude: -80.29736,
  },
  {
    id: "OEG",
    name: "Qalirusiq",
    Community: "Igloolik",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 69.3909,
    default_longitude: -81.79963,
  },
  {
    id: "LVY",
    name: "Kalliraaq",
    Community: "Igloolik",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 69.85861,
    default_longitude: -85.54392,
  },
  {
    id: "ZTJ",
    name: "Nuvuit",
    Community: "Igloolik",
    status: "Operational",
    type: "Sea Ice Monitoring",
    location: "North Articbay",
    default_latitude: 69.82437,
    default_longitude: -77.67336,
  },
  {
    id: "FON",
    name: "Milne Ice Shelf",
    Community: "",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 82.71045,
    default_longitude: -81.2807,
  },
  {
    id: "VUU",
    name: "Qikiqtatannak",
    Community: "Arctic Bay",
    status: "Operational",
    type: "Automatic Weather Station",
    location: "North Articbay",
    default_latitude: 72.756146,
    default_longitude: -85.91475,
  },
  {
    id: "NPK",
    name: "Purple Valley",
    Community: "",
    status: "Operational",
    type: "Sea Ice Monitoring",
    location: "North Articbay",
    default_latitude: 82.462486,
    default_longitude: -80.736578,
  },
  {
    id: "YBL",
    name: "Naujaaruluit",
    Community: "Igloolik",
    status: "Non-Operational",
    type: "Tracking Beacons",
    location: "North Articbay",
    default_latitude: 69.825861,
    default_longitude: -82.139611,
  },
];

export const flagTableHeaders = [
  "ID",
  "Station",
  "Variable",
  "From Date",
  "To Date",
  "QC Value",
  "Comment",
  "AWS Mapping ID",
];
