// NonOperationalStation.jsx
import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { getStatusIcon } from "../../utils/rawFunction";
import { useTranslation } from "../../context/TranslationContext";

function NonOperationalStation({ stationName }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { translate } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 64px)", // Accounts for navbar height
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: "800px",
          margin: 0, // Reset default margin
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              backgroundColor: "#fff",
              width: "100%",
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "4px",
                backgroundColor: "#ad4242",
              },
            }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              {/* Status Icon */}
              <Box
                sx={{
                  transform: `scale(${isMobile ? 1.2 : 1.5})`,
                  mb: isMobile ? 2 : 3,
                  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))",
                  mt: isMobile ? 0.5 : 1,
                }}
              >
                {getStatusIcon("Non-Operational")}
              </Box>

              {/* Rest of your content */}
              <Typography
                variant={isMobile ? "h6" : "h5"}
                sx={{
                  fontWeight: 600,
                  color: "text.primary",
                  mb: 1,
                  maxWidth: "100%",
                  wordBreak: "break-word",
                }}
              >
                {translate(stationName)}
              </Typography>

              <Typography
                variant={isMobile ? "subtitle1" : "h6"}
                sx={{
                  color: "#ad4242",
                  mb: 2,
                  fontWeight: 500,
                }}
              >
                {translate("Station Not Operational")}
              </Typography>

              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  mb: 4,
                  maxWidth: isMobile ? "100%" : 500,
                  px: isMobile ? 1 : 0,
                  lineHeight: 1.6,
                }}
              >
                {translate("This weather station is currently unavailable. Please select another station from the list or return later.")}
              </Typography>

              <Button
                variant="contained"
                fullWidth={isMobile}
                startIcon={<HomeIcon />}
                onClick={() => navigate("/")}
                sx={{
                  py: 1.5,
                  px: isMobile ? 2 : 4,
                  borderRadius: 1.5,
                  backgroundColor: "#ad4242",
                  boxShadow: "0 2px 8px rgba(173, 66, 66, 0.2)",
                  maxWidth: isMobile ? "100%" : "300px",
                  "&:hover": {
                    backgroundColor: "#943838",
                    boxShadow: "0 4px 12px rgba(173, 66, 66, 0.3)",
                  },
                }}
              >
                 {translate("Return to Home page")}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NonOperationalStation;
