import React, { useEffect, useRef, useState } from "react";
import {
	Typography,
	Card,
	CardContent,
	Grid,
	Box,
	Link,
	Paper,
	Divider,
	Chip,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	ListSubheader,
} from "@mui/material";
import theme from "../../../utils/theme";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StationInfoDisplay = ({ stationData }) => {
	const handleOpenLink = (url) => {
		if (url) {
			window.open(url, "_blank", "noopener,noreferrer");
		}
	};
	const photoCollection =
		stationData.length > 0 ? JSON.parse(stationData[0].photo_collection) : [];
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(true);
	const imageListRef = useRef(null);
	useEffect(() => {
		const handleScroll = () => {
			if (imageListRef.current) {
				const { scrollLeft, scrollWidth, clientWidth } = imageListRef.current;
				setShowLeftArrow(scrollLeft > 0);
				setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
			}
		};
		const imageList = imageListRef.current;
		if (imageList) {
			imageList.addEventListener("scroll", handleScroll);

			// Use a timeout to ensure the content has rendered
			setTimeout(() => {
				handleScroll(); // Initial check
			}, 100);
		}

		return () => {
			if (imageList) {
				imageList.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	const scroll = (direction) => {
		if (imageListRef.current) {
			const scrollAmount =
				direction === "left"
					? -imageListRef.current.clientWidth
					: imageListRef.current.clientWidth;
			imageListRef.current.scrollBy({
				left: scrollAmount,
				behavior: "smooth",
			});
		}
	};

	return (
		<Box>
			<CardContent>
				{stationData.length > 0 ? (
					<Grid container spacing={6}>
						<Grid item xs={12} md={6}>
							<Paper elevation={0} sx={{ padding: "20px", height: "100%" }}>
								<Divider
									textAlign="left"
									sx={{
										"&::before": { width: 12 },
										"&::after": { width: "100%" },
									}}
								>
									<Chip
										label="Name"
										sx={{
											backgroundColor: theme.palette.lightBlackBg.main,
											color: theme.palette.secondary.main,
										}}
									/>
								</Divider>

								<Box mt={2} ml={3}>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Variable Name:
										</Typography>
										<Typography variant="body1">
											{stationData[0].variable_name || "N/A"}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Station Name:
										</Typography>
										<Typography variant="body1">
											{stationData[0].station_name || "N/A"}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Alternative Name:
										</Typography>
										<Typography variant="body1">
											{stationData[0].alternative_name || "N/A"}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center">
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Community Name:
										</Typography>
										<Typography variant="body1">
											{stationData[0].community_name || "N/A"}
										</Typography>
									</Box>
								</Box>
							</Paper>
						</Grid>
						<Grid item xs={12} md={6}>
							<Paper elevation={0} sx={{ padding: "20px", height: "100%" }}>
								<Divider
									textAlign="left"
									sx={{
										"&::before": { width: 12 },
										"&::after": { width: "100%" },
									}}
								>
									<Chip
										label="Location"
										sx={{
											backgroundColor: theme.palette.lightBlackBg.main,
											color: theme.palette.secondary.main,
										}}
									/>
								</Divider>{" "}
								<Box mt={2} ml={3}>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Latitude:
										</Typography>
										<Typography variant="body1">
											{stationData[0].latitude || "N/A"}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Longitude:
										</Typography>
										<Typography variant="body1">
											{stationData[0].longitude || "N/A"}
										</Typography>
									</Box>
									<Box display="flex" alignItems="center">
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Elevation:
										</Typography>
										<Typography variant="body1">
											{stationData[0].elevation
												? `${stationData[0].elevation} m`
												: "N/A"}
										</Typography>
									</Box>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12}>
							<Paper elevation={0} sx={{ padding: "20px" }}>
								<Divider
									textAlign="left"
									sx={{
										"&::before": { width: 12 },
										"&::after": { width: "100%" },
									}}
								>
									<Chip
										label="Additional Information"
										sx={{
											backgroundColor: theme.palette.lightBlackBg.main,
											color: theme.palette.secondary.main,
										}}
									/>
								</Divider>

								<Box mt={2} ml={3}>
									<Box display="flex" alignItems="center" mb={2}>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											URL:
										</Typography>
										<Typography variant="body1">
											{stationData[0].url || "N/A"}
										</Typography>
										{stationData[0].url && (
											<IconButton
												size="small"
												onClick={() => handleOpenLink(stationData[0].url)}
												sx={{ ml: 1 }}
											>
												<OpenInNewIcon fontSize="small" />
											</IconButton>
										)}
									</Box>
									<Box>
										<Typography
											variant="subtitle1"
											color="textSecondary"
											sx={{ fontWeight: "bold", marginRight: "10px" }}
										>
											Description:
										</Typography>
										<Typography variant="body1">
											{stationData[0].site_description || "N/A"}
										</Typography>
									</Box>
								</Box>
							</Paper>
						</Grid>
						{/* Display Images using ImageList */}
						{photoCollection?.length > 0 && (
							<Grid item xs={12}>
								<Paper elevation={0} sx={{ padding: "20px" }}>
									<Divider
										textAlign="left"
										sx={{
											"&::before": { width: 12 },
											"&::after": { width: "100%" },
										}}
									>
										<Chip
											label="Photos"
											sx={{
												backgroundColor: theme.palette.lightBlackBg.main,
												color: theme.palette.secondary.main,
											}}
										/>
									</Divider>
									<Box mt={2} ml={3} position="relative">
										<ImageList
											ref={imageListRef}
											sx={{
												width: "100%",
												height: 300,
												transform: "translateZ(0)",
												flexWrap: "nowrap",
												overflowX: "auto",
												overflowY: "hidden",

												"&::-webkit-scrollbar": {
													width: "5px !important",
													height: "5px !important",
												},
												"&::-webkit-scrollbar-track": {
													backgroundColor: `${theme.palette.secondary.main} !important`,
												},
												"&::-webkit-scrollbar-thumb": {
													backgroundColor: `${theme.palette.primary.main} !important`,
													borderRadius: "5px !important",
												},
												"&::-webkit-scrollbar-thumb:hover": {
													backgroundColor: theme.palette.primary.main,
												},
											}}
											variant="quilted"
											cols={photoCollection?.length}
											rowHeight={300}
										>
											{photoCollection?.map((photo, index) => (
												<ImageListItem
													key={index}
													sx={{ width: "auto", height: 300 }}
												>
													<img
														src={`${photo.url}?w=300&h=300&fit=crop&auto=format`}
														srcSet={`${photo.url}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
														alt={photo.caption || `Photo ${index + 1}`}
														loading="lazy"
														style={{ height: "100%", width: "auto" }}
													
													/>
													<ImageListItemBar
														sx={{
															background:
																"linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
																"rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
														}}
														title={photo.caption}
														position="top"
														actionIcon={
															<IconButton
																sx={{ color: "white" }}
																aria-label={`info about ${
																	photo.caption || `Photo ${index + 1}`
																}`}
															>
																<InfoIcon />
															</IconButton>
														}
														actionPosition="left"
													/>
												</ImageListItem>
											))}
										</ImageList>

										{showRightArrow && (
											<IconButton
												sx={{
													position: "absolute",
													top: "50%",
													right: 8,
													transform: "translateY(-50%)",
													backgroundColor: theme.palette.primary.main,
													color: theme.palette.secondary.main,
													"&:hover": {
														backgroundColor: theme.palette.primary.dark,
													},
												}}
												onClick={() => scroll("right")}
											>
												<ArrowForwardIcon />
											</IconButton>
										)}
										{showLeftArrow && (
											<IconButton
												sx={{
													position: "absolute",
													top: "50%",
													left: 8,
													transform: "translateY(-50%)",
													backgroundColor: theme.palette.primary.main,
													color: theme.palette.secondary.main,
													"&:hover": {
														backgroundColor: theme.palette.primary.dark,
													},
												}}
												onClick={() => scroll("left")}
											>
												<ArrowBackIcon />
											</IconButton>
										)}
									</Box>
								</Paper>
							</Grid>
						)}
					</Grid>
				) : (
					<Typography variant="subtitle1">
						No station information available.
					</Typography>
				)}
			</CardContent>
		</Box>
	);
};

export default StationInfoDisplay;
