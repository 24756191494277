// formula for calculating wind chill is taken from https://climate.weather.gc.ca/glossary_e.html#windChill

export const calculateWindChill = (temperatureElement, windSpeedElement) => {
  // Return "-" if values are missing
  if (!temperatureElement?.value || !windSpeedElement?.value) {
    return null;
  }

  // Check QC values only if they exist, and return "-" if they're not 100
  if (
    (temperatureElement.qc_value !== undefined &&
      temperatureElement.qc_value !== 100) ||
    (windSpeedElement.qc_value !== undefined &&
      windSpeedElement.qc_value !== 100)
  ) {
    return null;
  }

  const temperature = temperatureElement.value;
  const windSpeedKmH = windSpeedElement.value * 3.6; // Convert m/s to km/h

  // Return temperature if conditions aren't met for wind chill
  if (temperature > 0) {
    return null;
  }

  let windChill;

  // Formula 1: For wind speeds >= 5 km/h
  if (windSpeedKmH >= 5) {
    windChill =
      13.12 +
      0.6215 * temperature -
      11.37 * Math.pow(windSpeedKmH, 0.16) +
      0.3965 * temperature * Math.pow(windSpeedKmH, 0.16);
  }
  // Formula 2: For wind speeds between 0-5 km/h
  else if (windSpeedKmH > 0) {
    windChill =
      temperature + ((-1.59 + 0.1345 * temperature) / 5) * windSpeedKmH;
  }
  // No wind chill if no wind
  else {
    return null;
  }

  return Math.round(windChill * 10) / 10;
};
