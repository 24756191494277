// DeleteConfirmDialog.js
import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const DeleteConfirmDialog = ({ open, setOpen, selectedRows, setselectedRows, setDeleteMode, refetchRows, setMessage ,deleteFunction,  // Added custom text props with defaults
  dialogTitle = "Confirm Deletion",
  dialogContent = "Are you sure you want to delete the selected rows?",
  cancelButtonText = "Cancel",
  confirmButtonText = "Delete"
}) => {
  const handleDeleteConfirm = async () => {
    try {
      await deleteFunction(selectedRows);
      refetchRows();
      setMessage({
        text: "Deleted successfully",
        severity: "success",
        open: true,
      });
    } catch (error) {
      setMessage({
        text: error.message || "Error deleting",
        severity: "error", 
        open: true,
      });
    } finally {
      setOpen(false);
      setDeleteMode(false);
      setselectedRows([]);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>          {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} >
        {cancelButtonText}

        </Button>
        <Button onClick={handleDeleteConfirm} color="error" autoFocus>
        {confirmButtonText}

        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;