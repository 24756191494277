// TableRows.js
import React, { useState, forwardRef, useEffect } from "react";
import {
	TableCell,
	TableRow,
	Checkbox,
	Button,
	TextField,
	Select,
	MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateFlag, updateOffset } from "../../services/api";
import moment from "moment";
import { offsetFormConfig } from "../../config/formConfigs/offsetConfig";

const OffsetTableRows = forwardRef(
	(
		{
			data,
			editMode,
			deleteMode,
			selectedRows,
			setselectedRows,
			refetchRows,
			setMessage,
			cancelEditMode,
		},
		ref
	) => {
		const [editingId, setEditingId] = useState(null);
		const [editedRow, seteditedRow] = useState({});
		const [isLoading, setIsLoading] = useState(false);
		const handleEdit = (data) => {
		
			setEditingId(data.id);
			seteditedRow({
				...data,
				from_date: convertDateFormat(data?.from_date),
				to_date: data?.to_date ? convertDateFormat(data.to_date) : "",

			});
		};

		const handleSave = async () => {
			setIsLoading(true);
			try {
				const formattedRow = {
					...editedRow,
					custom_expression:editedRow.custom_expression ? editedRow.custom_expression:null,
					from_date: editedRow.from_date
						? moment(editedRow.from_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
						: null,
					to_date: editedRow.to_date
						? moment(editedRow.to_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
						: null,
				};
				console.log(formattedRow.id, formattedRow);
				formattedRow.offset_value = Number(formattedRow.offset_value);
				await updateOffset(formattedRow.id, formattedRow);
				setEditingId(null);
				refetchRows();
				setMessage({
					text: "Offset updated successfully",
					severity: "success",
					open: true,
				});
			} catch (error) {
				console.error("Error updating Offset:", error);
				setMessage({
					text: error.message || "Error updating Offset",
					severity: "error",
					open: true,
				});
			} finally {
				setIsLoading(false);
			}
		};

		const handleCancel = () => {
			setEditingId(null);
			seteditedRow({});
		};
		// Use useEffect to listen for changes in cancelEditMode
		useEffect(() => {
			if (cancelEditMode) {
				handleCancel();
			}
		}, [cancelEditMode]);
		const handleChange = (e, field) => {
			const newValue = e.target.value;
    
			if (field === "operator") {
				const disablingOperators = ["abs", "round", "floor", "ceil", "custom"];
				seteditedRow({ 
					...editedRow, 
					[field]: newValue,
					// Set offset_value to 0 if operator is in disabling list
					offset_value: disablingOperators.includes(newValue) ? 0 : editedRow.offset_value 
				});
			} else {
				seteditedRow({ ...editedRow, [field]: newValue });
			}
				};

		const handleSelectedRow = (id) => {
			setselectedRows((prev) =>
				prev.includes(id)
					? prev.filter((dataId) => dataId !== id)
					: [...prev, id]
			);
		};
		const convertDateFormat = (dateString) => {
			if (!dateString) return "";
			const parsedDate = moment(dateString, "MM/DD/YYYY, h:mm:ss a");
			return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DDTHH:mm") : "";
		};
		const cellStyles = {
			display: {
			  id: { minWidth: '80px' },
			  uid: { minWidth: '120px' },
			  field_name: { minWidth: '200px' },
			  offset_value: { minWidth: '100px' },
			  operator: { minWidth: '120px' },
			  dates: { minWidth: '200px' },
			  comment: { minWidth: '250px' }
			}
		  };
	  
		  const commonCellStyle = {
			whiteSpace: 'pre-wrap',
			wordBreak: 'break-word',
			padding: '16px 8px'
		  };
	  


		return (
			<TableRow ref={ref}>
			{deleteMode && (
			  <TableCell padding="checkbox" sx={{ width: '60px' }}>
				<Checkbox
				  checked={selectedRows.includes(data.id)}
				  onChange={() => handleSelectedRow(data.id)}
				/>
			  </TableCell>
			)}
			
			{editMode && (
			  <TableCell sx={{ width: '120px' }}>
				{editingId === data.id ? (
				  <>
					<Button onClick={handleSave} startIcon={<SaveIcon />} size="small">
					  Save
					</Button>
					<Button onClick={handleCancel} startIcon={<CancelIcon />} size="small">
					  Cancel
					</Button>
				  </>
				) : (
				  <Button onClick={() => handleEdit(data)} startIcon={<EditIcon />} size="large" />
				)}
			  </TableCell>
			)}
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.id }}>
			  {data.id}
			</TableCell>
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.uid }}>
			  {data.uid}
			</TableCell>
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.field_name }}>
			  {editMode && editingId === data.id ? (
				<Select
				  value={editedRow.field_name}
				  onChange={(e) => handleChange(e, "field_name")}
				  size="small"
				  fullWidth
				  sx={{ minWidth: cellStyles.display.field_name.minWidth }}
				  
				>
				  {offsetFormConfig.fields
					.find((f) => f.name === "field_name")
					.options.map((option) => (
					  <MenuItem key={option.value} value={option.value}>
						{option.label}
					  </MenuItem>
					))}
				</Select>
			  ) : (
				<div style={{ ...commonCellStyle }}>
				  {data.field_name}
				</div>
			  )}
			</TableCell>
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.offset_value }}>
			  {editingId === data.id ? (
    <TextField
	value={editedRow.offset_value}
	onChange={(e) => handleChange(e, "offset_value")}
	size="small"
	fullWidth
	disabled={[
	  "abs",
	  "round",
	  "floor",
	  "ceiling",
	  "custom",
	].includes(editedRow.operator)} // Disable based on operator
	sx={{ minWidth: cellStyles.display.offset_value.minWidth }}
  />
			) : (
				<div style={{ ...commonCellStyle }}>
				  {data.offset_value}
				</div>
			  )}
			</TableCell>
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.operator }}>
  {editingId === data.id ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Select
        value={editedRow.operator}
        onChange={(e) => handleChange(e, "operator")}
        size="small"
        fullWidth
        disabled={data.custom_expression}
        sx={{ minWidth: cellStyles.display.operator.minWidth }}
      >
        {offsetFormConfig.fields
          .find((f) => f.name === "operator")
          .options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {data.custom_expression && (
  <div style={{ 
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    fontSize: '0.75rem',
    color: '#666'
  }}>
    <div style={{ fontStyle: 'italic' }}>
	Custom Expression: {data.custom_expression}
    </div>
    <div style={{ 
      color: '#d32f2f',
      fontStyle: 'italic'
    }}>
      (Cannot be edited - Reset and create new)
    </div>
  </div>
)}
</div>
) : (
<div style={{ ...commonCellStyle }}>
  {data.operator === 'custom' ? (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    }}>
      <div style={{ fontStyle: 'italic' }}>
       Custom Expression: {data.custom_expression}
      </div>
    </div>
  ) : (
    data.operator
  )}
    </div>
  )}
</TableCell>
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.dates }}>
			  {editingId === data.id ? (
				<TextField
				  type="datetime-local"
				  value={editedRow.from_date}
				  onChange={(e) => handleChange(e, "from_date")}
				  size="small"
				  fullWidth
				  sx={{ minWidth: cellStyles.display.dates.minWidth }}
				/>
			  ) : (
				<div style={{ ...commonCellStyle }}>
				  {data.from_date}
				</div>
			  )}
			</TableCell>
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.dates }}>
			  {editingId === data.id ? (
				<TextField
				  type="datetime-local"
				  value={editedRow.to_date}
				  onChange={(e) => handleChange(e, "to_date")}
				  size="small"
				  fullWidth
				  sx={{ minWidth: cellStyles.display.dates.minWidth }}
				/>
			  ) : (
				<div style={{ ...commonCellStyle }}>
				  {data.to_date || 'n/a'}
				</div>
			  )}
			</TableCell>
	
			<TableCell sx={{ ...commonCellStyle, ...cellStyles.display.comment }}>
			  {editingId === data.id ? (
				<TextField
				  value={editedRow.comment}
				  onChange={(e) => handleChange(e, "comment")}
				  multiline
				  minRows={2}
				  maxRows={4}
				  fullWidth
				  sx={{
					minWidth: cellStyles.display.comment.minWidth,
					'& .MuiInputBase-root': {
					  padding: '8px',
					  resize: 'vertical'
					}
				  }}
				/>
			  ) : (
				<div style={{ 
				  ...commonCellStyle,
				  maxHeight: '150px',
				  overflow: 'auto'
				}}>
				  {data?.comment || 'n/a'}
				</div>
			  )}
			</TableCell>
		  </TableRow>
		);
	  }
	);
	
	
export default OffsetTableRows;
