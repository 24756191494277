// DeleteModeActions.js
import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteModeActions = ({ deleteMode, handleDeleteMode, selectedRows, setDeleteConfirmOpen,  buttonText = "DELETE" // Default value if not provided
}) => (
  <Button
    onClick={deleteMode ? () => setDeleteConfirmOpen(true) : handleDeleteMode}
    startIcon={<DeleteIcon />}
    variant={deleteMode ? "contained" : "outlined"}
    size="small"
    disabled={deleteMode && selectedRows.length === 0}
  >
        {buttonText}

  </Button>
);

export default DeleteModeActions;