import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grid,
	Box,
	Typography,
	Alert,
} from "@mui/material";
import { postFormData } from "../../../services/api";

const AddPersonDialog = ({ open, onClose, onSuccess, initialData = null }) => {
	const [personInfo, setPersonInfo] = useState({
		firstname: initialData?.firstname || "",
		lastname: initialData?.lastname || "",
		email: initialData?.email || "",
		organization: initialData?.organization || "",
	});

	const [errors, setErrors] = useState({});

	const validateForm = () => {
		const newErrors = {};

		// Validate based on CHARACTER VARYING lengths from schema
		if (!personInfo.firstname) {
			newErrors.firstname = "First name is required";
		} else if (personInfo.firstname.length > 50) {
			newErrors.firstname = "First name must be 50 characters or less";
		}

		// if (!personInfo.lastname) {
		// 	newErrors.lastname = "Last name is required";
		// } 
        if (personInfo.lastname.length > 50) {
			newErrors.lastname = "Last name must be 50 characters or less";
		}

		// if (!personInfo.email) {
		// 	newErrors.email = "Email is required";
		// } 
        if (personInfo.email.length > 50) {
			newErrors.email = "Email must be 50 characters or less";
		} 
        // if (!/\S+@\S+\.\S+/.test(personInfo.email)) {
		// 	newErrors.email = "Invalid email format";
		// }

		if (!personInfo.organization) {
			newErrors.organization = "Organization is required";
		} 
         if (personInfo.organization.length > 50) {
			newErrors.organization = "Organization must be 50 characters or less";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async () => {
		if (!validateForm()) return;

		try {
			await postFormData("metadata/person", personInfo);
			onSuccess();
			handleClose();
		} catch (error) {
			setErrors({ submit: error.message || "Failed to add person" });
		}
	};

	const handleClose = () => {
		setPersonInfo({
			firstname: "",
			lastname: "",
			email: "",
			organization: "",
		});
		setErrors({});
		onClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				{initialData ? "Edit Person" : "Add New Person"}
			</DialogTitle>

			<DialogContent>
				<Box sx={{ pt: 2 }}>
					{errors.submit && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{errors.submit}
						</Alert>
					)}

					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="First Name"
								value={personInfo.firstname}
								onChange={(e) =>
									setPersonInfo((prev) => ({
										...prev,
										firstname: e.target.value,
									}))
								}
								error={!!errors.firstname}
								helperText={errors.firstname}
								required
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="Last Name"
								value={personInfo.lastname}
								onChange={(e) =>
									setPersonInfo((prev) => ({
										...prev,
										lastname: e.target.value,
									}))
								}
								error={!!errors.lastname}
								helperText={errors.lastname}
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Email"
								type="email"
								value={personInfo.email}
								onChange={(e) =>
									setPersonInfo((prev) => ({
										...prev,
										email: e.target.value,
									}))
								}
								error={!!errors.email}
								helperText={errors.email}
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Organization"
								value={personInfo.organization}
								onChange={(e) =>
									setPersonInfo((prev) => ({
										...prev,
										organization: e.target.value,
									}))
								}
                                required
								error={!!errors.organization}
								helperText={errors.organization}
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions sx={{ p: 2.5, pt: 1.5 }}>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					variant="contained"
					onClick={handleSubmit}
					disabled={Object.keys(errors).length > 0}
				>
					{initialData ? "Save Changes" : "Add Person"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddPersonDialog;
