export const SENSOR_LABELS = {
  temperature_ext: { label: "Temperature", visible: true },
  temperature_int: { label: "Internal Temperature", visible: false },
  humidity_ext: { label: "Humidity", visible: true },
  humidity_int: { label: "Internal Humidity", visible: false },
  pressure_int: { label: "Pressure", visible: false },
  wind_speed: { label: "Wind Speed", visible: false },
  wind_direction: { label: "Wind Direction", visible: false },
  wind_gust_speed: { label: "Wind Gust Speed", visible: false },
  wind_gust_direction: { label: "Wind Gust Direction", visible: false },
  wind_compass: { label: "Wind", visible: true },
  snow_depth: { label: "Snow Depth", visible: false },
  voltage: { label: "Battery", visible: false },
  solar: { label: "Solar", visible: false },
  pitch: { label: "Pitch", visible: false },
  roll: { label: "Roll", visible: false },
  wind_chill: { label: "Wind Chill", visible: true },
};
