import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  StepButton,
  Card,
  CardContent,
  IconButton,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddEquipment from './AddEquipment';
import AddEquipmentModel from './AddEquipmentModel';
import AddEquipmentAttribute from './AddEquipmentAttribute';

const steps = [
  'Add Equipment Model',
  'Add Equipment',
  'Add Equipment Attributes'
];

const EquipmentStepper = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  const [modelId, setModelId] = useState(null);
  const [addedEquipments, setAddedEquipments] = useState([]);
  const [showEquipmentForm, setShowEquipmentForm] = useState(true);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (activeStep === 1) {
      setShowEquipmentForm(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setSkipped((prev) => new Set(prev.add(activeStep)));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep !== steps.length - 1) {
      handleNext();
    }
  };

  const handleModelSuccess = (modelId) => {
    setModelId(modelId);
    handleComplete();
  };

  const handleEquipmentSuccess = (equipmentData) => {
    setAddedEquipments(prev => [...prev, equipmentData]);
    setShowEquipmentForm(false);
  };

  const handleAddAnotherEquipment = () => {
    setShowEquipmentForm(true);
  };

  const handleRemoveEquipment = (index) => {
    setAddedEquipments(prev => prev.filter((_, i) => i !== index));
  };

  const handleAttributeSuccess = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setTimeout(onClose, 2000);
  };

  const renderAddedEquipments = () => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Added Equipment
      </Typography>
      <Grid container spacing={2}>
        {addedEquipments.map((equipment, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper 
              elevation={2} 
              sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  {equipment.name}
                </Typography>
                <IconButton 
                  onClick={() => handleRemoveEquipment(index)}
                  size="small"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box mt={1}>
                <Typography variant="body2" color="text.secondary">
                  Type: {equipment.type}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Serial: {equipment.serial_number}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {equipment.status}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Model: {equipment.model_name}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setShowEquipmentForm(true)}
        sx={{ mt: 2 }}
      >
        Add Another Equipment
      </Button>
    </Box>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddEquipmentModel 
            onClose={handleModelSuccess}
            onSkip={handleSkip}
          />
        );
      case 1:
        return (
          <Box>
            {showEquipmentForm ? (
              <AddEquipment 
                onClose={handleEquipmentSuccess}
                modelId={modelId}
                onSkip={handleSkip}
              />
            ) : null}
            {addedEquipments.length > 0 && renderAddedEquipments()}
          </Box>
        );
      case 2:
        return (
          <Box>
            {addedEquipments.length > 0 && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Select Equipment to Add Attributes
                </Typography>
                <Grid container spacing={2}>
                  {addedEquipments.map((equipment, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Paper 
                        elevation={2}
                        sx={{ 
                          p: 2,
                          bgcolor: 'background.default',
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          {equipment.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Type: {equipment.type}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Serial: {equipment.serial_number}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <AddEquipmentAttribute 
              onClose={handleAttributeSuccess}
              modelId={modelId}
              equipments={addedEquipments}
            />
          </Box>
        );
      default:
        return null;
    }
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Add New Equipment Setup
        </Typography>
        
        <Stepper nonLinear activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={completed[index]}
                  {...labelProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        <Box sx={{ mb: 2 }}>
          {renderStepContent(activeStep)}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep !== steps.length - 1 && (
            <Button onClick={handleSkip} sx={{ mr: 1 }}>
              Skip this step
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button onClick={handleComplete}>
              Finish
            </Button>
          ) : (
            <Button 
              onClick={handleNext}
              disabled={activeStep === 1 && addedEquipments.length === 0}
            >
              Next
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default EquipmentStepper;