import { useState } from "react";
import { validateForm } from "../utils/formValidation";

const useForm = (initialState, validationSchema, onSubmit) => {
	const [formState, setFormState] = useState(initialState);
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState({
		text: "",
		severity: "info",
		open: false,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
console.log(e.target);
		let processedValue = value;
		if (e.target.type === "number") {
			processedValue = value === "" ? "" : (value === "0" ? 0 : Number(value));
		}
		setFormState((prevState) => ({ ...prevState, [name]: processedValue }));
		};
	const resetForm = () => {
		setFormState(initialState);
		setErrors({});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("formState",formState);

		const validationErrors = validateForm(formState, validationSchema);
		setErrors(validationErrors);

		if (Object.keys(validationErrors).length === 0) {
			try {
				const response = await onSubmit(formState);
				resetForm(); // Reset the form after successful submission
				setMessage({ text: response.message, severity: "success", open: true });
			} catch (error) {
				setMessage({ text: error.message, severity: "error", open: true });
			}
		}
	};

	const handleCloseMessage = () => {
		setMessage((prev) => ({ ...prev, open: false }));
	};

	return {
		formState,
		handleChange,
		handleSubmit,
		errors,
		message,
		handleCloseMessage,
		resetForm,
	};
};

export default useForm;
