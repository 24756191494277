import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query"; // Add this import
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import StationDashboard from "./components/StationDashboard/StationDashboard";
import Home from "./components/Home/Home";
import "leaflet/dist/leaflet.css";
import theme from "./utils/theme";
import ScrollToTop from "./utils/ScrollToTop";
import Auth from "./components/Auth/Auth";
import SuccessPage from "./components/Auth/SuccessPage";
import { AuthProvider } from "./components/Auth/AuthContext";
import FlagForm from "./pages/FlagForm";
import OffsetForm from "./pages/OffsetForm";
import NotFound from "./components/PageNotAFound/NotFound";
import PermissionDenied from "./components/PermissionDenied/PermissionDenied";
import { ReactQueryDevtools } from "react-query/devtools";
import AwsMapping from "./pages/AwsMapping";
import StationMeatadataDashboard from "./components/StationMeatadataDashboard/StationMeatadataDashboard";
import { TranslationProvider } from "./context/TranslationContext";
import ErrorBoundary from "./utils/ErrorBoundary"; // Import the ErrorBoundary component
import StaticPagesRouter from "./pages/StaticPages/StaticPagesRouter";

// Create a client
const queryClient = new QueryClient(); // Add this line

function App() {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <AuthProvider>
        <TranslationProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <ScrollToTop />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <Navbar />
                <Box
                  sx={{ flexGrow: 1, marginTop: { xs: "56px", sm: "64px" } }}
                >
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {/* <Route path="/:stationId" element={<StationDashboard />} /> */}
                      <Route
                        path="/:uid/metadata/*"
                        element={<StationMeatadataDashboard />}
                      />
                      {/* as i have the functionfindStationByNameOrId , i will use it to get the stationId from the stationIdentifier and vice versa */}
                      <Route
                        path="/:stationIdentifier"
                        element={<StationDashboard />}
                      />

                      <Route path="/auth" element={<Auth />} />
                      <Route path="/success" element={<SuccessPage />} />
                      <Route path="/flag" element={<FlagForm />} />
                      <Route path="/offset" element={<OffsetForm />} />
                      <Route path="/aws-mapping" element={<AwsMapping />} />
                      {/* Add StaticPagesRouter here */}
                      <Route path="/pages/*" element={<StaticPagesRouter />} />

                      <Route path="/404" element={<NotFound />} />

                      <Route
                        path="/permissionDenied"
                        element={<PermissionDenied />}
                      />
                      {/* 404 catch-all route should be last */}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </ErrorBoundary>
                </Box>

                <Footer />
              </Box>
            </Router>
          </ThemeProvider>
        </TranslationProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
