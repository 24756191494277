// src/pages/StaticPages/StaticPagesRouter.js
import { Routes, Route } from "react-router-dom";
import PageTemplate from "./PageTemplate";
import { pages } from "./pagesConfig";
import NotFound from "../../components/PageNotAFound/NotFound";

const StaticPagesRouter = () => {
  return (
    <Routes>
      {Object.entries(pages).map(([key, page]) => (
        <Route
          key={key}
          path={page.path.replace("/pages/", "")}
          element={<PageTemplate content={page.content} />}
        />
      ))}
      {/* Catch any undefined pages routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default StaticPagesRouter;
