import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Button, CircularProgress, Box } from "@mui/material";
import { useQuery } from "react-query";
import { getFormData } from "../../../services/api";
import StationInfoDisplay from "./StationInfoDisplay";
import StationInfoActions from "./StationInfoActions";
import AddStationDialog from "./AddStationDialog";

const StationInfo = () => {
  const { uid } = useParams();
  const [showAddComponent, setShowAddComponent] = useState(false);

  const { data: stationData, isLoading, error } = useQuery(
    ["stationInfo", uid],
    () => getFormData(`metadata/station-info/${uid}`),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  const addNewStation = (newStationData) => {
    // Logic to add new station data
    console.log("Adding new station:", newStationData);
  };

  const deleteStation = () => {
    // Logic to delete station data
    console.log("Deleting station with ID:", uid);
  };

  const onEdit = () => {
    // Logic to handle edit action
    console.log("Editing station with ID:", uid);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">
        Error loading station data: {error.message}
      </Typography>
    );
  }

  return (
    <div>
      {stationData && stationData.length > 0 ? (
        <StationInfoDisplay stationData={stationData} />
      ) : showAddComponent ? (
        <AddStationDialog onAdd={() => setShowAddComponent(false)} />
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography variant="h6" color="textSecondary">
            No information available
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setShowAddComponent(true)}>
            Add New
          </Button>
        </Box>
      )}
    </div>
  );
};

export default StationInfo;

