import React, { useState } from "react";
import {
	TextField,
	Button,
	Box,
	IconButton,
	InputAdornment,
	Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { authenticate } from "../../services/authenticate";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./userpool";
import { useAuth } from "./AuthContext";

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState("");
	const [success, setSucess] = useState("");
	const { login } = useAuth();

	const [isUnconfirmed, setIsUnconfirmed] = useState(false);
	const [showOtpInput, setShowOtpInput] = useState(false);
	const [otp, setOtp] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (event) => {
		event.preventDefault();
		setError("");
		setIsUnconfirmed(false);
		try {
			const data = await authenticate(username, password);
			login(); // Update the auth state
			console.log("Login successful:", data);
			navigate("/");
		} catch (err) {
			if (err.code === "UserNotConfirmedException") {
				setError(
					'Your account is not yet verified. Please click "Resend Verification Code" to verify your account.'
				);
				setIsUnconfirmed(true);
			} else {
				setError(err.message);
			}
		}
	};

	const handleResendOTP = () => {
		setError("");

		const user = new CognitoUser({
			Username: username,
			Pool: UserPool,
		});

		user.resendConfirmationCode((err, result) => {
			if (err) {
				console.error(err);
				setError(err.message);
			} else {
				console.log("OTP resent successfully");
				setSucess("A new verification code has been sent to your email.");
				setShowOtpInput(true);
			}
		});
	};

	const handleVerifyOtp = (event) => {
		event.preventDefault();
		const user = new CognitoUser({
			Username: username,
			Pool: UserPool,
		});

		user.confirmRegistration(otp, true, async (err, result) => {
			if (err) {
				setError(err.message);
			} else {
				setSucess("Account verified successfully.");
				try {
					// Attempt to authenticate immediately after verification
					await authenticate(username, password, true);
					// If successful, navigate to the home page
					navigate("/");
				} catch (authError) {
					setError(
						"Verification successful, but login failed. Please Contact."
					);
				}
			}
			setIsUnconfirmed(false);
			setShowOtpInput(false);
		});
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Box>
			<Box component="form" onSubmit={handleLogin}>
				<TextField
					fullWidth
					margin="normal"
					label="Email"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<TextField
					fullWidth
					margin="normal"
					type={showPassword ? "text" : "password"}
					label="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowPassword}>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				{!isUnconfirmed && (
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						sx={{ mt: 2 }}
					>
						Login
					</Button>
				)}
			</Box>
			{error && (
				<Alert severity="error" sx={{ mt: 2 }}>
					{error}
				</Alert>
			)}

			{isUnconfirmed && !showOtpInput && (
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleResendOTP}
					sx={{ mt: 2 }}
				>
					Resend Verification Code
				</Button>
			)}
			{showOtpInput && (
				<Box component="form" onSubmit={handleVerifyOtp} sx={{ mt: 2 }}>
					<TextField
						fullWidth
						margin="normal"
						label="Enter OTP"
						value={otp}
						onChange={(e) => setOtp(e.target.value)}
						required
						sx={{ mt: 2 }}
					/>
					<Button type="submit" variant="contained" color="primary" fullWidth>
						Verify OTP
					</Button>
				</Box>
			)}
			{success && (
				<Alert severity="success" sx={{ mt: 2 }}>
					{success}
				</Alert>
			)}
		</Box>
	);
};

export default Login;
