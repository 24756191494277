import { Box, Typography } from "@mui/material";
import { SENSOR_LABELS } from "./SENSOR_LABELS";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslation } from "../../context/TranslationContext";

const WarningBanner = ({ elements }) => {
  const getSensorInfo = (key) => {
    const sensorConfig = SENSOR_LABELS[key];
    return {
      label: sensorConfig?.label || key.replace(/_/g, " ").toUpperCase(),
      visible: sensorConfig?.visible ?? false,
    };
  };
  const { translate, currentLanguage } = useTranslation();

  const errors = Object.entries(elements).filter(
    ([key, element]) => element?.qc_value === 0 && getSensorInfo(key).visible
  );

  const warnings = Object.entries(elements).filter(
    ([key, element]) =>
      element?.qc_value !== undefined &&
      element?.qc_value !== 100 &&
      element?.qc_value !== 0 &&
      getSensorInfo(key).visible
  );

  if (errors.length === 0 && warnings.length === 0) return null;

  const allIssues = [...errors, ...warnings];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, mb: 2 }}>
      <Box
        sx={{
          backgroundColor: "rgba(255, 235, 235, 0.95)",
          padding: "12px 16px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          gap: 1.5,
          minHeight: "48px",
          boxShadow: "0 2px 8px rgba(211, 47, 47, 0.15)",
          border: "1px solid rgba(211, 47, 47, 0.2)",
          transition: "all 0.3s ease",
        }}
      >
        <WarningIcon
          color="error"
          sx={{
            fontSize: "24px",
            animation: "pulse 2s infinite",
          }}
        />
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px 8px",
              color: "error.dark",
              alignItems: "center",
              "& > span": {
                lineHeight: "1.5",
              },
            }}
          >
            <span
              style={{
                fontWeight: "600",
                marginRight: "4px",
              }}
            >
              {translate("Currently unavailable")}
            </span>
            {allIssues.map(([key], index) => (
              <>
                <span
                  key={key}
                  style={{
                    backgroundColor: "rgba(211, 47, 47, 0.08)",
                    padding: "2px 8px",
                    borderRadius: "4px",
                  }}
                >
                  {translate(getSensorInfo(key).label)}
                </span>
              </>
            ))}
            
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WarningBanner;

// import { Box, Typography } from "@mui/material";
// import { SENSOR_LABELS } from "./SENSOR_LABELS";
// import WarningIcon from "@mui/icons-material/Warning";

// const WarningBanner = ({ elements }) => {
//   const getSensorInfo = (key) => {
//     const sensorConfig = SENSOR_LABELS[key];
//     return {
//       label: sensorConfig?.label || key.replace(/_/g, " ").toUpperCase(),
//       visible: sensorConfig?.visible ?? false
//     };
//   };

//   const errors = Object.entries(elements)
//     .filter(([key, element]) =>
//       element?.qc_value === 0 &&
//       getSensorInfo(key).visible
//     );

//   const warnings = Object.entries(elements)
//     .filter(([key, element]) =>
//       element?.qc_value !== undefined &&
//       element?.qc_value !== 100 &&
//       element?.qc_value !== 0 &&
//       getSensorInfo(key).visible
//     );

//   if (errors.length === 0 && warnings.length === 0) return null;

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mb: 2 }}>
//       {errors.length > 0 && (
//         <Box
//           sx={{
//             backgroundColor: "rgba(255, 235, 235, 0.95)",
//             padding: "12px 16px",
//             borderRadius: "8px",
//             display: 'flex',
//             alignItems: 'center',
//             gap: 1.5,
//             minHeight: '48px',
//             boxShadow: '0 2px 8px rgba(211, 47, 47, 0.15)',
//             border: '1px solid rgba(211, 47, 47, 0.2)',
//             transition: 'all 0.3s ease',

//           }}
//         >
//           <WarningIcon
//             color="error"
//             sx={{
//               fontSize: '24px',
//               animation: 'pulse 2s infinite'
//             }}
//           />
//           <Box sx={{ flex: 1 }}>
//             <Typography
//               sx={{
//                 display: 'flex',
//                 flexWrap: 'wrap',
//                 gap: '4px 8px',
//                 color: 'error.dark',
//                 alignItems: 'center',
//                 '& > span': {
//                   lineHeight: '1.5'
//                 }
//               }}
//             >
//               <span style={{
//                 fontWeight: '600',
//                 marginRight: '4px'
//               }}>
//                 Sensor Errors:
//               </span>
//               {errors.map(([key, element], index) => (
//                 <span key={key} style={{
//                   backgroundColor: 'rgba(211, 47, 47, 0.08)',
//                   padding: '2px 8px',
//                   borderRadius: '4px'
//                 }}>
//                   {getSensorInfo(key).label}: {element.comment || "Not working"}
//                   {index < errors.length - 1 ? "" : ""}
//                 </span>
//               ))}
//             </Typography>
//           </Box>
//         </Box>
//       )}

//       {warnings.length > 0 && (
//         <Box
//           sx={{
//             backgroundColor: "rgba(255, 244, 229, 0.95)",
//             padding: "12px 16px",
//             borderRadius: "8px",
//             display: 'flex',
//             alignItems: 'center',
//             gap: 1.5,
//             minHeight: '48px',
//             boxShadow: '0 2px 8px rgba(237, 108, 2, 0.15)',
//             border: '1px solid rgba(237, 108, 2, 0.2)',
//             transition: 'all 0.3s ease',

//           }}
//         >
//           <WarningIcon
//             color="warning"
//             sx={{
//               fontSize: '24px'
//             }}
//           />
//           <Box sx={{ flex: 1 }}>
//             <Typography
//               sx={{
//                 display: 'flex',
//                 flexWrap: 'wrap',
//                 gap: '4px 8px',
//                 color: 'warning.dark',
//                 alignItems: 'center',
//                 '& > span': {
//                   lineHeight: '1.5'
//                 }
//               }}
//             >
//               <span style={{
//                 fontWeight: '600',
//                 marginRight: '4px'
//               }}>
//                 Sensor Warnings:
//               </span>
//               {warnings.map(([key, element], index) => (
//                 <span key={key} style={{
//                   backgroundColor: 'rgba(237, 108, 2, 0.08)',
//                   padding: '2px 8px',
//                   borderRadius: '4px'
//                 }}>
//                   {getSensorInfo(key).label}: {element.comment || "May be experiencing issues"}
//                   {index < warnings.length - 1 ? "" : ""}
//                 </span>
//               ))}
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default WarningBanner;
