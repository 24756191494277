import React, { useState, useEffect } from "react";
import useForm from "../hooks/useForm";
import { postFormData, getFormData } from "../services/api";
import moment from "moment";
import FormBuilder from "../components/Form/FormBuilder";
import { CircularProgress, Container, Grid } from "@mui/material";
import FlagsTable from "../components/Tables/FlagsTable";
import { flagTableConfig, flagFormConfig } from "../config/formConfigs/flagConfig";
import { useFlags } from "../../src/services/useFlags";

const FlagForm = () => {
	const [awsMappings, setAwsMappings] = useState([]);
	const [isAwsMappingsLoading, setIsAwsMappingsLoading] = useState(true);
    const { refetchRows } = useFlags();
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
	  const fetchAwsMappings = async () => {
		try {
		  const response = await getFormData("aws-mappings");
		  const mappings = response.map((mapping) => ({
			value: String(mapping.id), // Convert to string
			label: mapping.uid,
		  }));
		  setAwsMappings(mappings);
		} catch (error) {
		  console.error("Error fetching AWS mappings:", error);
		} finally {
		  setIsAwsMappingsLoading(false);
		}
	  };
  
	  fetchAwsMappings();
	}, []);
  
	const initialState = {
	  variable: "",
	  from_date: "",
	  to_date: "",
	  QC_value: "",  // Changed to empty string
	  comment: "",
	  aws_mapping_id: "", // Changed to empty string
	};
  
	const onSubmit = async (formData) => {
	  try {
		setIsSubmitting(true); // Start loading

		const formattedData = {
		  ...formData,
		  from_date: formData.from_date
			? moment(formData.from_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
			: null,
		  to_date: formData.to_date
			? moment(formData.to_date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
			: null,
		  // Convert string values to numbers during submission
		  QC_value: formData.QC_value !== "" ? parseInt(formData.QC_value, 10) : null,
		  aws_mapping_id: formData.aws_mapping_id !== "" ? parseInt(formData.aws_mapping_id, 10) : null,
		};
		console.log(formattedData);
		const response = await postFormData("flag", formattedData);
		await refetchRows(); // Refetch after successful submission

		return response;
	  } catch (error) {
		throw error;
	  }finally {
		setIsSubmitting(false); // Stop loading
	  }
	};
  
	const {
	  formState,
	  handleChange,
	  handleSubmit,
	  errors,
	  message,
	  handleCloseMessage,
	  resetForm,
	} = useForm(initialState, flagFormConfig.validationSchema, onSubmit);
  
	// Modified to handle string values
	const modifiedHandleChange = (e) => {
	  const { name, value } = e.target;
	  handleChange({
		target: {
		  name,
		  value: value
		}
	  });
	};
  
	const updatedFields = flagFormConfig.fields.map((field) => {
	  if (field.name === "aws_mapping_id") {
		return {
		  ...field,
		  options: awsMappings,
		  isLoading: isAwsMappingsLoading,
		};
	  }
	  return field;
	});
  
	return (
	  <Container maxWidth="xl" style={{ marginTop: "20px" }}>
		<Grid container spacing={2}>
		  <Grid item xs={12} md={6}>
			<FormBuilder
			  title={flagFormConfig.title}
			  fields={updatedFields}
			  onSubmit={handleSubmit}
			  formState={formState}
			  handleChange={modifiedHandleChange}
			  errors={errors}
			  message={message}
			  handleCloseMessage={handleCloseMessage}
			  resetForm={resetForm}
			  isSubmitting={isSubmitting} // Pass loading state

			/>
		  </Grid>
		  <Grid item xs={12} md={6}>
			<FlagsTable TableConfig={flagTableConfig} />
		  </Grid>
		</Grid>
	  </Container>
	);
  };
  
  

export default FlagForm;