const LANGUAGE_KEY = "app_language_preference";
export const languageService = {
  getPreferredLanguage: () => {
    // make sure when we update the language pereference setting on we go live,because the exisiting user would have set the language as the syllabics in the localstorage and it will not deleted automatically so ,have to write the code for that which delete the localstorage config
    return localStorage.getItem(LANGUAGE_KEY) || "syllabics";
  },

  setPreferredLanguage: (language) => {
    localStorage.setItem(LANGUAGE_KEY, language);
  },
};
