import { Box, Typography } from "@mui/material";
import { useTranslation } from "../../context/TranslationContext";
import { useState } from "react";

const WindDirectionIllustration = ({ windDirection, windSpeed ,getArrowColor,  hideSpeed,
  hideDirection 
}) => {
  // Define compass directions with their angles
  const directions = [
    { label: "N", angle: 0 },
    { label: "NNE", angle: 22.5 },
    { label: "NE", angle: 45 },
    { label: "ENE", angle: 67.5 },
    { label: "E", angle: 90 },
    { label: "ESE", angle: 112.5 },
    { label: "SE", angle: 135 },
    { label: "SSE", angle: 157.5 },
    { label: "S", angle: 180 },
    { label: "SSW", angle: 202.5 },
    { label: "SW", angle: 225 },
    { label: "WSW", angle: 247.5 },
    { label: "W", angle: 270 },
    { label: "WNW", angle: 292.5 },
    { label: "NW", angle: 315 },
    { label: "NNW", angle: 337.5 },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        width: "250px",
        height: "250px",
        margin: "20px auto",
        border: "2px solid rgba(0,0,0,0.1)",
        borderRadius: "50%",
      }}
    >
      {/* Direction Labels and Numbers */}

      {!hideDirection && directions.map(({ label, angle }) => (
        <Box
          key={label}
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate(-50%, -50%) rotate(${angle}deg) translateY(-115px)`,
            color: label.length === 1 ? "#000" : "#666",
            fontSize: label.length === 1 ? "14px" : "11px",
            fontWeight: label.length === 1 ? "700" : "500",
          }}
        >
          {label}
          <div style={{ fontSize: "10px" }}>{angle}°</div>
        </Box>
      ))}

{!hideDirection && [0, 1, 2].map((index) => (
  <Box
    key={`arrow-${index}`}
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "3px",
      height: "25px",
      background: getArrowColor(windSpeed),
      transformOrigin: "center",
      animation: `flowArrow 1.5s infinite ${index * 0.5}s ease-in-out`,
      transform: `
        translate(-50%, -50%)
        rotate(${windDirection}deg)
      `,
      boxShadow: "0 0 3px rgba(135, 206, 235, 0.5)",
      "@keyframes flowArrow": {
        "0%": {
          opacity: 0,
          transform: `
            translate(-50%, -50%)
            rotate(${windDirection}deg)
            translateY(100px)
          `
        },
        "50%": {
          opacity: 1,
          transform: `
            translate(-50%, -50%)
            rotate(${windDirection}deg)
            translateY(0px)
          `
        },
        "100%": {
          opacity: 0,
          transform: `
            translate(-50%, -50%)
            rotate(${windDirection}deg)
            translateY(-100px)
          `
        }
      },
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: "-5px",
        borderBottom: `10px solid ${getArrowColor(windSpeed)}`,
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        filter: "drop-shadow(0 0 1px rgba(135, 206, 235, 0.3))"
      }
    }}
  />
))}
{/* Person Icon with Speed Display */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      >
        {/* Head */}
        <Box
          sx={{
            width: "30px",
            height: "30px",
            backgroundColor: "#f0d6b3",
            borderRadius: "50%",
            marginBottom: "5px",
          }}
        />
        {/* Body */}
        <Box
          sx={{
            width: "40px",
            height: "60px",
            backgroundColor: "#4a90e2",
            borderRadius: "8px 8px 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {!hideSpeed ? (
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                lineHeight: 1,
                color: "#000",
              }}
            >
              {windSpeed}
              <span
                style={{
                  fontSize: "12px",
                  display: "block",
                  marginTop: "2px",
                }}
              >
                km/h
              </span>
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              N/A
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WindDirectionIllustration;
