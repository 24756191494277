export const validateForm = (formData, validationSchema) => {
	const errors = {};

	Object.keys(validationSchema).forEach((field) => {
		const value = formData[field];
		const fieldValidation = validationSchema[field];

		if (fieldValidation.required && !value) {
			errors[field] = "This field is required";
		} else if (
			fieldValidation.minLength &&
			value.length < fieldValidation.minLength
		) {
			errors[field] = `Minimum length is ${fieldValidation.minLength}`;
		} else if (
			fieldValidation.pattern &&
			!fieldValidation.pattern.test(value)
		) {
			errors[field] = fieldValidation.message || "Invalid format";
		}
	});

	return errors;
};
