import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "../../context/TranslationContext";
import WindDirectionIllustration from "./WindDirectionIllustration";
import { useState } from "react";
import { colorRanges } from "./colorRanges";
import AirIcon from "@mui/icons-material/Air";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { getQCFlag } from "../../utils/rawFunction";
import getArrowColor from "../../utils/getArrowColor";

export const WindCompass = ({
  title = "Wind Info",
  windSpeed,
  windDirectionTo,
  windDirectionFrom,
  windGustSpeed,
  windGustDirection,
  cardinalDirectionFrom,
  gustcardinalDirectionFrom,
  // Add QC-related props
  windSpeedQC,
  windDirectionQC,
  windGustSpeedQC,
  windGustDirectionQC,
}) => {
  const { translate, currentLanguage } = useTranslation();
  const [showIllustration, setShowIllustration] = useState(false);
  // Computed values
  const speed = typeof windSpeed === "number" ? windSpeed.toFixed(0) : "N/A";
  const direction = typeof windDirectionTo === "number" ? windDirectionTo : 0;
  const gustSpeed =
    typeof windGustSpeed === "number" ? windGustSpeed.toFixed(0) : "N/A";
  const arrowColor = getArrowColor(Number(speed));

  // Component styles
  const containerStyles = {
    borderRadius: "5px",
    border: "1px solid rgba(5, 5, 5, 0.1)",
    padding: { xs: "12px", sm: "12px" },
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  };

  return (
    <Box sx={containerStyles}>
      {/* Header */}
      <Typography
        sx={{
          fontSize: "clamp(1rem, 1.5vw, 1.2rem)",
          display: "flex",
          alignItems: "center",
          mb: { xs: 0.2, sm: 0.2 },
        }}
      >
        {translate(title)}
        <AirIcon
          sx={{
            ml: 1,
            fontSize: "clamp(1.3rem, 1.5vw, 1.5rem)",
          }}
        />
      </Typography>

      {/* Main Content Grid */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 100px", // Reduced width for mobile
            sm: "1fr 160px", // New value to accommodate larger compass
          },
          gap: {
            xs: 1, // Reduced gap for mobile
            sm: 2,
          },
          alignItems: "center",
          // display: "grid",
          // gridTemplateColumns: {
          //   xs: "1fr", // Stack vertically on mobile
          //   sm: "1fr 160px",
          // },
          // gap: {
          //   xs: 1, // Reduced gap on mobile
          //   sm: 2,
          // },
          // alignItems: "center",
          // padding: { xs: "0.5rem", sm: 0 }, // Added padding on mobile
      
          
        }}
      >
        {/* Wind Information Column */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 0.25, sm: 1 },
            mr: { xs: 1, sm: 0 }, // Add margin right on mobile
          }}
        >
          {/* Speed Row */}
          <InfoRow
            label={translate("Speed")}
            value={`${speed} km/h`}
            qcFlag={
              windSpeedQC !== 100 ? getQCFlag({ qc_value: windSpeedQC }) : null
            }
            sx={{
              minWidth: { xs: "110px", sm: "auto" }, // Ensure minimum width on mobile
            }}
          />

          {/* Direction Row */}
          <InfoRow
            label={
              <>
                <Box
                  component="span"
                  onClick={() => setShowIllustration(true)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {translate("From")}
                </Box>
                <InfoButton onClick={() => setShowIllustration(true)} />
              </>
            }
            value={cardinalDirectionFrom}
            qcFlag={
              windDirectionQC !== 100
                ? getQCFlag({ qc_value: windDirectionQC })
                : null
            }
            sx={{
              minWidth: { xs: "110px", sm: "auto" },
            }}
          />

          {/* Gust Row */}
          <InfoRow
            label={translate("Gust")}
            value={`${gustSpeed} km/h`}
            sx={{
              minWidth: { xs: "110px", sm: "auto" }, // Ensure minimum width on mobile
            }}
            qcFlag={
              windGustSpeedQC !== 100
                ? getQCFlag({ qc_value: windGustSpeedQC })
                : null
            }

            /* value={`${gustSpeed} km/h (${gustcardinalDirectionFrom})`} */
          />
          {!windSpeedQC && (
            <Box sx={{ mx: 0.5, my: 0.2 }}>
              {/* Added margin top and bottom */}
              <ColorRamp speed={Number(speed)} />
            </Box>
          )}
        </Box>

        {/* Compass Column */}
        <CompassDisplay
          speed={speed}
          direction={direction}
          arrowColor={arrowColor}
          currentLanguage={currentLanguage}
          translate={translate}
          windSpeedQC={windSpeedQC}
          windDirectionQC={windDirectionQC}
          setShowIllustration={setShowIllustration}
        />
      </Box>

      {/* Illustration Modal */}
      {showIllustration && (
        <IllustrationModal
          onClose={() => setShowIllustration(false)}
          windDirection={windDirectionTo}
          windSpeed={windSpeed}
          cardinalDirectionFrom={cardinalDirectionFrom}
          windDirectionFrom={windDirectionFrom}
          speed={speed}
          getArrowColor={getArrowColor}
          hasSpeedQCIssue={
            windSpeedQC !== 100 ? getQCFlag({ qc_value: windSpeedQC }) : null
          }
          hasDirectionQCIssue={
            windDirectionQC !== 100
              ? getQCFlag({ qc_value: windDirectionQC })
              : null
          }
        />
      )}
    </Box>
  );
};

// Helper Components
const InfoRow = ({ label, value, qcFlag, comment }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      mb: {
        xs: 0.125, // spacing on mobile
        sm: -0.5, // spacing on tablet/desktop
      },
    }}
  >
    <Typography sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}>
      {label}
    </Typography>
    {/* <Typography sx={{ fontSize: "clamp(0.875rem, 1vw, 1rem)" }}>
      {value}
    </Typography>
    {qcFlag && (
        <Tooltip title={`QC Flag: ${qcFlag}${comment ? ` - ${comment}` : ""}`}>
          <WarningIcon color="error" sx={{ ml: 1, fontSize: "1rem" }} />
        </Tooltip>
      )} */}

    {!qcFlag && (
      <Typography sx={{ fontSize: "clamp(0.875rem, 1vw, 1rem)" }}>
        {value}
      </Typography>
    )}
    {qcFlag && (
      <Tooltip title={`QC Flag: ${qcFlag}${comment ? ` - ${comment}` : ""}`}>
        <WarningIcon color="error" sx={{ ml: 1, fontSize: "1rem" }} />
      </Tooltip>
    )}
  </Box>
);

const InfoButton = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      padding: { xs: "1px", sm: "2px" },
      marginLeft: "4px",
    }}
  >
    <Box
      sx={{
        width: { xs: "14px", sm: "16px" },
        height: { xs: "14px", sm: "16px" },
        borderRadius: "50%",
        border: "1px solid rgba(0,0,0,0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: { xs: "10px", sm: "12px" },
        color: "rgba(0,0,0,0.6)",
      }}
    >
      ?
    </Box>
  </IconButton>
);

const CompassDisplay = ({
  speed,
  direction,
  arrowColor,
  currentLanguage,
  translate,
  windSpeedQC,
  windDirectionQC,
  setShowIllustration
}) => {
  const hasSpeedQCIssue = windSpeedQC !== undefined && windSpeedQC !== 100;
  const hasDirectionQCIssue =
    windDirectionQC !== undefined && windDirectionQC !== 100;

  return (
    <Box
      sx={{
        width: { xs: "100px", sm: "130px" }, // Increased from 80px/90px
        height: { xs: "100px", sm: "130px" }, // Increased from 80px/90px
        position: "relative",
        margin: { xs: "0.5rem auto", sm: "0 auto" }, // Added vertical margin on mobile
        display: "flex",   // Added for vertical centering
        alignItems: "center", // Added for vertical centering
        justifyContent: "center", // Added for horizontal centering
        padding: { xs: "0.5rem", sm: 0 }, // Added padding on mobile
       
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
     

          }}
          onClick={() => setShowIllustration(true)}

    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "1.5px solid rgba(0, 0, 0, 0.15)",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "50%",
            background: `repeating-conic-gradient(
          from 0deg,
          transparent 0deg 5deg,
          rgba(0, 0, 0, 0.06) 5deg 6deg,
          transparent 6deg 30deg
        )`,
          },
        }}
      >
        {/* Outer circle */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            border: "1.5px solid rgba(0, 0, 0, 0.15)",
            position: "relative",
          }}
        >
          {/* Inner circle */}
          <Box
            sx={{
              position: "absolute",
              top: "15%",
              left: "15%",
              width: "70%",
              height: "70%",
              borderRadius: "50%",
              border: "1.5px solid rgba(0, 0, 0, 0.15)",
            }}
          >
            {/* Direction labels */}
            {["N", "E", "S", "W"].map((dir, i) => (
              <Box
                key={dir}
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: {
                    xs: `translate(-50%, -50%) rotate(${i * 90}deg) translate(0, -45px) rotate(-${i * 90}deg)`, // Reduced distance for mobile
                    sm: `translate(-50%, -50%) rotate(${i * 90}deg) translate(0, -60px) rotate(-${i * 90}deg)`  // Original distance for desktop
                  },
                              zIndex: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.7)",
                    fontSize: { 
                      xs: "0.7rem",  // Smaller font size for mobile
                      sm: "0.8rem"   // Original font size for desktop
                    },
                                fontWeight: "600",
                    lineHeight: 1,
                  }}
                >
                  {currentLanguage === "english" ? dir : translate(dir)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Center Speed Display */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "40px", sm: "48px" }, // Smaller on mobile
            height: { xs: "40px", sm: "48px" }, // Smaller on mobile
              borderRadius: "50%",
            backgroundColor: "white",
            border: "1.5px solid rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
        {hasSpeedQCIssue ? (
    <>
      <Typography
        sx={{ fontSize: { xs: "1.1rem", sm: "1.3rem" }, fontWeight: "600", lineHeight: 1, mb: 0.2 }}
      >
        -
      </Typography>
      <Typography
        sx={{ fontSize: { xs: "0.5rem", sm: "0.6rem" }, lineHeight: 1, color: "rgba(0, 0, 0, 0.7)" }}
      >
        km/h
      </Typography>
    </>
  ) : (
    <>
      <Typography
        sx={{             fontSize: { xs: "1.1rem", sm: "1.3rem" }
        , fontWeight: "600", lineHeight: 1, mb: 0.2 }}
      >
        {speed}
      </Typography>
      <Typography
        sx={{ fontSize: { xs: "0.5rem", sm: "0.6rem" }, lineHeight: 1, color: "rgba(0, 0, 0, 0.7)" }}
      >
        km/h
      </Typography>
    </>
  )}
        </Box>

        {/* Wind Direction Arrow */}
        {!hasDirectionQCIssue && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transform: `translate(-50%, -50%) rotate(${direction}deg)`,
              zIndex: 1,
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "10%", // Decreased from 15% to start higher
                left: "50%",
                width: "3px",
                height: "80%", // Increased from 70% to 90%
                backgroundColor: hasSpeedQCIssue
                  ? "rgba(0, 0, 0, 0.3)"
                  : arrowColor,
                transform: "translateX(-50%)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-2px",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 0,
                  height: 0,
                  borderLeft: "7px solid transparent",
                  borderRight: "7px solid transparent",
                  borderBottom: `13px solid ${
                    hasSpeedQCIssue ? "rgba(0, 0, 0, 0.3)" : arrowColor
                  }`,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-3px",
                  left: "50%",
                  transform: "translate(-50%, 50%)",
                  width: "8px",
                  height: "8px",
                  backgroundColor: "white",
                  border: `2px solid ${
                    hasSpeedQCIssue ? "rgba(0, 0, 0, 0.3)" : arrowColor
                  }`,
                  borderRadius: "50%",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const IllustrationModal = ({
  onClose,
  windDirection,
  windSpeed,
  cardinalDirectionFrom,
  windDirectionFrom,
  speed,
  getArrowColor,
  hasSpeedQCIssue,
  hasDirectionQCIssue,
}) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
    onClick={onClose}
  >
    <Box
      sx={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "300px",
        textAlign: "center",
        position: "relative",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        <CloseIcon />
      </IconButton>

      {!hasSpeedQCIssue || !hasDirectionQCIssue ? (
        <>
          <WindDirectionIllustration
            windDirection={windDirection}
            windSpeed={windSpeed.toFixed(0)}
            getArrowColor={getArrowColor}
            hideSpeed={hasSpeedQCIssue}
            hideDirection={hasDirectionQCIssue}
          />
          <Typography sx={{ mt: 2 }}>
            {!hasDirectionQCIssue && !hasSpeedQCIssue
              ? `Wind direction (${cardinalDirectionFrom} or ${windDirectionFrom.toFixed(
                  0
                )}°) is where the wind is coming FROM, and the arrow points to where the wind is blowing TO. When looking ${cardinalDirectionFrom}, you'll feel the ${speed} km/h wind on your face.`
              : !hasDirectionQCIssue
              ? `Wind is coming FROM ${cardinalDirectionFrom} (${windDirectionFrom.toFixed(
                  0
                )}°)`
              : !hasSpeedQCIssue
              ? `Current wind speed is ${speed} km/h`
              : null}
          </Typography>
          {(hasSpeedQCIssue || hasDirectionQCIssue) && (
            <Typography
              sx={{
                mt: 1,
                color: "error.main",
                fontSize: "0.875rem",
                fontStyle: "italic",
              }}
            >
              {hasSpeedQCIssue && hasDirectionQCIssue
                ? "Both wind measurements have issues"
                : hasSpeedQCIssue
                ? "Wind speed measurement is unavailable"
                : "Wind direction measurement is unavailable"}
            </Typography>
          )}
        </>
      ) : (
        <Box sx={{ p: 3 }}>
          <WarningIcon color="error" sx={{ fontSize: "3rem", mb: 2 }} />
          <Typography>
            All wind measurements are currently unavailable
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);

const ColorRamp = ({ speed }) => {
  const gradientStops = [
    { value: 0, color: "rgb(98, 113, 183)" },
    { value: 10, color: "rgb(74, 148, 169)" },
    { value: 20, color: "rgb(77, 141, 123)" },
    { value: 35, color: "rgb(53, 159, 53)" },
    { value: 55, color: "rgb(161, 108, 92)" },
    { value: 70, color: "rgb(175, 80, 136)" },
    { value: 100, color: "rgb(92, 144, 152)" },
  ];

  const mobilePoints = [0, 35, 55, 100];

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        my: 0.5,
      }}
    >
      <Box
        sx={{
          height: "16px",
          borderRadius: "4px",
          background: `linear-gradient(to right, ${gradientStops
            .map((stop) => stop.color)
            .join(", ")})`,
          position: "relative",
          display: "flex",
          alignItems: "center",
          px: 1.5,
        }}
      >
        {gradientStops.map((stop) => (
          <Typography
            key={stop.value}
            sx={{
              position: "absolute",
              left: `${(stop.value / 104) * 100}%`,
              transform: "translateX(-50%)",
              color: "white",
              fontSize: "0.65rem",
              fontWeight: "500",
              textShadow: "0 1px 1px rgba(0,0,0,0.3)",
              ...(stop.value === 0 && { left: "3px", transform: "none" }),
              ...(stop.value === 100 && {
                right: "3px",
                left: "auto",
                transform: "none",
              }),
              display: {
                xs: mobilePoints.includes(stop.value) ? "block" : "none",
                sm: "block",
              },
            }}
          >
            {stop.value}
          </Typography>
        ))}
      </Box>

      {speed <= 104 && (
        <>
          <Box
            sx={{
              position: "absolute",
              left: `${(Math.min(Math.max(speed, 0), 104) / 104) * 100}%`,
              bottom: "-6px",
              transform: "translateX(-50%)",
              width: "2px",
              height: "6px",
              backgroundColor: "white",
              border: "1px solid rgba(0,0,0,0.6)",
              borderRadius: "1px",
              zIndex: 2,
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              left: `${(Math.min(Math.max(speed, 0), 104) / 104) * 100}%`,
              bottom: "-20px",
              transform: "translateX(-50%)",
              fontSize: "0.7rem",
              color: "rgba(0,0,0,0.7)",
              fontWeight: "500",
              whiteSpace: "nowrap", // Prevents text wrapping
              borderRadius: "2px", // Rounds the corners
            }}
          >
            {speed} km/h
          </Typography>
        </>
      )}
    </Box>
  );
};
