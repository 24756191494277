import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import Login from "./Login";
import Signup from "./Signup";

const Auth = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "90vh",
			}}
		>
			<Box
				sx={{
					width: 500,
					border: "1px solid #33333",
					borderRadius: "8px",
					boxShadow: 1,
					padding: "10px",
				}}
			>
				<Tabs value={value} onChange={handleChange} centered>
					<Tab label="Login" />
					<Tab label="Signup" />
				</Tabs>
				{value === 0 && (
					<Box sx={{ p: 3 }}>
						<Typography component="div">
							<Login />
						</Typography>
					</Box>
				)}
				{value === 1 && (
					<Box sx={{ p: 3 }}>
						<Typography component="div">
							<Signup />
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Auth;
