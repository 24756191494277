import React, { useState } from "react";
import { 
  Typography, 
  Box, 
  Grid, 
  Paper, 
  IconButton, 
  Drawer, 
  Backdrop,
  useMediaQuery,
  Tooltip 
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PushPinIcon from "@mui/icons-material/PushPin";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import NorthernCanadaMap from "../Map/NorthernCanadaMap";
import AllStationsList from "../AllStations/AllStationsList";

function Home() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pinned, setPinned] = useState(false);
  
  // Use media query to determine screen size
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const drawerWidth = isSmallScreen ? "70%" : "350px";

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const togglePin = () => {
    setPinned(!pinned);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "relative",
        }}
      >
        <Box sx={{ p: 2, width: "100%", height: "100%" }}>
          <NorthernCanadaMap />
        </Box>

        <Tooltip title="Expand Menu" placement="left">
          <IconButton
            onClick={toggleDrawer}
            sx={{
              position: "fixed",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "30px",
              height: "80px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "5px 0 0 5px",
              "&:hover": {
                backgroundColor: "black",
              },
              zIndex: 1200,
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Tooltip>

        <Backdrop
          open={drawerOpen && !pinned}
          onClick={toggleDrawer}
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
        />

        <Drawer
          anchor="right"
          open={drawerOpen || pinned}
          onClose={toggleDrawer}
          variant="persistent"
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronRightIcon />
            </IconButton>
            <IconButton onClick={togglePin}>
              <PushPinIcon color={pinned ? "primary" : "inherit"} />
            </IconButton>
            <AllStationsList />
          </Box>
        </Drawer>
      </Box>
    </>
  );
}

export default Home;